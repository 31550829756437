import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import useDeviceDetection, {MOBILE} from "../shared/DetectDevice/useDeviceDetection";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ColorSelector from "../shared/ColorSelector/ColorSelector";
import MenuItem from "@mui/material/MenuItem";
import {ThemeProvider} from "@mui/material/styles";
import SurveysService from "../../services/SurveysService";
import {defaultsDeep} from "lodash";
import {defaultTheme, getTheme} from "../../utils/surveysUtils";
import CircularProgress from "@mui/material/CircularProgress";
import {SurveyContext} from "./context";
import IconButton from "@mui/material/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import {getMimeType} from "../../utils/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import TripleStateButton from "../shared/TripleStateButton/TripleStateButton";
import ReplayIcon from "@mui/icons-material/Replay";
import PaletteIcon from "@mui/icons-material/Palette";

const surveysService = new SurveysService();

const useStyles = makeStyles()(theme => ({
	dialogComponent: {
		display: 'flex',
		gap: '20px',
		minHeight: '500px',
	},
	previewContainer: {
		flex: 1,
		padding: '20px',
		border: '1px solid #ccc',
		borderRadius: '8px',
		backgroundColor: '#f9f9f9',
	},
	formContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
			backgroundColor: "#FF8787",
		}
	},
	previewElement: {
		margin: '10px 0',
		padding: '10px',
		borderRadius: '5px',
		textAlign: 'center',
	},
	checkCircleIcon: {
		color: '#003945',
	},
	circleIcon: {
		color: 'grey',
	},
	styleBox: {
		width: '100%',
		marginTop: 5,
	},
	styleStep: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	positionRelative: {
		position: 'relative'
	},
	flexEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
		gap: 10,
	},
	flexBetween: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	colorPicker: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	p: {
		margin: 0,
		padding: 0,
	},
	centerLoader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	previewRoot: {
		margin: '0',
		padding: '5px',
		width: "130px",
		height: `100px`,
		display: 'inline-block',
		borderStyle: 'dotted',
		borderRadius: '4px',
	},
	previewImg: {
		textAlign: 'center',
		verticalAlign: 'middle',
		maxWidth: '110px',
		maxHeight: '90px',
		position: 'relative',
		top: '-50px'
	},
	previewBtns: {
		textAlign: 'center',
		verticalAlign: 'middle',
		maxWidth: '100%',
		height: '50px',
		position: 'relative',
		top: '25%',
		zIndex: '100'
	},
	previewBody: {
		height: "100px",
	},
	titleIcon: {
		marginRight: '10px',
	},
	textAndButton: {
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
	}
}));

export default function EditThemeDialog(props) {
	const { classes } = useStyles();
	const { t, openSnackbar, showSpinner, openEditThemeDialog, setOpenEditThemeDialog } = props;
	const { getFormConfiguration } = useContext(SurveyContext);
	const [loading, setLoading] = useState(false);

	const [theme, setTheme] = useState({imageId: 0, json: defaultTheme});
	const [logo, setLogo] = useState(undefined);
	const [cataSelected, setCataSelected] = useState(false);

	const [uploading, setUploading] = useState(false);
	const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);

	const device = useDeviceDetection();

	useEffect(() => {
		setTheme({imageId: 0, json: defaultTheme});
		if (openEditThemeDialog) {
			setLoading(true);
			surveysService.getFormTheme(getFormConfiguration().id).then(results => {
				let json = results.data.json ? defaultsDeep(JSON.parse(results.data.json), defaultTheme) : defaultTheme;
				setTheme({...results.data, json: json});

				if(results.data.imageId) {
					surveysService.getFormFileUrl(getFormConfiguration().id, results.data.imageId)
						.then(response => {
							setLogo(response.url);
						})
						.catch(error => {
							console.log(error)
							openSnackbar('error', t("react.error.fetch.message"));
						}).finally(() => {
							setLoading(false);
					});
				} else {
					setLoading(false);
				}
			}).catch(error => {
				console.log(error)
				openSnackbar('error', t("react.error.fetch.message"));
			});
		}
	}, [openEditThemeDialog]);

	const handleUploadLogo = (event) => {
		const file = event.target.files[0];
		const mimeType = getMimeType(file.name);

		const reader = new FileReader();
		reader.onload = (e) => {
			setUploading(true);

			const arrayBufferView = e.target.result;

			const blob = new Blob([arrayBufferView], {type: mimeType});
			const destfile = new File([blob], file.name, {
				lastModified: new Date(),
				type: mimeType
			});

			surveysService.uploadFormFile(getFormConfiguration().id, destfile)
				.then(response => {
					setTheme({...theme, imageId: response.data.id});
					surveysService.getFormFileUrl(getFormConfiguration().id, response.data.id).then(res => {
						setLogo(res.url);
					});
					return response;
				}).catch((err) => {
				console.error(err);
			}).finally(() => {
				setUploading(false);
			});
		};

		reader.readAsArrayBuffer(file);
	};

	const handleDeleteLogo = () => {
		surveysService.deleteFormFile(getFormConfiguration().id, theme.imageId)
			.then(response => {
				setLogo(undefined);
				setTheme({...theme, imageId: 0});
				return response;
			}).catch((err) => {
			console.error(err);
		});
	};

	const handleSaveTheme = () => {
		setOpenEditThemeDialog(false);
		showSpinner(true);
		let payload = {
			...theme,
			json: JSON.stringify(theme.json),
			imageId: theme.imageId,
		};
		surveysService.saveFormTheme(getFormConfiguration().id, payload)
			.then(() => {
				openSnackbar('success', t("react.project.collectforms.parameters.edit.theme.success"));
			}).catch(() => {
			openSnackbar('error', t("react.project.collectforms.parameters.edit.theme.error"));
		}).finally(() => {
			showSpinner(false);
		});
	};

	const handleClose = () => {
		setOpenEditThemeDialog(false);
	};

	const handleReset = () => {
		setTheme({...theme, imageId: 0, json: defaultTheme});
		setLogo(undefined);
	};

	const handleThemeChange = (type, value) => {
		switch (type) {
			case "primaryColor":
				setTheme({
					...theme,
					json: {
						...theme.json,
						button: {
							...theme.json.button,
							backgroundColor: value,
							hoverBackgroundColor: darkenColor(value, -30),
						},
						radio: { ...theme.json.radio, color: value },
						checkbox: { ...theme.json.checkbox, color: value },
						stepper: { ...theme.json.stepper, color: value },
						progress: { ...theme.json.progress, color: value },
					},
				});
				break;
			case "canvaColor":
				setTheme({
					...theme,
					json: {
						...theme.json,
						backgroundColor: value,
					},
				});
				break;
			case "paperColor":
				setTheme({
					...theme,
					json: {
						...theme.json,
						paper: {
							...theme.json.paper,
							backgroundColor: value,
						},
					},
				});
				break;
			case "darkMode":
				setTheme({
					...theme,
					json: {
						...theme.json,
						darkMode: !theme.json.darkMode,
					},
				});
				break;
			case "logoWidth":
				setTheme({
					...theme,
					json: {
						...theme.json,
						logo: {
							...theme.json.logo,
							width: value,
						},
					},
				});
				break;
			case "logoPosition":
				setTheme({
					...theme,
					json: {
						...theme.json,
						logo: {
							...theme.json.logo,
							position: value,
						},
					},
				});
				break;
			case "elevation":
				setTheme({
					...theme,
					json: {
						...theme.json,
						paper: {
							...theme.json.paper,
							elevation: value,
						},
					},
				});
				break;
			case "buttonVariant":
				setTheme({
					...theme,
					json: {
						...theme.json,
						customNextButton: {
							...theme.json.customNextButton,
							variant: value,
						},
					},
				});
				break;

			default:
				console.warn("Type de modification inconnu :", type);
				break;
		}
	};

	const darkenColor = (color, percent) => {
		color = color.replace(/^\s*#|\s*$/g, "");

		if (color.length === 3) {
			color = color.replace(/(.)/g, "$1$1");
		}

		const hexR = color.substring(0, 2);
		const hexG = color.substring(2, 4);
		const hexB = color.substring(4, 6);

		let r = parseInt(hexR, 16);
		let g = parseInt(hexG, 16);
		let b = parseInt(hexB, 16);

		if (isNaN(r)) r = 0;
		if (isNaN(g)) g = 0;
		if (isNaN(b)) b = 0;

		const newR = Math.min(255, Math.floor(r + (r * percent) / 100));
		const newG = Math.min(255, Math.floor(g + (g * percent) / 100));
		const newB = Math.min(255, Math.floor(b + (b * percent) / 100));

		const newHexRColor = `${newR.toString(16)}`.padStart(2, "0");
		const newHexGColor = `${newG.toString(16)}`.padStart(2, "0");
		const newHexBColor = `${newB.toString(16)}`.padStart(2, "0");

		return "#" + newHexRColor + newHexGColor + newHexBColor;
	};

	const customTheme = getTheme(theme.json);

	if (!openEditThemeDialog) return null;

	return (
		<Dialog open={openEditThemeDialog} maxWidth="lg" fullWidth>
			<DialogTitleWithCloseIcon
				startIcon={<PaletteIcon color="primary" className={classes.titleIcon} />}
				title={t("react.project.collectforms.parameters.edit.theme")}
				callbackOnclose={handleClose}
			/>
			<DialogContent className={classes.dialogComponent}>
				{loading ?
					<div className={classes.centerLoader}>
						<CircularProgress/>
					</div>
					:   <>
						<div className={classes.formContainer}>
							<div className={classes.colorPicker}>
								<p className={classes.p}> {t("react.surveys.theme.editor.field.logo")} :</p>
								<div className={classes.previewBody}>
									<div className={classes.previewRoot}
									     onMouseOver={e => setUploadButtonVisibility(true)}
									     onMouseOut={e => setUploadButtonVisibility(false)}
									>
										{<div className={classes.previewBtns}>
											<div
												style={{display: (getFormConfiguration().id > 0 && (uploading || true === true || uploadButtonVisibility)) ? 'block' : 'none'}}>
												<div
													style={{display: (getFormConfiguration().id > 0 && (uploading || logo || uploadButtonVisibility)) ? 'block' : 'none'}}>
													{!uploading &&
														<span>
                                                            <IconButton variant="outlined" color="primary"
                                                                        component="label" size="large"
                                                                        title={t("react.project.collectforms.button.selectfile.tooltip")}>
                                                                <ImageIcon/>
	                                                            <input type="file" accept="image/*"
	                                                                   onChange={handleUploadLogo}
	                                                                   style={{display: "none"}}/>
															</IconButton>
                                                        </span>
													}
													{(!uploading && logo) &&
														<span>
                                                            <IconButton variant="outlined" color="primary"
                                                                        component="label" onClick={handleDeleteLogo}
                                                                        size="large">
                                                                <DeleteIcon/>
															</IconButton>
														</span>
													}
													{uploading && <CircularProgress/>}
												</div>
											</div>
										</div>}
										{logo && <div className={classes.alignCenter}>
											<img
												className={classes.previewImg}
												src={logo}
												style={{opacity: uploadButtonVisibility ? '0.3' : '1'}}
												alt="logo"
											/>
										</div>}
									</div>
								</div>
							</div>
							{logo &&
								<>
									<div className={classes.colorPicker}>
										<p className={classes.p}>{t("react.surveys.theme.editor.field.logo.size")} :</p>
										<TripleStateButton
											stateValues={["100px", "150px", "200px"]}
											stateLabels={["100 px", "150 px", "200 px"]}
											stateValue={theme.json.logo.width}
											setStateValue={(value) => {handleThemeChange("logoWidth", value)}}
										/>
									</div>
									<div className={classes.colorPicker}>
										<p className={classes.p}>{t("react.surveys.theme.editor.field.logo.position")} :</p>
										<TripleStateButton
											stateValues={["left", "center", "right"]}
											stateLabels={[t("react.surveys.image.left"), t("react.surveys.image.center"), t("react.surveys.image.right")]}
											stateValue={theme.json.logo.position}
											setStateValue={(value) => {handleThemeChange("logoPosition", value)}}
										/>
									</div>
								</>
							}
							<div className={classes.colorPicker}>
								<p className={classes.p}>{t("react.surveys.theme.editor.field.color.primary")} :</p>
								<ColorSelector
									{...props}
									color={theme.json.button.backgroundColor}
									onChange={(value) => {handleThemeChange("primaryColor", value)}}
								/>
							</div>
							<div className={classes.colorPicker}>
								<p className={classes.p}>{t("react.surveys.theme.editor.field.color.background")} :</p>
								<ColorSelector
									{...props}
									color={theme.json.backgroundColor}
									onChange={(value) => {handleThemeChange("canvaColor", value)}}
								/>
							</div>
							<div className={classes.colorPicker}>
								<p className={classes.p}>{t("react.surveys.theme.editor.field.color.canva")} :</p>
								<ColorSelector
									{...props}
									color={theme.json.paper.backgroundColor}
									onChange={(value) => {handleThemeChange("paperColor", value)}}
								/>
							</div>
							<div className={classes.colorPicker}>
								<p className={classes.p}>{t("react.surveys.theme.editor.field.darkmode")} :</p>
								<Switch checked={theme.json.darkMode} onChange={() => {handleThemeChange("darkMode")}} name="darkMode" />
							</div>

							<TextField
								select
								label={t("react.surveys.theme.editor.field.elevation")}
								value={theme.json.paper.elevation}
								fullWidth
								onChange={(e) => {handleThemeChange("elevation", e.target.value)}}
							>
								<MenuItem value={0}>{t("react.surveys.theme.editor.field.elevation.none")}</MenuItem>
								<MenuItem value={1}>{t("react.surveys.theme.editor.field.elevation.low")}</MenuItem>
								<MenuItem value={3}>{t("react.surveys.theme.editor.field.elevation.medium")}</MenuItem>
								<MenuItem value={6}>{t("react.surveys.theme.editor.field.elevation.high")}</MenuItem>
								<MenuItem value={12}>{t("react.surveys.theme.editor.field.elevation.veryhigh")}</MenuItem>
							</TextField>
							<div className={classes.colorPicker}>
								<p className={classes.p}>{t("react.surveys.theme.editor.field.button.variant")} :</p>
								<TripleStateButton
									stateValues={["text", "outlined", "contained"]}
									stateLabels={[t("react.surveys.button.text"), t("react.surveys.button.outlined"), t("react.surveys.button.contained")]}
									stateValue={theme.json.customNextButton.variant}
									setStateValue={(value) => {handleThemeChange("buttonVariant", value)}}
								/>
							</div>
						</div>
						<ThemeProvider theme={customTheme}>
							<div className={classes.previewContainer}
							     style={{backgroundColor: theme.json.backgroundColor}}>
								<Paper elevation={theme.json.paper.elevation}>
									<LinearProgress style={{margin: '0px 24px 10px 24px'}} variant="determinate" value={20}/>
									<Container className={classes.positionRelative}>
										<Grid container spacing={4}>
											<Grid item xs={12} className={classes.textAndButton}>
												{logo !== undefined &&
													<Box style={{width: "100%", display: "flex", margin: "16px 0px", justifyContent: theme.json.logo.position}}>
														<img src={logo} alt="logo" style={{width: theme.json.logo.width, objectFit: "contain"}} />
													</Box>
												}
												<Box className={classes.flexBetween}>
													<div className="ql-view">
														<p><strong>{t("react.surveys.theme.editor.preview.title")}</strong></p>
														<p><span className="ql-size-small">{t("react.surveys.theme.editor.preview.subtitle")}</span></p>
													</div>
												</Box>
												<Box>
													<RadioGroup defaultValue={"Two"}>
														<FormControlLabel value="One" control={<Radio/>} label={t("react.surveys.theme.editor.preview.checkbox.disabled")} disabled/>
														<FormControlLabel value="Two" control={<Radio/>} label={t("react.surveys.theme.editor.preview.radio.one")}/>
														<FormControlLabel value="Three" control={<Radio/>} label={t("react.surveys.theme.editor.preview.radio.two")}/>
													</RadioGroup>
												</Box>
												<Box>
													<FormGroup>
														<FormControlLabel control={<Checkbox defaultChecked/>} label={t("react.surveys.theme.editor.preview.checkbox")}/>
														<FormControlLabel disabled control={<Checkbox/>} label={t("react.surveys.theme.editor.preview.checkbox.disabled")}/>
													</FormGroup>
												</Box>
												<Box>
													<Stepper className={classes.styleStep}
													         orientation={device === MOBILE ? "vertical" : "horizontal"}
													         alternativeLabel={device !== MOBILE}>
														<Step>
															<StepLabel
																StepIconComponent={() => <CircleIcon sx={{color: theme.json.button.backgroundColor}}/>}>
																{t("react.surveys.theme.editor.preview.step.one")}
															</StepLabel>
														</Step>
														<Step active>
															<StepLabel StepIconComponent={() => <CheckCircleIcon
																sx={{color: theme.json.button.backgroundColor}}/>}>
																{t("react.surveys.theme.editor.preview.step.two")}
															</StepLabel>
														</Step>
														<Step>
															<StepLabel
																StepIconComponent={() => <CircleIcon style={{color: 'grey'}}/>}>
																{t("react.surveys.theme.editor.preview.step.three")}
															</StepLabel>
														</Step>
													</Stepper>
												</Box>
												<Box>
													<TextField variant={"outlined"} label={t("react.surveys.theme.editor.preview.label")} fullWidth/>
												</Box>
												<Box>
													<Button
														variant={cataSelected ? "contained" : "outlined"}
														style={{width: '100%', textTransform: 'none', height: 50, marginBottom: 13}}
														onClick={() => setCataSelected(!cataSelected)}
													>
														{t("react.surveys.theme.editor.preview.cata")}
													</Button>
												</Box>
											</Grid>
										</Grid>
										<div className={classes.flexEnd}>
											<Button variant={theme.json.customNextButton.variant}>{t("react.surveys.theme.editor.preview.button")}</Button>
											<Button disabled variant={theme.json.customNextButton.variant}>{t("react.surveys.theme.editor.preview.checkbox.disabled")}</Button>
										</div>
									</Container>
								</Paper>
							</div>
						</ThemeProvider></>
				}
			</DialogContent>
			<DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
				<Button
					size="large"
					startIcon={<ReplayIcon/>}
					onClick={handleReset}
				>
					{t('react.button.reset')}
				</Button>
				<div>
					<Button
						size="large"
						startIcon={<CloseIcon/>}
						onClick={handleClose}
					>
						{t('react.button.cancel')}
					</Button>
					<Button
						size="large"
						startIcon={<SaveIcon/>}
						color="primary"
						onClick={handleSaveTheme}
					>
						{t('react.button.save')}
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}