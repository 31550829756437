import React, { Fragment, useState, useEffect, useContext, useReducer } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { SurveyContext } from './context';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import { TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { isBlank, getMimeType } from '../../utils/utils';
import { extractMinMaxOfInterval, extractListOfNumerics } from '../../utils/surveysUtils';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import { Trans } from 'react-i18next';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import SurveysService from '../../services/SurveysService';
import Typography from '@mui/material/Typography';

const surveysService = new SurveysService();

const useStyles = makeStyles()(theme => ({
    dialogComponent: {
        minHeight: '400px',
    },
    sliderQuota: {
        marginTop: '10px',
        marginLeft: '20px',
        maxWidth: "350px",
    },
    textFieldEditAnswer: {
        marginBottom: "10px",
        marginLeft: "17px",
        marginTop: '15px',
        width: "360px"
    },
    editIcon: {
        marginRight: '10px',
        verticalAlign: 'middle'
    },
    marginLeftTextHelper: {
        marginLeft: '15px',
        marginBottom: '5px',
        fontStyle: 'italic'
    },
    dividerHelp: {
        marginBottom: '10px',
        marginTop: '10px'
    },
    previewRoot: {
        marginBottom: '25px',
        margin: '0',
        padding: '5px',
        width: `250px`,
        height: `150px`,
        display: 'inline-block',
        borderStyle: 'dotted',
        borderRadius: '7px',
        marginLeft: 70,
    },
    previewImg: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '140px',
        position: 'relative',
        top: '-50px'
    },
    previewBtns: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        height: '50px',
        position: 'relative', top: '25%', zIndex: '100'
    },
    alignCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '200px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 18,
        marginRight: 20,
    },
    labelSwitchQuestion: {
        marginTop: 5,
    },
}));

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 100,
        label: '100%',
    },
];

export default function ParamsQuestions(props) {

    const { classes } = useStyles();

    const { t, uuid, setOpenDialogQuestions, openDialogQuestions, answerIndex, openSnackbar } = props;

    const { setNeedToSave, getComponentConfiguration, getFormConfiguration, formIdParameter, mediaFiles, setMediaFiles } = useContext(SurveyContext);
    const [valueQuota, setValueQuota] = useState(0);
    const [valueQuestion, setValueQuestion] = useState('');
    const [valueFixedPosition, setValueFixedPosition] = useState(false);
    const [valueScreenOut, setValueScreenOut] = useState(false);
    const [freeField, setFreeField] = useState(false);
    const [valueExclusiveQuestion, setValueExclusiveQuestion] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [uploading, setUploading] = useState(false);
    const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
    const [hasImage, setHasImage] = useState(-1); // -1 = img is not supported / 0 = no img loaded / xxx > 0 id of the image

    useEffect(() => {
        if (!openDialogQuestions) return;

        // get current component we are configuring
        let thisComponent = getComponentConfiguration(uuid);

        if (thisComponent.question.answers[answerIndex].fixedPosition === true) {
            setValueFixedPosition(true);
        } else {
            setValueFixedPosition(false);
        }

        if (thisComponent.question.answers[answerIndex].screenOut === true) {
            setValueScreenOut(true);
        } else {
            setValueScreenOut(false);
        }

        if (thisComponent.question.answers[answerIndex].freeField === true) {
            setFreeField(true);
        } else {
            setFreeField(false);
        }

        if (thisComponent.question.answers[answerIndex].exclusive === true) {
            setValueExclusiveQuestion(true);
        } else {
            setValueExclusiveQuestion(false);
        }

        if (thisComponent.question.answers[answerIndex].quota === 0) {
            setValueQuota(0);
        } else {
            setValueQuota(thisComponent.question.answers[answerIndex].quota);
        }

        setValueQuestion(thisComponent.question.answers[answerIndex].answer);
        if (thisComponent.question.answers[answerIndex].imageId > 0) {
            setHasImage(thisComponent.question.answers[answerIndex].imageId);
        } else {
            setHasImage(0);
        }
    }, [openDialogQuestions]);

    const saveParamsAnswer = () => {
        let thisComponent = getComponentConfiguration(uuid);
        setOpenDialogQuestions(false);
        thisComponent.question.answers[answerIndex].quota = valueQuota;
        thisComponent.question.answers[answerIndex].answer = valueQuestion;
        thisComponent.question.answers[answerIndex].fixedPosition = valueFixedPosition;
        thisComponent.question.answers[answerIndex].screenOut = valueScreenOut;
        thisComponent.question.answers[answerIndex].freeField = freeField;
        thisComponent.question.answers[answerIndex].exclusive = valueExclusiveQuestion;
        setNeedToSave(true);
    };

    const enableQuota = () => {
        if (valueQuota === 0) {
            let thisComponent = getComponentConfiguration(uuid);
            let unparsedValue = ((1 / thisComponent.question.answers.length) * 100).toFixed(0)
            setValueQuota(parseInt(unparsedValue));
        } else {
            setValueQuota(0);
        }
        forceUpdate();
        setNeedToSave();
    };

    const enableScreenOut = () => {
        setValueScreenOut(!valueScreenOut);
        forceUpdate();
        setNeedToSave();
    };

    const enableFreeField = () => {
        setFreeField(!freeField);
        forceUpdate();
        setNeedToSave();
    };

    const enablePositionFixed = () => {
        setValueFixedPosition(!valueFixedPosition);
        forceUpdate();
        setNeedToSave();
    };

    const enableExclusiveQuestion = () => {
        setValueExclusiveQuestion(!valueExclusiveQuestion);
        forceUpdate();
        setNeedToSave();
    };

    const handleChangeQuota = (event, newValue) => {
        setValueQuota(newValue);
    };

    const isFreeFieldSwitchReachable = () => {
        // NO if it's the BindField is enable
        if(getComponentConfiguration(uuid).question.bindFieldEnable) return false;

        const howManyOtherAnswersHaveTheFreeFieldChecked = getComponentConfiguration(uuid).question.answers
            // except the current answer
            .filter((a, idx) => idx !== answerIndex && a.freeField)
            .length;

        // YES if no other free field
        return howManyOtherAnswersHaveTheFreeFieldChecked === 0;
    }

    const getMessageIfNotValid = () => {
        // if it's not a bind field, do no control content => NO SPECIAL MARK
        if(!getComponentConfiguration(uuid).question.bindFieldEnable) return null;

        let message = null;

        if(getComponentConfiguration(uuid).question.bindType === 'interval') {
            // extract values from the interval
            const minMax = extractMinMaxOfInterval(getComponentConfiguration(uuid).question.answers[answerIndex].answer);
            if(!minMax /* it's NOT a valid interval */) {
                message = "react.project.collectforms.intervalNotValid.help";
            }
        } else if (getComponentConfiguration(uuid).question.bindType === 'list') {
            // extract values from the list
            const values = extractListOfNumerics(getComponentConfiguration(uuid).question.answers[answerIndex].answer);
            if(!values /* it's NOT a valid list of values */) {
                message = "react.project.collectforms.listNotValid.help";
            }
        }

        if(!message) return null;

        return (
            <div className={classes.marginLeftTextHelper}>
                <div className={classes.marginLeftTextHelper}>
                    <Trans i18nKey={message} />
                </div>
                <Divider className={classes.dividerHelp}/>
            </div>
        );
    }

    const uploadFile = (file) => {
        return surveysService.uploadFormFile(formIdParameter, file)
            .then(response => {
                surveysService.getFormFileUrl(formIdParameter, response.data.id).then(res => {
                    const newfiles = { ...mediaFiles };
                    newfiles[res.id] = res.url;
                    setMediaFiles(newfiles);
                });
                return response;
            })
            .catch(error => {
                openSnackbar('error', t('react.error.save.message'));
                return;
              });
    }

    const generateSetImageButton = (uuid, setUploading) => {
        let title = t("react.project.collectforms.button.selectfile.tooltip");
        return (
            <span>
                <IconButton variant="outlined" color="primary" component="label" size="large" title={title}>
                    <ImageIcon />
                    <input type="file" accept="image/*" onChange={(event) => handleUploadMediaFile(event, uuid, setUploading)} style={{ display: "none" }} />
                </IconButton>
            </span>
        );
    };

    const generateDeleteImageButton = (uuid) => {
        if (getComponentConfiguration(uuid).question.answers[answerIndex].imageId > 0) {
            let title = t("react.project.collectforms.button.deletefile.tooltip");
            return (
                <span>
                    <IconButton
                        variant="outlined"
                        color="primary"
                        component="label"
                        onClick={() => handleDeleteMediaFile(uuid, getComponentConfiguration(uuid).question.answers[answerIndex].imageId)}
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </span>
            );
        } else {
            return '';
        }
    };

    const handleUploadMediaFile = (event, uuid, setUploading) => {
        // read the incoming file and detect its type
        const file = event.target.files[0];
        const mimeType = getMimeType(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            setUploading(true);

            // read asked ArrayBuffer
            const arrayBufferView = e.target.result;
                
            // create blob and image
            const blob = new Blob([arrayBufferView], {type: mimeType});
            const destfile = new File([blob], file.name, {
                    lastModified: new Date(),
                type: mimeType
            });

            // upload the file using our api
            uploadFile(destfile).then(response => {
                    getComponentConfiguration(uuid).question.answers[answerIndex].imageId = response.data.id;
                    setNeedToSave(true);
                }).catch((err) => {
                    openSnackbar('error', t('react.error.save.message'));
                }).finally(() => {
                    setUploading(false);
                });
        };

        // ask content as ArrayBuffer
        reader.readAsArrayBuffer(file);
    };

    const handleDeleteMediaFile = (uuid, mediaFileId) => {
        getComponentConfiguration(uuid).question.answers[answerIndex].imageId = 0;
        delete mediaFiles[mediaFileId];
        setNeedToSave(true);
    }

    const cancelPopup = () => {
        if (hasImage <= 0 /* no image at load */ && getComponentConfiguration(uuid).question.answers[answerIndex].imageId > 0 /* an image has been added */) {
            let mediaFileId = getComponentConfiguration(uuid).question.answers[answerIndex].imageId;
            getComponentConfiguration(uuid).question.answers[answerIndex].imageId = 0;
            delete mediaFiles[mediaFileId];
        } 
        setOpenDialogQuestions(false);
    }

    if(!openDialogQuestions) return null;

    return (
        <Dialog
            open={openDialogQuestions}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitleWithCloseIcon
                startIcon={<EditIcon color='primary' className={classes.editIcon} />}
                title={t("react.project.collectforms.params.answer")}
                callbackOnclose={() => cancelPopup()}
            />
            <DialogContent component="div" className={classes.dialogComponent} >
                <DialogContentText component="div">
                    <TextField
                        value={valueQuestion}
                        multiline={true}
                        onChange={(e) => setValueQuestion(e.target.value)}
                        className={classes.textFieldEditAnswer}
                        label={t('react.project.collectforms.params.edit.answer')}
                        variant="outlined"
                        error={isBlank(valueQuestion)}
                        fullWidth
                    />
                    <br />
                    {/* display a message if the answer is not valid as interval or list of values */}
                    {getMessageIfNotValid()}
                    {getComponentConfiguration(uuid).question.shuffle &&
                        <div className={classes.flexBetween}>
                            <Typography className={classes.labelSwitchQuestion}>{t("react.project.collectforms.answer.fixed")}</Typography>
                            <FormControlLabel
                                disabled={getComponentConfiguration(uuid).shuffle === false}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={valueFixedPosition === true} color="secondary" onChange={enablePositionFixed}  />}
                                labelPlacement="start"
                            />
                        </div>
                    }
                    {getComponentConfiguration(uuid).question.type === "multiple" &&
                        <div className={classes.flexBetween}>
                            <Typography className={classes.labelSwitchQuestion}>{t("react.project.collectforms.answer.exclusive.question")}</Typography>
                            <FormControlLabel
                                disabled={getComponentConfiguration(uuid).shuffle === false}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={valueExclusiveQuestion === true} color="secondary" onChange={enableExclusiveQuestion}  />}
                                labelPlacement="start"
                            />
                        </div>
                    }
                    <div className={classes.flexBetween}>
                        <Typography className={classes.labelSwitchQuestion}>{t("react.project.collectforms.answer.screenout")}</Typography>
                        <FormControlLabel
                            className={classes.switchSingleOrMultiple}
                            control={<Switch checked={valueScreenOut === true} color="secondary" onChange={enableScreenOut}  />}
                            labelPlacement="start"
                        />
                    </div>
                    <div className={classes.flexBetween}>
                        <Typography className={classes.labelSwitchQuestion}>{t("react.project.collectforms.answer.freefield")}</Typography>
                        <FormControlLabel
                            disabled={!isFreeFieldSwitchReachable()}
                            className={classes.switchSingleOrMultiple}
                            control={<Switch checked={freeField === true} color="secondary" onChange={enableFreeField}  />}
                            labelPlacement="start"
                        />
                    </div>
                    <div className={classes.flexBetween}>
                        <Typography className={classes.labelSwitchQuestion}>{t("react.project.collectforms.enable.quota")}</Typography>
                        <FormControlLabel
                            disabled={getFormConfiguration().nbMaxAnswers === 0}
                            className={classes.switchSingleOrMultiple}
                            control={<Switch checked={valueQuota !== 0} color="secondary" onChange={enableQuota}  />}
                            labelPlacement="start"
                        />
                    </div>
                    <Fragment>
                        <Slider
                            disabled={valueQuota === 0 || getFormConfiguration().nbMaxAnswers === 0}
                            value={valueQuota}
                            valueLabelDisplay={valueQuota === 0 ? "off" : "on"}
                            onChange={handleChangeQuota}
                            marks={marks}
                            className={classes.sliderQuota}
                        />
                    </Fragment>
                    <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                        onMouseOver={e => setUploadButtonVisibility(true)}
                        onMouseOut={e => setUploadButtonVisibility(false)}
                    >
                        {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                            <div style={{ display: (formIdParameter > 0 && (uploading || true === true || uploadButtonVisibility)) ? 'block' : 'none' }}>
                                {!uploading && generateSetImageButton(uuid, setUploading)}
                                {!uploading && generateDeleteImageButton(uuid)}
                                {uploading && <CircularProgress />}
                            </div>
                        </div>}
                        {getComponentConfiguration(uuid).question.answers[answerIndex].imageId > 0 && <div className={classes.alignCenter}>
                            <img
                                id={`img_${uuid}`}
                                className={classes.previewImg}
                                src={mediaFiles[getComponentConfiguration(uuid).question.answers[answerIndex].imageId]}
                                style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                        </div>}
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CloseIcon />}
                    onClick={() => cancelPopup()}
                >
                    {t('react.button.cancel')}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<SaveIcon />}
                    color="primary"
                    disabled={isBlank(valueQuestion) || valueQuestion.length > 255}
                    onClick={() => saveParamsAnswer()}
                >
                    {t('react.button.save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
