import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { tss } from 'tss-react/mui';
import { CollectorContext } from './context';
import useDeviceDetection, { MOBILE } from "../shared/DetectDevice/useDeviceDetection";
import CollectorsTestRef from './CollectorsTestRef';
import Rating from '@mui/material/Rating';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = tss
  .withParams()
  .create(({ theme, justifyContent }) => ({
    checkCircleIcon: {
      color: theme.palette.primary.main,
    },
    circleIcon: {
      color: 'grey',
    },
    styleBox: {
      marginTop: 5,
      display: 'flex',
      justifyContent: `${justifyContent}`
    },
    styleStep: {
      width: '100%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    ratingBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: `${justifyContent}`,
      alignItems: `${justifyContent}`
    },
}));

export default function CustomSlider(props) {

  const {
    embeddedReplaceDynamicElements } = useContext(CollectorContext);

  const { identifier /* used to identify when to reload because 'answers' may reload unexpectedly (= question or item id) */, 
    answers, selectAnswer, selectedIndex, style = 'slider' /* of 'rating' */, position = 'left' } = props;

  const { classes } = useStyles({justifyContent: position});

  const [mouseOverIndex, setMouseOverIndex] = useState(-1);

  const device = useDeviceDetection();

  useEffect(() => {
    setMouseOverIndex(-1);
  }, [identifier]);

  const handleMouseClick = (index) => {
    selectAnswer(answers[index]);
  };

  const CustomStepIcon = ({ index }) => {
    // checked if before or equals to the selected index
    let isChecked = index === selectedIndex;
    let isIlluminated = index <= selectedIndex;

    // follow the mouse if hover
    if(mouseOverIndex >= 0) {
      isChecked = index === mouseOverIndex;
      isIlluminated = index <= mouseOverIndex;
    }

    if(isChecked) {
      return <CheckCircleIcon className={classes.checkCircleIcon}/>;
    } else if (isIlluminated) {
      return <CircleIcon className={classes.checkCircleIcon}/>
    } else {
      return <CircleIcon className={classes.circleIcon}/>
    }
  };

  const isActive = (index) => {
    let isActive = index <= selectedIndex;

    if(mouseOverIndex >= 0) {
      isActive = index <= mouseOverIndex;
    }

    return isActive;
  }

  if(!device) return null;

  return (
    <Box className={classes.styleBox}>
      {style === 'rating' && 
        <Box className={classes.ratingBox}>
          <Rating 
            name="no-value" 
            /* Rating value goes from 1 to 5 */
            value={selectedIndex >= 0 && selectedIndex <= 4 ? selectedIndex + 1 : 0}
            onChange={(event, newValue) => {
              handleMouseClick(newValue - 1);
            }}
            sx={{fontSize: '42px'}}
          />
          {answers.length === 6 && <FormControlLabel 
            style={{color: (selectedIndex >= 0 && selectedIndex <= 4) ? '#aaaaaa' : '#555555', }} 
            sx={{margin: '0 !important', '& .MuiCheckbox-root': {paddingLeft: 0}} /* in order to have to space left or right */}
            control={<Checkbox disabled={selectedIndex >= 0 && selectedIndex <= 4} 
              checked={selectedIndex > 4} 
              onChange={() => handleMouseClick(5)}
              />} 
            label={answers[5].text}/>
          }
      </Box>
      }
      {style === 'slider' && <Stepper activeStep={mouseOverIndex} className={classes.styleStep}
        orientation={device === MOBILE ? "vertical" : "horizontal"} alternativeLabel={device !== MOBILE}>
        {answers.map((answer, index) => (
          <Step
            key={answer.id}
            onMouseOver={() => setMouseOverIndex(index)}
            onMouseOut={() => setMouseOverIndex(-1)}
            active={isActive(index)}
          >
            <StepLabel
              onClick={() => handleMouseClick(index)}
              StepIconComponent={() => <CustomStepIcon index={index} />}>
              <CollectorsTestRef {...props} refToDisplay={answer.ref} />
              {embeddedReplaceDynamicElements(answer.text)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>}
    </Box>
  );
}
