import { isBlank } from '../utils/utils';
import { RestClientService } from './RestClientService';
require('dotenv').config();

const getPluginsStr = () =>{
    const x=navigator.plugins.length;
    let txt;
    for(var i=0;i<x;i++)
    {
      txt+=navigator.plugins[i].name + "|";
    }
    return txt;
};

const identity = {
    plugins: getPluginsStr(),
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    browserName: navigator.appCodeName,
    locale: navigator.language
};

class SurveysService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  // -- ADMINISTRATIVE METHODS - Auth required -------------------------------------------------------------------------------

  async fetchCollectors(page, rowsPerPage, sortBy, sortDirection, keywords) {
    return this.getRestClient().get('/api/surveys/v1.0/?include=users,customers&from=' + (page*rowsPerPage) + '&size=' + rowsPerPage + '&sort=' + sortBy+ '&order=' + sortDirection + '&keywords=' + encodeURIComponent(keywords));
  }

  async fetchWelcomeCollectors() {
    return this.getRestClient().get('/api/surveys/v1.0/?size=3&sort=id&order=desc');
}

  async getReporting(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/advancement`);
  }

  async getReportingAsExcelFile(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/advancement/xlsx`, {responseType: 'blob'});
  }

  async getDatamap(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/datamap`);
  }

  async saveDatamap(formId, datamap) {
    return this.getRestClient().post(`/api/surveys/v1.0/${formId}/datamap`, datamap);
  }

  async buildDatamap(formId) {
    return this.getRestClient().put(`/api/surveys/v1.0/${formId}/datamap/build`);
  }

  async deleteCollector(collectorId) {
    return this.getRestClient().delete(`/api/surveys/v1.0/${collectorId}`);
  }

  async duplicateCollector(collectorId, copyName) {
    return this.getRestClient().post(`/api/surveys/v1.0/duplicate/${collectorId}`, copyName);
  }

  async uploadFormFile(formId, file) {
      var formData = new FormData();
      formData.append("uploadedFile", file);

      return this.getRestClient().post(`/api/surveys/v1.0/${formId}/img/`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });
  }

  async deleteFormFile(formId, fileId) {
      return this.getRestClient().delete(`/api/surveys/v1.0/${formId}/img/${fileId}`);
  }

  async getFormFileUrl(formId, fileId) {
      return this.getRestClient().get(`/api/surveys/v1.0/${formId}/img/${fileId}`, {responseType: 'arraybuffer'}).then(response => {
          const arrayBufferView = new Uint8Array(response.data);
          const blob = new Blob([arrayBufferView], {type: response.contentType});
          return {id: fileId, url: URL.createObjectURL(blob)};
      });
  }

  async fetchForm(formId) {
      return this.getRestClient().get(`/api/surveys/v1.0/${formId}`);
  }

  async saveForm(formId, form) {
    if(formId > 0) {
      // existing form
      return this.getRestClient().put(`/api/surveys/v1.0/${formId}`, form);
    } else {
      // new form
      return this.getRestClient().post('/api/surveys/v1.0/', form);
    }
  }

  async getFormSignature(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/signature`);
  }

  async createFormGroup(formId) {
    return this.getRestClient().post(`/api/surveys/v1.0/${formId}/groups`);
  }

  async setFormActivityStatus(formId, isActive) {
      return this.getRestClient().put(`/api/surveys/v1.0/${formId}/activity-status/` + isActive);
  }

  async deleteCollectedData(formId) {
      return this.getRestClient().delete(`/api/surveys/v1.0/${formId}/collected/data`);
  }

  async getAnswersAsExcelFile(formId) {
      return this.getRestClient().get(`/api/surveys/v1.0/${formId}/answers/xlsx`, {responseType: 'blob'});
  }

  async getAnswersAsTriple_sFile(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/answers/sss`, {responseType: 'blob'});
  }

  async getFormLinksAsExcelFile(formId, count) {
      return this.getRestClient().get(`/api/surveys/v1.0/${formId}/links/xlsx?count=${count}`, {responseType: 'blob'});
  }

  async fireAutoThrustSurvey(formId, payload) {
    return this.getRestClient().put(`/api/surveys/v1.0/${formId}/fire-auto-thrust`, payload);
  }

  async getFormTranslations(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/translations`);
  }

  async saveFormTranslations(formId, translations) {
    return this.getRestClient().post(`/api/surveys/v1.0/${formId}/translations`, translations);
  }

  async saveFormTheme(formId, payload) {
    return this.getRestClient().post(`/api/surveys/v1.0/${formId}/theme`, payload);
  }

  async getFormTheme(formId) {
    return this.getRestClient().get(`/api/surveys/v1.0/${formId}/theme`);
  }

  /**
   * Return the block identified by 'refOrUuid'
   * AND if AFTER the current block identified by the 'uuid'
   * AND if the block is a question or a battery
   * AND both MUST NOT BE in the same shuffled group
   * AND both MUST NOT BE in different cells
   * 
  */
  findBlockByRefOrUuidAndUsableToSortElements(refOrUuid, flattenBlocksForm, uuid /* uuid of the current block */) {
    var expectedBlock = isBlank(refOrUuid) ? undefined : flattenBlocksForm.find(b => b.configuration.ref === refOrUuid || b.uuid === refOrUuid);

    // check if usable
    if (!expectedBlock || !(expectedBlock.type === "question" || expectedBlock.type === "battery")) return undefined;

    // check the positions
    let indexOfTheCurrentBlock = flattenBlocksForm.findIndex(b => b.uuid === uuid /* uuid of the current block */);
    let indexOfTheExpectedBlock = flattenBlocksForm.findIndex(b => b.uuid === expectedBlock.uuid);

    if(indexOfTheCurrentBlock <= indexOfTheExpectedBlock) return undefined;

    // check if both blocks are in the same group and if shuffle is enabled, it's not allowed
    let currentBlock = flattenBlocksForm.find(b => b.uuid === uuid /* uuid of the current block */);
    let currentBlockData = this.getCellAndShuffledParentGroupIdsTheBlockBelongsTo(currentBlock, flattenBlocksForm);
    let expectedBlockData = this.getCellAndShuffledParentGroupIdsTheBlockBelongsTo(expectedBlock, flattenBlocksForm);

    // they MUST NOT HAVE a common shuffled group
    if(currentBlockData.shuffledParentGroupIds.some(id => expectedBlockData.shuffledParentGroupIds.includes(id))) return undefined;

    // they MUST NOT BE in different CELL
    if(currentBlockData.cellId > 0 && expectedBlockData.cellId > 0 && currentBlockData.cellId !== expectedBlockData.cellId) return undefined;

    return expectedBlock;
  }

  getCellAndShuffledParentGroupIdsTheBlockBelongsTo (block, flattenBlocksForm) {
    const result = { cellId: 0, shuffledParentGroupIds:[] };
    let currentBlock = block;
    while (currentBlock) {
      let parentBlock = null;
      if (currentBlock.configuration.groupId > 0) {
        parentBlock = flattenBlocksForm.find(b => b.configuration.group?.id === currentBlock.configuration.groupId);
        if (parentBlock) {
          if(parentBlock.configuration.group.type === 'cell') {
            result.cellId = parentBlock.configuration.id;
          }
          if(parentBlock.configuration.group.shuffleBlocks) {
            result.shuffledParentGroupIds.push(parentBlock.configuration.id);
          }
        }
      }
      currentBlock = parentBlock;
    }
    return result;
  }
}

export default SurveysService;
