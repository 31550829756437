import { useState, useContext, useReducer, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SurveyContext } from './context';
import CircularProgress from '@mui/material/CircularProgress';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import QuillEditor from './QuillEditor.js';
import Button from '@mui/material/Button';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ParamsImage from './ParamsImage';
import BlockHeader from './BlockHeader.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const useStyles = makeStyles()(theme => ({
  previewRoot: {
    marginBottom: '25px',
    margin: '0',
    padding: '5px',
    width: `250px`,
    height: `150px`,
    display: 'inline-block',
    borderStyle: 'dotted',
    borderRadius: '7px',
  },
  previewImg: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '140px',
    position: 'relative',
    top: '-50px'
  },
  previewBtns: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    height: '50px',
    position: 'relative', top: '25%', zIndex: '100'
  },
  previewFullscreen: {
    margin: '0px 0 15px 0',
    textWrap: 'nowrap'
  },
  alignCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '200px'
  },
  paddingPaper: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 1,
    paddingBottom: 30
  },
  gridImage: {
    marginTop: 20,
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelSwitchQuestion: {
    marginTop: 10,
  },
  switchSingleOrMultiple: {
    marginRight: -5,
    padding: 0,
  },
}));

export default function Text(props) {

  const { classes } = useStyles();

  const { t, uuid } = props;

  const { formIdParameter, generateSetImageButton, generateDeleteImageButton,
    setNeedToSave, getComponentConfiguration, mediaFiles } = useContext(SurveyContext);

  const [uploading, setUploading] = useState(false);
  const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
  const [activeTempo, setActiveTempo] = useState(getComponentConfiguration(uuid).timeout > 0);
  const [switchAccessibleFrom, setSwitchAccessibleFrom] = useState(getComponentConfiguration(uuid).accessibleFrom != undefined);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [openDialogImage, setOpenDialogImage] = useState(false);

  const quillRef = useRef();

  const changeField = (fieldName, value) => {
    switch (fieldName) {
      case "ref":
        getComponentConfiguration(uuid).ref = value;
        break;
      case "title":
        getComponentConfiguration(uuid).title = value;
        break;
      case "content":
        getComponentConfiguration(uuid).content = value;
        break;
      case "enabledBlock":
        getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
        break;
      case "enabledTemporisation":
        if(activeTempo) {
          // Tempo is going to be desactivated
          getComponentConfiguration(uuid).timeout = 0;
          getComponentConfiguration(uuid).transitionAuto = false;
        } else {
          // else Tempo is going to be activated => default timeout of 3
          getComponentConfiguration(uuid).timeout = 3;
        }
        setActiveTempo(!activeTempo);
        break;
      case "switchAccessibleFrom":
        if(switchAccessibleFrom) {
          getComponentConfiguration(uuid).accessibleFrom = undefined;
        } else {
          getComponentConfiguration(uuid).accessibleFrom = dayjs();
        }
        setSwitchAccessibleFrom(!switchAccessibleFrom);
        break;
      case "setAccessibleFrom":
        getComponentConfiguration(uuid).accessibleFrom = dayjs(value);
        break;
      case "temporisationDelay":
        if (value >= 0) {
          getComponentConfiguration(uuid).timeout = value;
        }
        break;
      case "transitionAuto":
        getComponentConfiguration(uuid).transitionAuto = !getComponentConfiguration(uuid).transitionAuto;
        break;
    }
    setNeedToSave(true);
    forceUpdate();
  };

  return (
    <>
    {/* Define some popup and dialog */}
    <ParamsImage
        {...props}
        uuid={uuid}
        openDialogImage={openDialogImage}
        setOpenDialogImage={setOpenDialogImage}
        enableFullScreenMode={true}
      />
    {/* Header of the bloc */}
    <BlockHeader
      {...props}
      blockRef={getComponentConfiguration(uuid).ref}
      setBlockRef={(newValue) => changeField('ref', newValue)}
      isActive={getComponentConfiguration(uuid).active}
      setIsActive={(newValue) => changeField('enabledBlock', newValue)}
      title={t("react.project.collectforms.block.text.title")}
    />
    <Paper className={classes.paddingPaper}>
      <Grid item xs={3} xm={4} className={classes.gridImage}>
        <div id={`preview_img_${uuid}`} className={classes.previewRoot}
          onMouseOver={e => setUploadButtonVisibility(true)}
          onMouseOut={e => setUploadButtonVisibility(false)}
        >
          {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
            <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
              {!uploading && generateSetImageButton(uuid, setUploading)}
              {!uploading && generateDeleteImageButton(uuid)}
              {uploading && <CircularProgress />}
            </div>
          </div>}
          {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
            <img
              id={`img_${uuid}`}
              className={classes.previewImg}
              src={mediaFiles[getComponentConfiguration(uuid).imageId]}
              style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
          </div>}
        </div>
      </Grid>
      {getComponentConfiguration(uuid).imageId > 0 &&
        <Button style={{marginTop: 20, marginBottom: 20}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
          {t("react.surveys.button.params.image")}
        </Button>
      }
      <TextField
        label={`${t("react.project.collectforms.block.field.title")} (${(getComponentConfiguration(uuid).title || '').length} / 255)`}
        fullWidth
        value={getComponentConfiguration(uuid).title}
        onChange={e => changeField('title', e.target.value)}
        inputProps={{ maxLength: 255 }}
        style={{marginTop: getComponentConfiguration(uuid).imageId > 0 ? 0 : 20}}
      ></TextField>
      <QuillEditor
        ref={quillRef}
        value={getComponentConfiguration(uuid).content}
        onChange={(value) => changeField('content', value)}
        isError={(formIdParameter > 0 && isBlankHtml(getComponentConfiguration(uuid).content) && getComponentConfiguration(uuid).imageId === 0)}
        style={{height: 400, marginTop: 30, paddingBottom: 42}}
        placeholder= {t("react.project.collectforms.content")}
        {...props}
      />
      <Box className={classes.flexBetween}>
        <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.temporisation')}</Typography>
        <FormControlLabel
            className={classes.switchSingleOrMultiple}
            control={<Switch checked={activeTempo} color="secondary" onChange={e => changeField('enabledTemporisation', e.target.value)}  />}
            labelPlacement="start"
        />
      </Box>
      {getComponentConfiguration(uuid).timeout !== 0 &&
        <>
          <Box className={classes.flexBetween} >
            <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.timeout')}</Typography>
            <TextField
              value={getComponentConfiguration(uuid).timeout}
              onChange={(e) => changeField("temporisationDelay", e.target.value)}
              type='number'
              style={{ width: 40 }}
            />
          </Box>
          <Box className={classes.flexBetween}>
            <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.transition.mode')}</Typography>
            <FormControlLabel
              className={classes.switchSingleOrMultiple}
              control={<Switch checked={getComponentConfiguration(uuid).transitionAuto} color="secondary" onChange={e => changeField('transitionAuto', e.target.value)}  />}
              labelPlacement="start"
            />
          </Box>
        </>
      }
      <Box className={classes.flexBetween}>
        <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.text.accessibleFrom.switch')}</Typography>
        <FormControlLabel
            className={classes.switchSingleOrMultiple}
            control={<Switch checked={switchAccessibleFrom} color="secondary" onChange={e => changeField('switchAccessibleFrom', e.target.value)}  />}
            labelPlacement="start"
        />
      </Box>
      {getComponentConfiguration(uuid).accessibleFrom &&
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              ampm={false}
              value={dayjs(getComponentConfiguration(uuid).accessibleFrom)}
              onChange={(newValue) => changeField('setAccessibleFrom', newValue)}
              format="DD/MM/YY HH:mm"
            />
          </LocalizationProvider>
        </Box>
      }
    </Paper>
    </>
  );
};
