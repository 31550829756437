import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { toolbarStyles } from '../../common.js';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { AuthService } from '../../services/AuthService';
import SurveysService from '../../services/SurveysService';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { LoadData } from '../../Constants.js';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DialogDuplicateForm from './DialogDuplicateForm';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {getItemAriaLabel} from '../../utils/pagination.js';
import {Helmet} from "react-helmet";

const surveysService = new SurveysService();

function createData(id, userId, clientName, name, active, lang) {
  return { id, userId, clientName, name, active, lang };
}

const headCells = [
  { id: 'name', label: 'react.collectors.list.column.name', minWidth: 200 },
  // VISIBLE FOR ADMIN
  { id: 'userId', label: 'react.collectors.list.column.user', minWidth: 200, sortable: false, adminView: true },
  {id: 'clientName', label: 'react.collectors.list.column.client', minWidth:200, sortable: true },
  // END SPECIFICS
  { id: 'active', label: 'react.collectors.list.column.active', minWidth: 200, format: row => row['active'].toString() },
  { id: 'lang', label: 'react.collectors.list.column.lang', minWidth: 200 },
  { id: 'reporting', label: 'react.collectors.list.column.reporting', minWidth: 200, sortable: false, adminView: true },
];

function SurveysListHead(props) {
  const { classes, t, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.filter(function (headCell) {
          if (headCell.adminView === true && !AuthService.isAdmin()) {
            return false; // skip admin only to normal user
          } else if (headCell.adminView === false && AuthService.isAdmin()) {
            return false; // skip useless columns to admin
          }
          return true;
        }).map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
            {headCell.sortable === false ? (
              t(headCell.label)
            ) : (
              <TableSortLabel
                active={sortBy === headCell.id}
                direction={sortDirection}
                onClick={e => handleChangeSort(e, headCell.id)}
              >
                {t(headCell.label)}
                {sortBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const SurveysListToolbar = props => {
  const { classes } = toolbarStyles();
  const { t, selected, countForPagination, handleDeleteCollectors, handleCopyCollectors, keywords, handleKeywordsChange, setLoadData } = props;
  const numSelected = selected.length;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <QuestionAnswerIcon className={classes.mainIcon} />
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t("react.collectors.list.number.of.selected", { 'numSelected': numSelected })}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t("react.collectors.list.number.title", { 'countForPagination': countForPagination })}
        </Typography>
      )}

      <Input
        id="search-filter-basic"
        value={keywords}
        className={classes.searchfield}
        placeholder={t('react.translationtool.search.title')}
        onChange={e => handleKeywordsChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={e => handleKeywordsChange('')} edge="end" size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      {numSelected > 0 &&
        <Tooltip title={t('react.button.delete')}>
          <IconButton onClick={handleDeleteCollectors} size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
      {numSelected > 0 &&
        <Tooltip title={t('react.button.copy')}>
          <IconButton
            onClick={handleCopyCollectors}
            disabled={numSelected !== 1}
            size="large">
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      }
      <Tooltip title={t('react.button.add')}>
        <IconButton
          onClick={() => props.navigate('/surveys/add')}
          size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('react.button.refresh')}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer: {
    cursor: 'pointer',
  },
  textFieldCopy: {
    width: 400,
    marginLeft: 25,
    marginBottom: 30,
    marginTop: 15,
  },
  titleIcon: {
    marginRight: '12px',
    verticalAlign: 'sub'
  },
  iconButtonReporting: {
    height: 20,
    width: 20,
    margin: 0,
    padding: 0
  }
}));

export default function SurveysList(props) {

  const { classes } = useStyles();

  const { t, openSnackbar } = props;

  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [keywords, setKeywords] = useState('');

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if (sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc');
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const [loadKeywordsChange, setLoadKeywordsChange] = useState(false);
  const handleKeywordsChange = (newValue) => {
    setPage(0);
    setKeywords(newValue);
    setLoadKeywordsChange(true);
    setLoadData(LoadData.Load);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    surveysService.fetchCollectors(page, rowsPerPage, sortBy, sortDirection, keywords)
      .then(function (result) {
        // parse collectors
        setUsers(users.concat(result.data.users));
        setCustomers(customers.concat(result.data.customers));

        const newRows = [];
        for (let i = 0; i < result.data.hits.length; i++) {
          let hit = result.data.hits[i];
          let data = createData(hit.id, hit.userId, hit.clientName, hit.name, hit.active, hit.lang);
          newRows.push(data);
        }
        setRows(newRows);
        setCountForPagination(result.data.total);

      })
      .catch(err => {
        setRows([]);
        setCountForPagination(0);
        openSnackbar('error', t("react.collectorslist.error.while.loading"));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
        setLoadKeywordsChange(false);
      });
  }, [loadData, page, rowsPerPage, sortBy, sortDirection, loadKeywordsChange, keywords, users, customers, openSnackbar, t]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleEditCollector = (event, collectorIdToEdit) => {
    event.preventDefault();
    props.navigate(`/surveys/${collectorIdToEdit}`);
  };

  const goingToReporting = (event, surveyIdToEdit) => {
    event.preventDefault();
    props.navigate(`/surveys/${surveyIdToEdit}/reporting`);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteCollectors = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for (var i = 0; i < selected.length; i++) {
      promises.push(surveysService.deleteCollector(selected[i]));
    }

    Promise.all(promises)
      .then(function (values) {
        setDeleteDialogOpen(false);
        setLoadData(LoadData.Load);
        setSelected([]);
      })
      .catch(err => {
        openSnackbar('error', t("react.collectorslist.error.while.deleting"));
      });
  };

  const handleDeleteDialogCancel = () => {
    setDeleteDialogOpen(false);
  };

  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [nameCopyForm, setNameCopyForm] = useState('');
  const [spinnerCopy, setSpinnerCopy] = useState(false);
  const handleCopyCollectors = (event, row) => {
    setCopyDialogOpen(true);
    event.preventDefault();
    setNameCopyForm(rows.find(row => row.id === selected[0]).name);
  };

  const handleCopyDialogConfirm = () => {
    setSpinnerCopy(true);
    surveysService.duplicateCollector(selected[0], nameCopyForm)
      .then(function (values) {
        setLoadData(LoadData.Load);
        setSelected([]);
        openSnackbar('success', t("react.collectorslist.success.copy"));
        setCopyDialogOpen(false);
        setSpinnerCopy(false);
      })
      .catch(err => {
        openSnackbar('error', t("react.collectorslist.error.while.deleting"));
        setSpinnerCopy(false);
      });
  };

  const handleCopyDialogCancel = () => {
    setCopyDialogOpen(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.surveyslist')} />
      {loadData !== LoadData.Loaded && !loadKeywordsChange ? (<LinearProgress />)
        : (
          <Paper className={classes.paper}>
            <PopupTwoButtons
              variant='warning'
              openState={deleteDialogOpen}
              callbackOnclose={handleDeleteDialogCancel}
              callbackOnclickLeftButton={handleDeleteDialogCancel}
              callbackOnclickRightButton={handleDeleteDialogConfirm}
              title={t('react.collectors.list.confirm.delete.title')}
              content={t('react.collectors.list.confirm.delete.description')}
              leftButton={t('react.button.cancel')}
              rightButton={t('react.button.delete')}
            />
            <DialogDuplicateForm
              {...props}
              copyDialogOpen={copyDialogOpen}
              handleCopyDialogCancel={handleCopyDialogCancel}
              handleCopyDialogConfirm={handleCopyDialogConfirm}
              spinnerCopy={spinnerCopy}
              nameCopyForm={nameCopyForm}
              setNameCopyForm={setNameCopyForm}
            />
            <SurveysListToolbar
              {...props}
              keywords={keywords}
              selected={selected}
              countForPagination={countForPagination}
              handleDeleteCollectors={handleDeleteCollectors}
              handleCopyCollectors={handleCopyCollectors}
              handleKeywordsChange={handleKeywordsChange}
              setLoadData={setLoadData} />
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                size="medium"
              >
                <SurveysListHead
                  t={t}
                  classes={classes}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  handleChangeSort={handleChangeSort}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        className={classes.tableRowCursorPointer}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            id={`checkbox-${row.id}`}
                            onClick={event => handleClick(event, row.id)}
                            checked={isItemSelected}
                          />
                        </TableCell>
                        {headCells
                          .filter(function (headCell) {
                            if (headCell.adminView === true && !AuthService.isAdmin()) {
                              return false; // skip
                            } else if (headCell.adminView === false && AuthService.isAdmin()) {
                              return false; // skip
                            }
                            return true;
                          })
                          .map(column => {
                            let value = '';

                            if (column.id === 'userId') {
                              let realUser = users.filter(u => u.id === row['userId']);
                              if (realUser && realUser.length > 0) {
                                // avoid problem of load that breaks the page
                                value = realUser[0].firstname + ' ' + realUser[0].lastname;
                              }
                            } else if (column.id === 'customerId') {
                              let realUser = users.filter(u => u.id === row['userId']);
                              if (realUser && realUser.length > 0) {
                                // avoid problem of load that breaks the page
                                let realCustomer = customers.filter(el => el.id === realUser[0].customerId);
                                if (realCustomer && realCustomer.length > 0) {
                                  // avoid problem of load that breaks the page
                                  value = realCustomer[0].name;
                                }
                              }
                            } else if (column.id === 'reporting') {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title={t('react.collectors.list.tooltip.reporting')}>
                                    <IconButton
                                      className={classes.iconButtonReporting}
                                      onClick={event => goingToReporting(event, row.id)}
                                      size="large">
                                      <AssessmentIcon />
                                    </IconButton>
                                  </Tooltip>
                              </TableCell>
                              );
                            } else {
                              value = row[column.id];
                            }

                            return (
                              <TableCell key={column.id} align={column.align}
                                onClick={event => handleEditCollector(event, row.id)} >
                                {column.format ? column.format(row) : value}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={countForPagination}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('react.list.number.lignes.per.page')}
              labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', { 'from': from, 'to': to, 'count': count })}
              getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
    </div>
  );
}
