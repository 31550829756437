import React, { useState, useEffect } from 'react';
import {toolbarStyles} from '../../common.js';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import PersonIcon from '@mui/icons-material/Person';
import UserService from '../../services/UserService';
import Link from '@mui/material/Link';
import TableSortLabel from '@mui/material/TableSortLabel';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import {LoadData} from '../../Constants.js';
import { AuthService } from '../../services/AuthService';
import ChangeExpirationDateDialog from './ChangeExpirationDateDialog.js';
import dateFormat from 'dateformat';
import TripleStateButton from '../shared/TripleStateButton/TripleStateButton';
import { TbClockPause, TbClock, TbClockPlay } from "react-icons/tb";
import {getItemAriaLabel} from '../../utils/pagination.js';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import {Helmet} from "react-helmet";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const userService = new UserService();

function createData(id, uid, email, firstname, lastname, customerId, customerName, creationDateValue, updateDateValue, expirationDateValue) {
  let creationDate = new Date();
  creationDate.setTime(creationDateValue);
  let updateDate = new Date();
  updateDate.setTime(updateDateValue);
  let expirationDate = new Date();
  expirationDate.setTime(expirationDateValue);
  return { id, uid, email, firstname, lastname, customerId, customerName, creationDate, updateDate, expirationDate };
}

const headCells = [
  { id: 'lastname', label: 'react.users.table.header.lastname', minWidth: 200 },
  { id: 'firstname', label: 'react.users.table.header.firstname', minWidth: 200 },
  { id: 'email', label: 'react.users.table.header.email', minWidth: 200 },
  {
    id: 'customerName',
    label: 'react.users.table.header.client',
    minWidth: 150,
    format: (props, row) => (
      <Link href='#' onClick={() => props.navigate(`/customers/${row['customerId']}`)}>{row['customerName']}</Link>
    ),
    managerView: true,
  },
  {
    id: 'customerName',
    label: 'react.users.table.header.client',
    minWidth: 150,
    format: (props, row) => (
      <span>{row['customerName']}</span>
    ),
    managerView: false,
  },
  {
    id: 'expirationDate',
    label: 'react.users.table.header.expirationdate',
    minWidth: 170,
    align: 'right',
    format: (props, row) => dateFormat(row['expirationDate'], "UTC:dd/mm/yyyy"),
  },
];

function UsersListHead(props) {
  const { t, classes, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells
          .filter(function(headCell) {
            if(headCell.managerView === true && !AuthService.isManager()) {
              return false; // skip
            } else if(headCell.managerView === false && AuthService.isManager()) {
              return false; // skip
            }
            return true;
          })
          .map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
          <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortDirection}
              onClick={e => handleChangeSort(e, headCell.id)}
            >
          {t(headCell.label)}
          {sortBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const UsersListToolbar = props => {

  const { classes } = toolbarStyles();

  const { t, selected, countForPagination, handleDeleteUsers, handleSendFirstConnectionMail, keywords, handleFilterChange, setLoadData,
    stateFilter, handleChangeStateFilter, setOpenPopupExpirationDate } = props;

  const numSelected = selected.length;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <PersonIcon className={classes.mainIcon}/>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t("react.userlist.number.of.selected", {'numSelected': numSelected})}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('react.users.title', {'size': countForPagination})}
        </Typography>
      )}
      <Input
        id="search-filter-basic"
        className={classes.searchfield}
        placeholder={t('react.translationtool.search.title')}
        value={keywords}
        onChange={e => handleFilterChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={e => handleFilterChange('')} edge="end" size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Tooltip title={t('react.button.delete')}><span>
        <IconButton
          onClick={handleDeleteUsers}
          disabled={numSelected === 0}
          size="large">
          <DeleteIcon />
        </IconButton></span>
      </Tooltip>
      <Tooltip title={t('react.users.expiration.date.tooltip')}><span>
        <IconButton
          onClick={() => setOpenPopupExpirationDate(true)}
          disabled={numSelected === 0}
          size="large">
          <CalendarMonthIcon />
        </IconButton></span>
      </Tooltip>
      {AuthService.isManager() &&
        <Tooltip title={t('react.userlist.send.first.connection.mail')}><span>
          <IconButton
            onClick={handleSendFirstConnectionMail}
            disabled={numSelected === 0}
            size="large">
            <ContactMailIcon />
          </IconButton></span>
        </Tooltip>
      }
      <Tooltip title={t('react.button.add')}>
        <IconButton
          onClick={() => props.navigate('/users/add')}
          size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>
      <TripleStateButton
        stateValues={['onlyExpired', 'all', 'onlyActive']}
        stateIcons={[<TbClockPause/>, <TbClock/>,<TbClockPlay/>]}
        stateLabels={[t('react.userlist.filter.state.onlyExpired'), t('react.userlist.filter.state.all'), t('react.userlist.filter.state.onlyActive')]}
        stateValue={stateFilter}
        setStateValue={handleChangeStateFilter}
        style = {{height: '30px'}}
      />
      <Tooltip title={t('react.button.refresh')}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer : {
    cursor: 'pointer',
  },
}));

export default function UsersList(props) {

  const {t, openSnackbar } = props;

  const { classes } = useStyles();

  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('lastname');
  const [sortDirection, setSortDirection] = useState('asc');
  const [keywords, setKeywords] = useState('');
  const [stateFilter, setStateFilter] = useState('all');
  const [openPopupExpirationDate, setOpenPopupExpirationDate] = useState(false);
  
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if(sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc');
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const handleFilterChange = (newValue) => {
    setPage(0);
    setKeywords(newValue);
    setLoadSearchChange(true);
    setLoadData(LoadData.Load);
  };

  const handleChangeStateFilter = (newStatefFlter) => {
    setPage(0);
    setStateFilter(newStatefFlter);
    setLoadSearchChange(true);
    setLoadData(LoadData.Load);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
	const [loadSearchChange, setLoadSearchChange] = useState(false);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    userService.searchUsers(page, rowsPerPage, sortBy, sortDirection, keywords, (stateFilter === 'onlyActive'), (stateFilter === 'onlyExpired'))
    .then(result => {
      setCountForPagination(result.data.total);

      var newRows = [];
      for(var i = 0; i < result.data.hits.length; i++) {
        let hit = result.data.hits[i];
        let customerName = result.data.customers.find(r => r.id === hit.customerId).name;
        newRows.push(createData(hit.id, hit.uid, hit.email, hit.firstname, hit.lastname, hit.customerId, customerName, hit.creationDate, hit.updateDate, hit.expirationDate));
      }
      setRows(newRows);
    })
    .catch(err => {
      setRows([]);
      setCountForPagination(0);
      openSnackbar('error', t("react.userlist.error.while.loading"));
    }).finally(() => {
      setLoadData(LoadData.Loaded);
      setLoadSearchChange(false);
    });
  }, [loadData, page, rowsPerPage, sortBy, sortDirection, loadSearchChange, keywords]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleEditUser = (event, userIdToEdit) => {
    event.preventDefault();
    props.navigate('/users/' + userIdToEdit);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteUsers = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true);
  };

  const handleClickConfirmExpirationDate = (expirationDate) => {
    var promises = [];
    let dateToSend = new Date(expirationDate).getTime();
    for(var i = 0; i < selected.length; i++) {
        promises.push(userService.updateExpirationDate(selected[i], dateToSend));
    }

    Promise.all(promises)
    .then(function(values) {
        setLoadData(LoadData.Load);
        setSelected([]);
        setOpenPopupExpirationDate(false);
    })
    .catch(err => {
        openSnackbar('error', t("react.userlist.error.while.update.expiration.users"));
    });
  };

  const handleSendFirstConnectionMail = (event, row) => {
    event.preventDefault();
    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(userService.sendFirstConnectionMail(selected[i]));
    }

    Promise.all(promises)
    .then(function(values) {
      setSelected([]);
      openSnackbar('success', t("react.userlist.success.while.sending.emails"));
    })
    .catch(err => {
      openSnackbar('error', t("react.userlist.error.while.sending.emails"));
    });
  };

  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(userService.deleteUser(selected[i]));
    }

    Promise.all(promises)
    .then(function(values) {
      setDeleteDialogOpen(false);
      setLoadData(LoadData.Load);
      setSelected([]);
    })
    .catch(err => {
      openSnackbar('error', t("react.userlist.error.while.deleting.users"));
    });
  };

  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.userslist')} />
      { loadData !== LoadData.Loaded && !loadSearchChange ? ( <LinearProgress /> )
      : (
      <Paper className={classes.paper}>
        <PopupTwoButtons
          variant='warning'
          openState={deleteDialogOpen}
          callbackOnclose={handleDeleteDialogCancel}
          callbackOnclickLeftButton={handleDeleteDialogCancel}
          callbackOnclickRightButton={handleDeleteDialogConfirm}
          title={t('react.userlist.confirm.delete.title')}
          content={t('react.userlist.confirm.delete.description')}
          leftButton={t('react.button.cancel')}
          rightButton={t('react.button.delete')}
          unlockCheckboxLabel={t('react.popuptwobuttons.delete.help.for.the.disabled')}
        />
        <ChangeExpirationDateDialog 
          {...props}
          callbackAfterConfirm={handleClickConfirmExpirationDate}
          callbackAfterCancel={() => setOpenPopupExpirationDate(false)}
          openState={openPopupExpirationDate}
        />
        <UsersListToolbar
          {...props}
          selected={selected}
          countForPagination={countForPagination}
          handleDeleteUsers={handleDeleteUsers}
          setOpenPopupExpirationDate={setOpenPopupExpirationDate}
          handleSendFirstConnectionMail={handleSendFirstConnectionMail}
          keywords={keywords}
          handleFilterChange={handleFilterChange}
          setLoadData={setLoadData} 
          stateFilter={stateFilter}
          handleChangeStateFilter={handleChangeStateFilter}
          />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            size="medium"
          >
            <UsersListHead
              {...props}
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleChangeSort={handleChangeSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableRowCursorPointer}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headCells
                        .filter(function(headCell) {
                          if(headCell.managerView === true && !AuthService.isManager()) {
                            return false; // skip
                          } else if(headCell.managerView === false && AuthService.isManager()) {
                            return false; // skip
                          }
                          return true;
                        })
                        .map(column => {
                        let value = row[column.id];
                        // when it is the customer column let the <Link> do the redirect
                        let isCustomerColumn = column.id === 'customerName';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={event => isCustomerColumn ? '' : handleEditUser(event, row.id)} >
                            {column.format ? column.format(props, row) : value}
                          </TableCell>
                        );
                      })}
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={countForPagination}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('react.list.number.lignes.per.page')}
          labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', {'from':from, 'to':to, 'count':count})}
          getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      ) }
    </div>
  );
}
