import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import SurveysService from '../../services/SurveysService';
import BarChart from './Charts/BarChart';
import ScreenOutChart from './Charts/ScreenOutChart';
import OngoingStopsChart from './Charts/OngoingStopsChart';
import SimplePieChart from './Charts/SimplePieChart';
import PieChartHotSpot from './Charts/PieChartHotSpot';
import PieChartItemBattery from './Charts/PieChartItemBattery';
import PieChartQueryParameters from './Charts/PieChartQueryParameters';
import CellsChart from './Charts/CellsChart';
import CellsTable from './CellsTable.js';
import DonutChartQuota from './Charts/DonutChartQuota';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import { LoadData } from '../../Constants.js';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { toolbarStyles } from '../../common.js';
import {Helmet} from "react-helmet";
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Select, AccordionDetails, AccordionSummary, Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewCompactOutlinedIcon from '@mui/icons-material/ViewCompactOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import BuildIcon from "@mui/icons-material/Build";
import { flatAllSurveyBlocks } from '../../utils/surveysUtils.js';
import { isBlank } from '../../utils/utils.js';

const surveysService = new SurveysService();

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        overflow: 'hidden',
    },
    subcontainer: {
        flexGrow: 1,
        flexBasis: '30%',
        /*width:18%;*/
    },
    blueLine: {
        width: '100%',
        height: '1px',
        background: 'rgba(0,57,69,0.5)',
    },
    titleQuestion: {
        textAlign: 'center',
        marginTop: 5,
        fontSize: 20,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
    },
    titleBase: {
        textAlign: 'center',
        marginTop: 0,
        fontSize: 13,
        fontWeight: 400,
        marginBottom: 10
    },
    conditionInfo: {
        marginLeft: 10,
        marginTop: 10,
        fontSize: 15,
        fontWeight: 400,
        fontStyle: 'italic',
        color: 'grey',
        position: 'absolute'
    },
    marginSwitch: {
        margin: 8
    },
    relative: {
        position: 'relative'
    },
    absolute: {
        position: 'absolute'
    },
    relativeMarginTop: {
        position: 'relative',
        marginTop: 15
    },
    reverseFlexAbsolute: {
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'absolute',
        right: 0,
        top: -30
    },
    typeBlockIcon: {
        marginTop: 4, 
        marginRight: 10,
    },
    flexEnd: {
        display: 'flex', 
        justifyContent: 'flex-end'
    },
    iconButtonGiveUp: {
        color: 'rgb(50,50,50)', 
        transform: 'scale(0.8)'
    },
    logoutIcon: {
        position: 'absolute', 
        top: 9
    },
    marginTypoTextGiveUp: {
        marginTop: 10, 
        marginRight: -10
    }
}));

export default function SurveyReporting(props) {

    const { formIdParameter } = useParams();

    const { classes } = useStyles();
    const { classes: toolbarClasses } = toolbarStyles();

    const { t, openSnackbar } = props;

    const [survey, setSurvey] = useState([]);
    const [flattenedBlocks, setFlattenedBlocks] = useState([]);
    const [dashboardDataSurvey, setDashboardDataSurvey] = useState([]);
    const [nbMaxAnswers, setNbMaxAnswers] = useState(0);
    const [completionStates, setCompletionStates] = useState(null);
    const [screenOuts, setScreenOuts] = useState({});
    const [cells, setCells] = useState([]);
    const [ongoingStops, setOngoingStops] = useState([]);
    const [cardToShow, setCardToShow] = useState(0);
    const [queryParams, setQueryParams] = useState([]);
    const [loadData, setLoadData] = useState(LoadData.Load);

    useEffect(() => {
        if (loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        var promises = [
            surveysService.getReporting(formIdParameter),
            surveysService.fetchForm(formIdParameter)
         ];

         Promise.all(promises)
            .then(results => {
                setOngoingStops(results[0].data.ongoingStops);
                setCompletionStates(results[0].data.completionStates);
                setNbMaxAnswers(results[0].data.nbMaxAnswers);
                setScreenOuts(results[0].data.screenOuts);
                setCells(results[0].data.cells);
                setDashboardDataSurvey(results[0].data.collectProgress);
                setQueryParams(results[0].data.queryParameters);
                setFlattenedBlocks(flatAllSurveyBlocks(results[1].data.blocks));
                setSurvey(results[1].data);
            })
            .catch(err => {
                openSnackbar('error', t('react.generic.error.while.loading'));
            })
            .finally(() => {
                setLoadData(LoadData.Loaded);
            });
    }, []);

    const containQuotaOrScreenOut = (index, secondParam) => {
        return dashboardDataSurvey[index].answers.some(a => {
            if (secondParam === 'quota') {
                return a[secondParam] !== 0;
            } else if (secondParam === 'screenout') {
                return a[secondParam] === true;
            }
            return false;
        });
    };

    const handleEditCollector = (event, collectorIdToEdit) => {
        event.preventDefault();
        props.navigate(`/surveys/${collectorIdToEdit}`);
    };

    const findOngoingStopForThisQuestion = (blockId) => {
        const numberToReturn = ongoingStops[blockId];
        return  (<div className={classes.flexEnd}>
                    <Typography className={classes.marginTypoTextGiveUp}>{t("react.reporting.ongoingStops.text")}</Typography>
                    <Tooltip title={ongoingStops[blockId] > 0 ? t("react.reporting.ongoingStops.tooltip.plural", {number: ongoingStops[blockId]}) : t("react.reporting.ongoingStops.tooltip.singular", {number: !ongoingStops[blockId] ? 0 : ongoingStops[blockId]})} className={classes.relative}>
                        <IconButton className={classes.iconButtonGiveUp}>
                            <LogoutIcon className={classes.logoutIcon}/>
                            <span style={{marginLeft: 55}}>{!numberToReturn ? 0 : numberToReturn}</span>
                        </IconButton>
                    </Tooltip>
                </div>);
    };

    return (loadData !== LoadData.Loaded ?
        <LinearProgress /> :
        <div className={classes.relativeMarginTop}>
            <Helmet title={dashboardDataSurvey.name} />
            <Toolbar className={toolbarClasses.root}>
                <Tooltip title={t("react.collectors.list.tooltip.survey")}>
                    <IconButton component="span" size='small' onClick={event => handleEditCollector(event, formIdParameter)}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>
                <Typography className={toolbarClasses.title} variant="h6">
                    {t("react.project.collectforms.reporting.title")}
                </Typography>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card>
                        <BarChart
                            {...props}
                            completionStates={completionStates}
                            nbMaxAnswers={nbMaxAnswers}
                        />
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <div style={{marginTop: -43, display: 'flex', flexDirection: 'row-reverse'}}>
                    <FormControl>
                        <Select
                            value={cardToShow}
                            onChange={(e) => setCardToShow(e.target.value)}
                        >
                            <MenuItem value={0}>{t("react.reporting.menuItem.screenOut")}</MenuItem>
                            <MenuItem value={1}>{t("react.reporting.menuItem.giveUp")}</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                    <Card style={{marginTop: 10}}>
                        {cardToShow === 0 ? 
                        <ScreenOutChart
                            {...props}
                            completionStates={completionStates}
                            nbMaxAnswers={nbMaxAnswers}
                            screenOuts={screenOuts}
                            dashboardDataSurvey={dashboardDataSurvey}
                            survey={survey}
                            flattenedBlocks={flattenedBlocks}
                        /> : 
                        <OngoingStopsChart
                            {...props}
                            completionStates={completionStates}
                            dashboardDataSurvey={dashboardDataSurvey}
                            ongoingStops={ongoingStops}
                            survey={survey}
                            flattenedBlocks={flattenedBlocks}
                        />}
                    </Card>
                </Grid>
                {cells.length > 0 && <Grid item xs={12}>
                    <Card>
                        <CellsChart
                            {...props}
                            cells={cells}
                        />
                    </Card>
                </Grid>}
                <Grid item xs={12} >
                    <Card>
                        {dashboardDataSurvey.map((block, index) => {
                            const questionRef = block.ref + (isBlank(block.iterationRef) ? '' : `_${block.iterationRef}`);
                            const formBlock = flattenedBlocks.find(b => b.id === block.blockId);
                            return (
                            <div key={index} className={classes.relative}>
                                {index !== 0 && <div className={classes.blueLine}></div>}
                                {block.question !== undefined ? 
                                <div >                                
                                    <div className={classes.titleQuestion}>
                                        <div className={classes.relative}>
                                            <QuestionAnswerIcon className={classes.typeBlockIcon}/>
                                        </div>{questionRef}: {block.stimulusName}
                                    </div>
                                    <div className={classes.titleBase}>{block.base} {t("react.project.reporting.conditional.answers")}</div>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <SimplePieChart
                                                    {...props}
                                                        thisQuestion={block}
                                                        index={index}
                                                    />
                                            </Grid>
                                            {containQuotaOrScreenOut(index, 'quota') &&
                                                <Grid item xs={6}>
                                                    <DonutChartQuota
                                                        {...props}
                                                        index={index}
                                                        thisQuestion={block}
                                                        nbMaxAnswers={nbMaxAnswers}
                                                    />
                                                </Grid>
                                            }
                                            {/* {containQuotaOrScreenOut(index, 'screenout') &&
                                                <Grid item xs={6}>
                                                    <DonutChartScreenOut
                                                        {...props}
                                                        index={index}
                                                        thisQuestion={block}
                                                    />
                                                </Grid>
                                            } */}
                                        </Grid> 
                                        {findOngoingStopForThisQuestion(block.blockId)}
                                        {cells.length > 0 && <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <ViewCompactOutlinedIcon/><Typography style={{marginLeft: 15}}>{t('react.reporting.button.show.cells.table')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <CellsTable
                                                    {...props}
                                                    thisQuestion={block}
                                                    cells={cells}
                                                />
                                            </AccordionDetails>
                                        </Accordion>}
                                </div>: formBlock.hotspot !== undefined ? 
                                <div className={classes.relative}>
                                    <div className={classes.titleQuestion}>
                                        <div className={classes.relative}>
                                            <PictureInPictureIcon className={classes.typeBlockIcon}/>
                                        </div>{questionRef}: {block.title}
                                    </div>
                                    <div className={classes.titleBase}>{block.base} {t("react.project.reporting.conditional.answers")}</div>
                                    <Grid container >
                                        {block.zones.map((zone, subindex) => (                               
                                            <Grid item xs={6} key={subindex}>
                                                <PieChartHotSpot
                                                    {...props}
                                                    zone={zone}
                                                    index={index}
                                                    base={block.base}
                                                    formBlock={formBlock}
                                                    iterationRef={block.iterationRef}
                                                />  
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {findOngoingStopForThisQuestion(block.blockId)}
                                    {cells.length > 0 && <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <ViewCompactOutlinedIcon/> <Typography style={{marginLeft: 15}}>{t('react.reporting.button.show.cells.table')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <CellsTable
                                                {...props}
                                                cells={cells}
                                                thisQuestion={block}
                                                index={index}
                                            />
                                        </AccordionDetails>
                                    </Accordion>}
                                </div> : formBlock.battery !== undefined ?
                                <div >
                                    <div className={classes.titleQuestion}>
                                        <div className={classes.relative}>
                                            <CalendarViewMonthIcon className={classes.typeBlockIcon}/>
                                        </div>{questionRef}: {block.title}
                                    </div>
                                    <div className={classes.titleBase}>{block.base} {t("react.project.reporting.conditional.answers")}</div>
                                    <Grid container >
                                        {block.items.map((item, subindex) => (                               
                                            <Grid item xs={6} key={subindex}>
                                                <PieChartItemBattery
                                                    {...props}
                                                    item={item}
                                                    formBlock={formBlock}
                                                    iterationRef={block.iterationRef}
                                                />  
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {findOngoingStopForThisQuestion(block.blockId)}
                                    {cells.length > 0 && <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <ViewCompactOutlinedIcon/> <Typography style={{marginLeft: 15}}>{t('react.reporting.button.show.cells.table')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <CellsTable
                                                {...props}
                                                cells={cells}
                                                thisQuestion={block}
                                                index={index}
                                            />
                                        </AccordionDetails>
                                    </Accordion>}
                                </div> :
                                <div>{"Unsupported Block type" /* When a type of block is not supported for reporting */}</div>
                            }
                            </div>
                        );})}
                    </Card>
                </Grid>
                {queryParams.length > 0 &&
                    <Grid item xs={12}>
                        <Card>
                            <div key={1} className={classes.relative}>
                                <div>
                                    <div className={classes.titleQuestion}>
                                        <div className={classes.relative}>
                                            <BuildIcon className={classes.typeBlockIcon}/>
                                        </div>
                                        {t("react.reporting.queryParameters.title")}
                                    </div>
                                    <Grid container>
                                        {queryParams
                                            .map((param, index) => (
                                                <Grid item xs={6} key={index}>
                                                    <PieChartQueryParameters
                                                        {...props}
                                                        param={param}
                                                        index={index}
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                    {cells.length > 0 && <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <ViewCompactOutlinedIcon/> <Typography style={{marginLeft: 15}}>{t('react.reporting.button.show.cells.table')}</Typography>
                                                </AccordionSummary>
                                        <AccordionDetails>
                                            <CellsTable
                                                {...props}
                                                cells={cells}
                                                thisQuestion={{"queryParameters" : queryParams}}
                                            />
                                        </AccordionDetails>
                                    </Accordion>}
                                </div>
                            </div>
                        </Card>
                    </Grid>
                }
            </Grid>
        </div>
    );
};