import { RestClientService } from './RestClientService';
import { cloneDeep } from "../utils/utils";
import { v4 as uuidv4 } from 'uuid';
require('dotenv').config();

/**
 * Represents a big Condition itself, identified by its name.
 * It is applied to all conditionedUuids (block uuids and/or answer uuids)
 * Rules are in the conditions array.
 * 
 * Look for a sample in file __tests__/SampleOfConditionner.json
 * 
 */
const conditionnerTemplate = {
    // -- managed by the backend ------------------------
    // id: 0,
    // formId: 0,
    // --------------------------------------------------
    uuid: undefined,                    // an uuid to identify the conditionner
    name: '',
    conditionedUuids: [],
    joiner: 'AND',                      // AND or OR
    conditions: []                      // list of 'ConditionsDataBag'
};

/**
 * type = -1 => just an intermediat level
 * type =  0 => 'NOT_SELECTED' with a AND between values (named: 'AllThatMustBeNotSelected')
 * type =  1 => 'SELECTED' with a OR between values (named: 'AtLeastOneSelected')
 * type =  2 => 'SELECTED' with a AND between values (named: 'AllThatMustBeSelected')
 */
const conditionsDataBagTemplate = {
    // -- managed by the backend ------------------------
    // id: 0,
    // conditionnerId: 0,
    // conditionsDataBagId: 0,
    // --------------------------------------------------
    type: -1,                           // help you to identify the type of relation
    parentUuid: undefined,              // help you to identify the Question, Hotspot Zone or Battery Item
    joiner: 'AND',                      // AND or OR
    elements: [],                       // list of 'ConditionElement'
    groups: [],                         // list of 'ConditionsDataBag'
    nbMinMatches: 1,                    // When in OR mode, we can define a min 
    nbMaxMatches: 1,                    // and a max expected matches
};

const conditionElementTemplate = {
    // -- managed by the backend ------------------------
    // id: 0,
    // conditionnerId: 0,
    // conditionsDataBagId: 0,
    // --------------------------------------------------
    itemUuid: undefined,                // help you to identify the answer
    expectedState: undefined,           // SELECTED or NOT_SELECTED or VALUE
};

class ConditionService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async fetchConditionners(formId) {
        return this.getRestClient().get(`/api/surveys/v1.0/${formId}/conditionners`);
    }
  
    async saveConditionners(formId, conditionners) {
        return this.getRestClient().post(`/api/surveys/v1.0/${formId}/conditionners`, conditionners);
    }
  
    createConditionner() {
        return cloneDeep(conditionnerTemplate, {uuid: uuidv4()});
    }

    // -- Profile questions functions --------------------------------------------------------------------------

    addQuestionAnswerWithType(conditionner, blockUuid, answerUuid, type) {
        if(type === 0) {
            this.addToAllThatMustBeNotSelected(conditionner, blockUuid, answerUuid);
        } else if(type === 1) {
            this.addToAtLeastOneMustBeSelected(conditionner, blockUuid, answerUuid);
        } else if(type === 2) {
            this.addToAllThatMustBeSelected(conditionner, blockUuid, answerUuid);
        }
    }

    removeQuestionAnswer(conditionner, blockUuid, answerUuid) {
        this.removeFromAllThatMustBeNotSelected(conditionner, blockUuid, answerUuid);
        this.removeFromAtLeastOneMustBeSelected(conditionner, blockUuid, answerUuid);
        this.removeFromAllThatMustBeSelected(conditionner, blockUuid, answerUuid);
    }

    isQuestionAnswerInConditionnerWithType(conditionner, blockUuid, answerUuid, type) {
        return conditionner.conditions
            .filter(c => c.parentUuid === blockUuid)
            .map(c => c.groups)
            .flat()
            .filter(g => g.type === type && g.elements.findIndex(e => e.itemUuid === answerUuid) >= 0)
            .length > 0;
    }

    // -- Hotspots functions --------------------------------------------------------------------------

    addHotspotZoneAnswerWithType(conditionner, zoneUuid, answerUuid, type) {
        if(type === 0) {
            this.addToAllThatMustBeNotSelected(conditionner, zoneUuid, answerUuid);
        } else if(type === 1) {
            this.addToAtLeastOneMustBeSelected(conditionner, zoneUuid, answerUuid);
        } else if(type === 2) {
            this.addToAllThatMustBeSelected(conditionner, zoneUuid, answerUuid);
        }
    }

    removeHotspotZoneAnswer(conditionner, zoneUuid, answerUuid) {
        this.removeFromAllThatMustBeNotSelected(conditionner, zoneUuid, answerUuid);
        this.removeFromAtLeastOneMustBeSelected(conditionner, zoneUuid, answerUuid);
        this.removeFromAllThatMustBeSelected(conditionner, zoneUuid, answerUuid);
    }

    isHotspotZoneAnswerInConditionnerWithType(conditionner, zoneUuid, answerUuid, type) {
        return conditionner.conditions
            .filter(c => c.parentUuid === zoneUuid)
            .map(c => c.groups)
            .flat()
            .filter(g => g.type === type && g.elements.findIndex(e => e.itemUuid === answerUuid) >= 0)
            .length > 0;
    }

    // -- Batteries functions --------------------------------------------------------------------------

    addBatteryItemAnswerWithType(conditionner, itemUuid, answerUuid, type) {
        if(type === 0) {
            this.addToAllThatMustBeNotSelected(conditionner, itemUuid, answerUuid);
        } else if(type === 1) {
            this.addToAtLeastOneMustBeSelected(conditionner, itemUuid, answerUuid);
        } else if(type === 2) {
            this.addToAllThatMustBeSelected(conditionner, itemUuid, answerUuid);
        }
    }

    removeBatteryItemAnswer(conditionner, itemUuid, answerUuid) {
        this.removeFromAllThatMustBeNotSelected(conditionner, itemUuid, answerUuid);
        this.removeFromAtLeastOneMustBeSelected(conditionner, itemUuid, answerUuid);
        this.removeFromAllThatMustBeSelected(conditionner, itemUuid, answerUuid);
    }

    isBatteryItemAnswerInConditionnerWithType(conditionner, itemUuid, answerUuid, type) {
        return conditionner.conditions
            .filter(c => c.parentUuid === itemUuid)
            .map(c => c.groups)
            .flat()
            .filter(g => g.type === type && g.elements.findIndex(e => e.itemUuid === answerUuid) >= 0)
            .length > 0;
    }

    // -- Generic functions --------------------------------------------------------------------------

    getConcernedParentUuids(conditionner) {
        return conditionner.conditions
            .map(c => c.parentUuid);
    }

    /**
     * Returns true if the given uuid belongs to the block itself or if it belongs to one of its sub-elements (only Hotspot Zones or Battery Items)
     * 
     * @param {*} block 
     * @param {*} uuid 
     * @returns 
     */
    isConditionableElementOfBlock(block, uuid) {
        if(block.uuid === uuid) return true;
        else if(block.type === 'question') {
            return block.configuration.question.answers.findIndex(a => a.uuid === uuid) >= 0;
        } else if(block.type === 'hotspot') {
            return block.configuration.hotspot.zones.findIndex(z => z.uuid === uuid) >= 0 || 
                block.configuration.hotspot.answers.findIndex(a => a.uuid === uuid) >= 0;
        } else if(block.type === 'battery') {
            return block.configuration.battery.items.findIndex(i => i.uuid === uuid) >= 0 ||
                block.configuration.battery.answers.findIndex(a => a.uuid === uuid) >= 0;
        }
        return false;
    }

    removeObsoleteEntriesFromConditionners(conditionners, blocks) {
        // remove element when question or answer doesn't exist anymore
        for (let conditionner of conditionners) {
            // keep only conditionedUuids that represent an existing block or a conditionable element (answer, zone or item)
            conditionner.conditionedUuids = conditionner.conditionedUuids
                .filter(conditionedUuid => blocks.findIndex(b => this.isConditionableElementOfBlock(b, conditionedUuid)) >= 0);

            // keep only rules that belong to existing blocks
            conditionner.conditions = conditionner.conditions
                .filter(cdtn => blocks.findIndex(b => this.isConditionableElementOfBlock(b, cdtn.parentUuid)) >= 0);

            for(let level1 of conditionner.conditions) {
                // get the related block
                const relatedQuestionBlockIdx = blocks.findIndex(b => this.isConditionableElementOfBlock(b, level1.parentUuid));

                // we have to verify that conditionedUuids are AFTER the related QUESTION (if the block is moved for example)
                conditionner.conditionedUuids = conditionner.conditionedUuids
                    .filter(uuid => blocks.findIndex(b => this.isConditionableElementOfBlock(b, uuid)) > relatedQuestionBlockIdx);

                const relatedBlock = blocks[relatedQuestionBlockIdx];
                for(let group of level1.groups) {
                    // this will filter only valid answerUuids
                    if(relatedBlock.type === 'question') {
                        group.elements = group.elements
                            .filter(element => relatedBlock.configuration.question.answers.findIndex(a => a.uuid === element.itemUuid) >= 0);
                    } else if (relatedBlock.type === 'hotspot') {
                        // the Zone AND the Value must exist
                        group.elements = group.elements
                            .filter(element => relatedBlock.configuration.hotspot.answers.findIndex(a => a.uuid === element.itemUuid) >= 0);
                    } else if (relatedBlock.type === 'battery') {
                        // the Item AND the Value must exist
                        group.elements = group.elements
                            .filter(element => relatedBlock.configuration.battery.answers.findIndex(a => a.uuid === element.itemUuid) >= 0);
                    }
                }

                // keep groups having one or more elements
                level1.groups = level1.groups
                    .filter(g => g.elements.length > 0);
            }

            // keep conditions having one or more groups
            conditionner.conditions = conditionner.conditions
                .filter(c => c.groups.length > 0);
        }

        // keep conditions having one or more rules or conditionnedUuids
        conditionners = conditionners.filter(it => it.conditions.length > 0);
        conditionners = conditionners.filter(it => it.conditionedUuids.length > 0);

        return conditionners;
    }

    setMinOrMaxMatchesFieldValue(conditionner, parentUuid, minOrMaxField, value) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let atLeastOneMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 1);

            if(atLeastOneMustBeSelectedIdx >= 0) {
                conditionner
                    .conditions[groupOfConditionsAboutThisQuestionIdx]
                    .groups[atLeastOneMustBeSelectedIdx][minOrMaxField] = value;
            }
        }
    }

    // -- Methods below should not be used ouside of this class

    addToAllThatMustBeNotSelected(conditionner, parentUuid, itemUuid) {
        // we can have both with values
        this.removeAllFromAtLeastOneMustBeSelected(conditionner, parentUuid);
        this.removeFromAllThatMustBeSelected(conditionner, parentUuid, itemUuid);

        const element = cloneDeep(conditionElementTemplate, {itemUuid: itemUuid, expectedState: 'NOT_SELECTED'});

        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx === -1) {
            let groupOfConditionsAboutThisQuestion = cloneDeep(conditionsDataBagTemplate, {parentUuid: parentUuid, joiner: 'AND'});
            conditionner.conditions.push(groupOfConditionsAboutThisQuestion);
            groupOfConditionsAboutThisQuestionIdx = conditionner.conditions.length - 1;
        }

        // search the group having the good type
        let allThatMustBeNotSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
            .findIndex(g => g.type === 0);

        if(allThatMustBeNotSelectedIdx === -1) {
            let allThatMustBeNotSelectedArray = cloneDeep(conditionsDataBagTemplate, {type: 0, joiner: 'AND'});
            conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.push(allThatMustBeNotSelectedArray);
            allThatMustBeNotSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.length - 1;
        }

        conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[allThatMustBeNotSelectedIdx].elements.push(element);
    }

    removeFromAllThatMustBeNotSelected(conditionner, parentUuid, itemUuid) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let allThatMustBeNotSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 0);
        
            if(allThatMustBeNotSelectedIdx >= 0) {
                let idxOfElement = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[allThatMustBeNotSelectedIdx].elements
                    .findIndex(e => e.itemUuid === itemUuid);

                if(idxOfElement >= 0) {
                    conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[allThatMustBeNotSelectedIdx].elements.splice(idxOfElement, 1);
                }
            }
        }
    }

    removeAllFromAllThatMustBeNotSelected(conditionner, parentUuid) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let allThatMustBeNotSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 0);
        
            if(allThatMustBeNotSelectedIdx >= 0) {
                conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.splice(allThatMustBeNotSelectedIdx, 1);
            }
        }
    }

    addToAtLeastOneMustBeSelected(conditionner, parentUuid, itemUuid) {
        // we can have both with values
        this.removeAllFromAllThatMustBeNotSelected(conditionner, parentUuid);
        this.removeFromAllThatMustBeSelected(conditionner, parentUuid, itemUuid);

        const element = cloneDeep(conditionElementTemplate, {itemUuid: itemUuid, expectedState: 'SELECTED'});

        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx === -1) {
            let groupOfConditionsAboutThisQuestion = cloneDeep(conditionsDataBagTemplate, {parentUuid: parentUuid, joiner: 'AND'});
            conditionner.conditions.push(groupOfConditionsAboutThisQuestion);
            groupOfConditionsAboutThisQuestionIdx = conditionner.conditions.length - 1;
        }

        // search the group having the good type
        let atLeastOneSelectedArrayIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
            .findIndex(g => g.type === 1);

        if(atLeastOneSelectedArrayIdx === -1) {
            let atLeastOneSelectedArray = cloneDeep(conditionsDataBagTemplate, {type: 1, joiner: 'OR'});
            conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.push(atLeastOneSelectedArray);
            atLeastOneSelectedArrayIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.length - 1;
        }

        conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[atLeastOneSelectedArrayIdx].elements.push(element);
    }

    removeFromAtLeastOneMustBeSelected(conditionner, parentUuid, itemUuid) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let atLeastOneMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 1);
        
            if(atLeastOneMustBeSelectedIdx >= 0) {
                let idxOfElement = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[atLeastOneMustBeSelectedIdx].elements
                    .findIndex(e => e.itemUuid === itemUuid);

                if(idxOfElement >= 0) {
                    conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[atLeastOneMustBeSelectedIdx].elements.splice(idxOfElement, 1);
                }
            }
        }
    }

    removeAllFromAtLeastOneMustBeSelected(conditionner, parentUuid) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let atLeastOneMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 1);
        
            if(atLeastOneMustBeSelectedIdx >= 0) {
                conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.splice(atLeastOneMustBeSelectedIdx, 1);
            }
        }
    }

    addToAllThatMustBeSelected(conditionner, parentUuid, itemUuid) {
        this.removeFromAllThatMustBeNotSelected(conditionner, parentUuid, itemUuid);
        this.removeFromAtLeastOneMustBeSelected(conditionner, parentUuid, itemUuid);

        const element = cloneDeep(conditionElementTemplate, {itemUuid: itemUuid, expectedState: 'SELECTED'});

        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx === -1) {
            let groupOfConditionsAboutThisQuestion = cloneDeep(conditionsDataBagTemplate, {parentUuid: parentUuid, joiner: 'AND'});
            conditionner.conditions.push(groupOfConditionsAboutThisQuestion);
            groupOfConditionsAboutThisQuestionIdx = conditionner.conditions.length - 1;
        }

        // search the group having the good type
        let allThatMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
            .findIndex(g => g.type === 2);

        if(allThatMustBeSelectedIdx === -1) {
            let allThatMustBeSelectedArray = cloneDeep(conditionsDataBagTemplate, {type: 2, joiner: 'AND'});
            conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.push(allThatMustBeSelectedArray);
            allThatMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.length - 1;
        }

        conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[allThatMustBeSelectedIdx].elements.push(element);
    }

    removeFromAllThatMustBeSelected(conditionner, parentUuid, itemUuid) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let allThatMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 2);
        
            if(allThatMustBeSelectedIdx >= 0) {
                let idxOfElement = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[allThatMustBeSelectedIdx].elements
                    .findIndex(e => e.itemUuid === itemUuid);

                if(idxOfElement >= 0) {
                    conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups[allThatMustBeSelectedIdx].elements.splice(idxOfElement, 1);
                }
            }
        }
    }

    removeAllFromAllThatMustBeSelected(conditionner, parentUuid) {
        // search in rules the batch in relation with the parentUuid
        let groupOfConditionsAboutThisQuestionIdx = conditionner.conditions
            .findIndex(c => c.type === -1 && c.parentUuid === parentUuid);

        if(groupOfConditionsAboutThisQuestionIdx >= 0) {
            // search the group having the good type
            let allThatMustBeSelectedIdx = conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups
                .findIndex(g => g.type === 2);
        
            if(allThatMustBeSelectedIdx >= 0) {
                conditionner.conditions[groupOfConditionsAboutThisQuestionIdx].groups.splice(allThatMustBeSelectedIdx, 1);
            }
        }
    }

    // -- cloning functions --------------------------------------------------------------------------

    cloneConditionnerAsNew(conditionner, uuidsMapping) {
        var clone = cloneDeep(conditionner);

        clone.id = 0;
        clone.uuid = uuidv4();

        // replace all uuids with conditionedUuids if applicable
        clone.conditionedUuids = clone.conditionedUuids
            .map(uuid => {
                let mapping = uuidsMapping.get(uuid);
                return mapping ? mapping : uuid;
            });

        clone.conditions.forEach(databag => this.resetDataBagIdentifiers(databag, uuidsMapping));
        return clone;
    }

    resetDataBagIdentifiers(databag, uuidsMapping) {
        databag.id = 0;
        databag.conditionnerId = 0;
        databag.conditionsDataBagId = 0;

        let mapping = uuidsMapping.get(databag.parentUuid);
        databag.parentUuid = mapping ? mapping : databag.parentUuid;

        databag.elements.forEach(el => this.resetElementIdentifiers(el, uuidsMapping));
        databag.groups.forEach(subDatabag => this.resetDataBagIdentifiers(subDatabag, uuidsMapping));
    }

    resetElementIdentifiers(element, uuidsMapping) {
        element.id = 0;
        element.conditionnerId = 0;
        element.conditionsDataBagId = 0;

        let mapping = uuidsMapping.get(element.itemUuid);
        element.itemUuid = mapping ? mapping : element.itemUuid;
    }
}

export default ConditionService;
