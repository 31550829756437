import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CompactPicker} from "react-color";
import {ClickAwayListener} from "@mui/base/ClickAwayListener";
import ColorizeIcon from '@mui/icons-material/Colorize';
import Button from "@mui/material/Button";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
	container: {
		position: "relative",
		width: "fit-content",
	},
	label: {
		display: "flex",
		alignItems: "center",
		width: 130,
		borderRadius: "6px",
		padding: "4px 4px",
		gap: 5,
		border: "1px solid #CCC",
		cursor: "pointer",
	},
	colorBox: {
		width: "24px",
		height: "24px",
		borderRadius: "4px",
	},
	picker: {
		position: "absolute",
		top: "40px",
		zIndex: 10,
		background: "#FFF",
		boxShadow: "0 0 8px rgba(0,0,0,0.2)",
		borderRadius: "6px",
		padding: "8px",
		display: "flex",
		flexDirection: "column",
		gap: 10,
	},
	icon: {
		width: "20px",
		height: "20px",
	}
}));

export default function ColorSelector(props) {
	const { classes } = useStyles();
	const { color = "#013945", onChange, openSnackbar, t } = props;
	const [showPicker, setShowPicker] = useState(false);

	const handlePickColor = async () => {
		if (window.EyeDropper) {
			try {
				const eyeDropper = new window.EyeDropper();
				const result = await eyeDropper.open();
				onChange(result.sRGBHex);
			} catch (error) {
				console.error(t("react.surveys.theme.editor.color.picker.not.supported"), error);
			}
		} else {
			openSnackbar("error", t("react.surveys.theme.editor.color.picker.not.supported"));
		}
	};

	const handleColorChange = (color) => {
		onChange(color.hex);
	};

	return (
		<ClickAwayListener onClickAway={() => setShowPicker(false)}>
			<Box className={classes.container}>
				<Box
					component="label"
					onClick={() => setShowPicker(!showPicker)}
					className={classes.label}
				>
					<Box sx={{backgroundColor: color}} className={classes.colorBox}/>
					<Typography>{color.toUpperCase()}</Typography>
				</Box>

				{showPicker && (
					<Box className={classes.picker}>
						<CompactPicker color={color} onChangeComplete={handleColorChange}/>
						<Button onClick={handlePickColor} size={"small"}>
							<ColorizeIcon className={classes.icon}/>
							<span>
								{t("react.surveys.theme.editor.color.picker")}
							</span>
						</Button>
					</Box>
				)}
			</Box>
		</ClickAwayListener>
	);
}
