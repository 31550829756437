import { isBlank, pushAll } from "./utils";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import Looks3Icon from '@mui/icons-material/Looks3';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MessageIcon from '@mui/icons-material/Message';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import ISO6391 from 'iso-639-1';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import Badge from "@mui/material/Badge";
import { LuReplaceAll } from "react-icons/lu";
import {createTheme} from "@mui/material/styles";

  export const validateInputControlThreeWordQuestion = (firstA, secondA, thirdA) => {
      const trimFirstA = firstA.trim();
      const trimSecondA = secondA.trim();
      const trimThirdA = thirdA.trim();

      function enteredTextIsValid(userInput) {
          const words = userInput.split(' ');

          // Function to check if a word contains three consecutive same letters
          function threeSameLetters(w) {
              for (let i = 0; i < w.length - 2; i++) {
                  if (w[i] === w[i + 1] && w[i] === w[i + 2]) {
                      return true;
                  }
              }
              return false;
          }

          // Function to check if a word contains repeated special characters
          function hasRepeatedSpecialCharacters(w) {
            const specialCharsPattern = /([!?:,.&/§+-])\1+/g;
            return specialCharsPattern.test(w);
          }

          // Function to check if a word contains only 2 different characters and has more than 6 characters
          function hasTwoDifferentCharacters(w) {
            const stringWithoutSpaces = w.replace(/\s/g, '');
            const uniqueChars = [...new Set(stringWithoutSpaces)]; // Convert the word to an array of unique characters
            return uniqueChars.length < 3  && stringWithoutSpaces.length > 6;
          }

          // Check conditions for entered text validity
          return (
            words.length < 5 // Less than 5 words
            && userInput.length < 35 // Less than 35 characters
            && !words.some(threeSameLetters) // No three consecutive same letters
            && userInput.length > 2 // At least 3 characters per input
            && !hasRepeatedSpecialCharacters(userInput) // No repeated special characters
            && !hasTwoDifferentCharacters(userInput) // No input with only 2 different characters and more than 6 characters
          );
      }

      if (
          trimFirstA === trimSecondA ||
          trimFirstA === trimThirdA ||
          trimSecondA === trimThirdA ||
          !enteredTextIsValid(trimFirstA) ||
          !enteredTextIsValid(trimSecondA) ||
          !enteredTextIsValid(trimThirdA)
      ) {
          return false;
      }

      return true;
  };

  export const extractMinMaxOfInterval = (valueParam) => {
      if(isBlank(valueParam)) return null;
    
      const regex = /\d+/g; // Find all occurrences of numbers in string
      const matches = valueParam.match(regex); // Find the numbers in the string
    
      if (matches && matches.length === 2) {
          return [parseInt(matches[0]), parseInt(matches[1])];
      } else {
          return null; // If less or more than 2 numbers found, return null
      }
  };

  // this will extract number between parentheses or brackets: e.g const texte = "Normandie (27, 51, 76)" or "Ile de France [75, 78, 92]";
  export const extractListOfNumerics = (valueParam) => {
    if(isBlank(valueParam)) return null;

    // try to find between parentheses
    var matches = valueParam.match(/\((.*?)\)/);
    // try to find between brackets
    if(!matches) matches = valueParam.match(/\[(.*?)\]/);

    if (matches) {
      const results = isBlank(matches[1]) ? [] : matches[1].split(',').map(v => v.trim());
  
      // if some of the values AREN'T numeric return null
      if(results.length === 0 || results.filter(s => isNaN(s)).length > 0) return null;
  
      // convert to list of numbers and return
      return results.map(n => parseInt(n));
    }
  
    return null;
  };

  export const calculateWidthCol = (nbColumnsCata) => {
    return {
      width: nbColumnsCata === 1 && window.screen.width > 799 ? '50%' : nbColumnsCata === 2 ? '60%' : nbColumnsCata === 3 ? '70%' : '100%', marginLeft: nbColumnsCata === 1 && window.screen.width > 799 ? '25%' : nbColumnsCata === 2 ? '20%' : nbColumnsCata === 3 ? '15%' : '0px'
    };
  };

  export const isBlankHtml = (string) => {
    return isBlank(string) || isBlank(string.toString().replace(/<[^>]*>/g, ''));
  };

  export const modulesReactQuill = {
    toolbar: [
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike'],
      [
          {
          color: ["#000000de", "#FF0000", "#00FF00", "#0000FF", "#00FFFF", "#FF00FF", "#808080", "#C0C0C0", "#87CEEB", "#32CD32", "#FFA500", "#FFC0CB", "#800080", "#A52A2A", "#40E0D0", "#FFDB58", "#000080"],
          },
      ],
      ['link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ],
      clipboard: {
      matchVisual: false
    }
  };

  /**
   * Compute next default ref like "QXX", "ZXX", "IXX" or "AXX" where XX is a number.
   * 
   * Pass an array of elements having a 'ref' field.
   * 
   * ['Q1', 'WHATEVER', 'Q2', 'XZY'] will return 'Q3'
   * 
   */
  export const computeNextElementRef = (elements, withPrefix) => {
    let mustMatch = new RegExp(`^${withPrefix}[0-9]{1,3}$`, "g");
    var allRefIdx = elements
      .filter(it => !isBlank(it.ref) && it.ref.match(mustMatch))
      .map(it => parseInt(it.ref.substring(1)));

    // compute next index
    var nextRefIdx = allRefIdx.length > 0 ? (Math.max(...allRefIdx) + 1) : 1;

    return `${withPrefix}${nextRefIdx}`;
  };

  /**
   * Profile question:
   * [Q1] => returns all answers separated by a comma ("Answer 1, Answer 2, Answer 3")
   * [Q1.A2] => returns only the second answer (e.g "Answer 2"), in single mode it's possible to write [Q1.A1]
   * [Q1.TEXT] => returns the text of the question
   * 
   * Battery of items:
   * [Q2.I1] => returns all answers separated by a comma for an item ("Answer 1, Answer 2, Answer 3")
   * [Q2.I1.A2] => returns only the second answer (e.g "Answer 2") to an item, in single mode it's possible to write [Q2.I1.A1]
   * [Q2.TEXT] => returns the text of the main question of the battery
   * [Q2.I1.TEXT] => returns the text of an item
   * 
   * Hotspot:
   * [Q3.Z1] => returns the answer for a zone (hotspot can only have 1 answer)
   * [Q3.Z1.A1] => it's possible to write this way to have the answer
   * [Q3.TEXT] => returns the text of the main question of the hotspot
   * [Q3.Z1.TEXT] => returns the text (name) of a zone
   * 
   */
  export const replaceDynamicElements = (sentence = '', userHistory = [], globalVariables = [], blocksToDisplay = [], actualBlock = -1, queryParameters = {}) => {
    return sentence.replace(/\[([^\[\]]+)\]/g, (match /* = [Q1.Z2] */, element /* = Q1.Z2 */) => {
        // "Q1.I2.A3" => ["Q1", "I2", "A3"] or "VAR_1" => ["VAR_1"]
        const parts = element.toUpperCase().split('.');

        // check if it's a variable (global or in the current block)
        if(parts.some(p => p.startsWith('VAR_'))) {
          const expectedCode = element.toUpperCase();

          // check if it's a variable of a loop (contained in the current block)
          if(actualBlock >= 0 && blocksToDisplay[actualBlock]  && blocksToDisplay[actualBlock].context && blocksToDisplay[actualBlock].context.variables) {
            let correspondingContextVariable = blocksToDisplay[actualBlock].context.variables.find(v => v.code === expectedCode);
            if (correspondingContextVariable) {
              // a LOOP var can refer to a [VAR_XXX] we have to do a recursive call by security
              return replaceDynamicElements((correspondingContextVariable.value || ''), userHistory, globalVariables, blocksToDisplay, actualBlock, queryParameters);
            }
          }

          // check if it's a global variable
          let correspondingGlobalVariable = globalVariables.find(v => v.code === expectedCode);
          if (correspondingGlobalVariable) {
            return (correspondingGlobalVariable.value || '');
          }

          return match;
        }

        // check if it's a param
        if(parts[0].startsWith('PARAM_')) {
          if(queryParameters) {
            // e.g. PARAM_LANG
            let requestedParam = parts[0].substring(6);
            let entries = Object.entries(queryParameters);
            let paramIdx = entries.findIndex(it => it[0].toUpperCase() === requestedParam);
            if(paramIdx >= 0) return (entries[paramIdx][1] || '');
          }

          return match;
        }

        // we are looking for the TEXT of a Question, an answer, an Item or a Zone
        if(parts.some(p => p === "TEXT")) {
          let text = undefined;

          let questionRef = parts[0];
          let block = blocksToDisplay.find(b => b.ref === questionRef);

          if(block) {
            if(block.type === 'battery') {
              text = block.question;
              let itemRef = parts.find(p => p.startsWith('I'));
              let answerRef = parts.find(p => p.startsWith('A'));
              if(itemRef) {
                let correspondingItem = block.battery.items.find(it => it.ref === itemRef);
                if(correspondingItem) text = correspondingItem.text;
              } else if(answerRef) {
                let answer = block.battery.answers.find(a => a.ref === answerRef);
                if(answer) text = answer.text;
              }
            } else if(block.type === 'hotspot') {
              text = block.question;
              let zoneRef = parts.find(p => p.startsWith('Z'));
              let answerRef = parts.find(p => p.startsWith('A'));
              if(zoneRef) {
                let correspondingZone = block.hotspot.zones.find(it => it.ref === zoneRef);
                if(correspondingZone) text = correspondingZone.name;
              } else if(answerRef) {
                let answer = block.hotspot.answers.find(a => a.ref === answerRef);
                if(answer) text = answer.name;
              }
            } else if(block.type === 'question') {
              text = block.profile.question;
              let answerRef = parts.find(p => p.startsWith('A'));
              if(answerRef) {
                let answer = block.profile.answers.find(a => a.ref === answerRef);
                if(answer) text = answer.answer;
              }
            }
          }

          return text 
            ? replaceDynamicElements((text || ''), userHistory, globalVariables, blocksToDisplay, actualBlock, queryParameters)
            : match;
        }

        // search for the question only (eg. Q1.Z1) - rebuild objectRef with eligible parts
        // take everything EXCEPT answers 'AXX'
        let answerMatch = new RegExp(`^A[0-9]{1,3}$`, "g");
        let objectRef = parts.filter(p => !p.match(answerMatch)).join('.');
        let correspondingHistory = userHistory
          .map(uh => uh.elements)
          .flat()
          .find(a => a.objectRef === objectRef);

        if(correspondingHistory) {
          if(Array.isArray(correspondingHistory.answers) && correspondingHistory.answers.length > 0) {
            // if we are looking for a specific answer index: eg. "Q1.I2.A3" (eg. case of multiple)
            let answerRef = parts.find(p => p.match(answerMatch));
            if(answerRef /* "A3" */) {
              let result = match;

              // IMPORTANT NOTE: at this point the given ref is looking for AN INDEX OF THE POSITION in user answers
              // e.g: "A3" means the third answer of the user AND NOT THE "A3" answer by ref !

              let indexOfTheExpectedAnswer = parseInt(answerRef.substring(1)) - 1; // "A3" -> 3, the third one, so index is 2

              let answer = indexOfTheExpectedAnswer >= 0 && indexOfTheExpectedAnswer < correspondingHistory.answers.length
                ? correspondingHistory.answers[indexOfTheExpectedAnswer]
                : undefined;

              if(answer) {
                // R3MSCORE-845: as an answer can refer to a [VAR_XXX] we have to do a recursive call by security
                result = replaceDynamicElements(((answer.freeField ? correspondingHistory.enteredValue : answer.text) || ''), userHistory, globalVariables, blocksToDisplay, actualBlock, queryParameters);
              }

              return result;
            }

            // by default, returns values comma separated
            return correspondingHistory.answers
              // R3MSCORE-845: as an answer can refer to a [VAR_XXX] we have to do a recursive call by security
              .map(oneAnswer => replaceDynamicElements(((oneAnswer.freeField ? correspondingHistory.enteredValue : oneAnswer.text) || ''), userHistory, globalVariables, blocksToDisplay, actualBlock, queryParameters))
              .join(', ');
          }
        }

        // by default, return the match (eg. [Q1.Z2])
        return match;
      });
  };

  export const formatsReactQuill = ['bold', 'italic', 'underline', 'color', 'size', 'strike', 'list', 'indent', 'link'];

  export const IMAGE_WIDTH_AUTO = -1;
  export const IMAGE_WIDTH_100_PERCENT = -2;

  export const getBlockIcon = (blockConfiguration, badgeContent = null) => {
    if (!blockConfiguration || !blockConfiguration.type) return null;

    let icon = null;
    let type = blockConfiguration.type;

    if(!blockConfiguration.active) {
      icon = <MicOffOutlinedIcon />
    } else if (type === 'introduction') {
      icon = <PlayCircleFilledOutlinedIcon />;
    } else if (type === 'thankyou') {
      icon = <EmojiPeopleIcon />;
    } else if (type === 'experience') {
      icon = <Looks3Icon />;
    } else if (type === 'openQuestion') {
      icon = <MessageIcon />;
    } else if (type === 'question') {
      icon = <QuestionAnswerIcon/>;
      let questionType = blockConfiguration.question.type;
      if (badgeContent === null && (questionType === "multiple" || questionType === "automatic")) {
          badgeContent = questionType.charAt(0).toUpperCase();
      }
    } else if (type === 'text') {
      icon = <TextsmsIcon />;
    } else if (type === 'hotspot') {
      icon = <PictureInPictureIcon />;
    } else if (type === 'battery') {
      icon = <CalendarViewMonthIcon />;
    } else if (type === 'group') {
      icon = <ViewAgendaIcon />;
      let groupType = blockConfiguration.group.type;
      if (badgeContent === null) {
          badgeContent = groupType === 'cellAssigner' ? 'CA' : groupType.charAt(0).toUpperCase();
      }
    } else if (type === 'pairing') {
      icon = <LuReplaceAll />;
    }

    if (icon != null && badgeContent !== null) {
      return <Badge
        badgeContent={badgeContent}
        overlap="circular"
        sx={{ "& .MuiBadge-badge": { fontSize: 8, minHeight: 10, minWidth: 10, height: 13, width: 13, backgroundColor: '#eee', color: "#00000089" }}}
        anchorOrigin={{vertical: 'top', horizontal: 'right' }}
      >
        {icon}
      </Badge>
    }

    return icon;
  };

  export const localeNamesAndCodes = ISO6391.getAllCodes()
    .map(code => ({
          name: ISO6391.getName(code),
          nativeName: ISO6391.getNativeName(code),
          code: code,
      }))
      .sort((e1, e2) => e1.nativeName.localeCompare(e2.nativeName));

  export const renderLocaleNames = (code) => {
      return `${ISO6391.getNativeName(code)} (${ISO6391.getName(code)})`;
  };

  export const addBlockInListAccordingToSelectedElement = (selectedElement, blocks, newBlock) => {
    // special behaviors
    if (newBlock.type === "introduction") {
      blocks.splice(0, 0, newBlock);
      return true;
    } else if (newBlock.type === "thankyou") {
      blocks.push(newBlock);
      return true;
    }

    if(selectedElement === undefined /* when no block is selected */) {
      if (blocks.length > 0 && blocks[blocks.length - 1].type === 'thankyou') {
        // place before the thankyou if any
        blocks.splice(blocks.length - 1, 0, newBlock);
      } else {
        // place at the end
        blocks.push(newBlock);
      }
      return true;
    } 

    if (selectedElement.type === 'thankyou') {
      // place before the thankyou
      blocks.splice(blocks.length - 1, 0, newBlock);
      return true;
    }

    // at this point we have to find the selectedElement
    for (let i = 0; i < blocks.length; i++) {
      const b = blocks[i];

      if (b.uuid === selectedElement.uuid) {
        if (b.type === "group") {
          b.configuration.group.blocks.push(newBlock);
        } else {
          blocks.splice(i + 1, 0, newBlock);
        }
        return true;
      }

      // group behavior
      if (b.type === "group") {
        const groupBlocks = b.configuration.group.blocks;
        let added = addBlockInListAccordingToSelectedElement(selectedElement, groupBlocks, newBlock);
        if(added) return true;
      }
    }
  };

  export const findElementInBlocksForm = (element, blocks, parentList = null, indexInParentGroup = null, allBlocksUntil = []) => {
    for (let i = 0; i < blocks.length; i++) {
      const b = blocks[i];

      if (b.uuid === element.uuid) {
        return {block : b, 
          arrayOfAdjacentsBlocks: blocks, 
          index: i, 
          parentBlock: parentList !== null && indexInParentGroup !== null ? parentList[indexInParentGroup] : null, 
          indexInParentGroup: indexInParentGroup, 
          parentList : parentList,
          allBlocksUntil: [...allBlocksUntil]
        }
      }
      allBlocksUntil.push(b);
      if (b.type === "group") {
        const groupBlocks = b.configuration.group.blocks;
        const result = findElementInBlocksForm(element, groupBlocks, blocks, i, allBlocksUntil);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  export const moveUpwardElementInBlocksForm = (selectedElement, blocksForm, upperBlock) => {
    for (let i = 0; i < blocksForm.length; i++) {
      const b = blocksForm[i];
      if (b.type === "group") {
        const groupBlocks = b.configuration.group.blocks;
        moveUpwardElementInBlocksForm(selectedElement, groupBlocks, upperBlock);
      }
      if (b.uuid === upperBlock.uuid) {
        blocksForm.splice(i, 0, selectedElement);
        return;
      }
    }
  };

  export const moveDownwardElementInBlocksForm = (selectedElement, blocksForm, underBlock) => {
    for (let i = 0; i < blocksForm.length; i++) {
      const b = blocksForm[i];
      if (b.type === "group") {
        const groupBlocks = b.configuration.group.blocks;
        moveDownwardElementInBlocksForm(selectedElement, groupBlocks, underBlock);
      }
      if (b.uuid === underBlock.uuid) {
        blocksForm.splice(i+1, 0, selectedElement);
        return;
      }
    }
  };

  export const removeElementInBlocksForm = (selectedElement, blocksForm) => {
    for (let i = 0; i < blocksForm.length; i++) {
      const b = blocksForm[i];
      if (b.type === "group") {
        const groupBlocks = b.configuration.group.blocks;
        removeElementInBlocksForm(selectedElement, groupBlocks);
      }
      if (b.uuid === selectedElement.uuid) {
        blocksForm.splice(i, 1);
        return;
      }
    }
  };

  /**
   * Use this function to flat the 'BlocksForm' (used in the surveys configurator)
   */
  export const processToFlatBlocksForm = (blocks) => {
    let flatBlocks = [];

    for(let block of blocks) {
      flatBlocks.push(block);
      if (block.type === "group" && block.configuration.group.blocks.length > 0) {
        processToFlatBlocksForm(block.configuration.group.blocks).forEach(subBlock => flatBlocks.push(subBlock));
      }
    }

    return flatBlocks;
  };

  /**
   * Use this function to flat blocks of a Survey
   */
  export const flatAllSurveyBlocks = (blocks) => {
    let flatBlocks = [];

    for(let block of blocks) {
      flatBlocks.push(block);
      if(block.type === 'group') {
        pushAll(flatBlocks, flatAllSurveyBlocks(block.group.blocks));
      }
    }

    return flatBlocks;
  };

  /**
   * 
   * Exemple of a specific theme
   * {
      backgroundColor: "rgba(246, 247, 248, 0.5)",
      paper: {
        backgroundColor: "white",
        paddingTop: "10px",
        paddingBottom: "10px",
        elevation: 2
      },
      button: {
        color: "white !important",
        backgroundColor: "#009adf !important",
        hoverBackgroundColor: "rgba(0, 96, 162, 1) !important",
        disabledBackgroundColor: "grey !important"
      },
      radio: {
        color: "#009adf !important"
      },
      checkbox: {
        color: "#009adf !important"
      },
      stepper: {
        color: "#009adf !important"
      },
      progress: {
        color: "#009adf !important",
        backgroundColor: "white !important",
      },
    };
   * 
   * 
   * 
   */
  export const defaultTheme = {
    backgroundColor: "#ffffff",
    darkMode: false,
    paper: {
      backgroundColor: "#ffffff",
      paddingTop: "10px",
      paddingBottom: "10px",
      elevation: 0,
      minHeight: "500px"
    },
    button: {
      color: "#ffffff",
      backgroundColor: "#003945",
      hoverBackgroundColor: "#003945",
      disabledBackgroundColor: "gray",
    },
    customNextButton: {
      variant: "text",
    },
    radio: {
      color: "#003945"
    },
    checkbox: {
      color: "#003945"
    },
    stepper: {
      color: "#003945"
    },
    progress: {
      color: "#003945",
      backgroundColor: "#003945"
    },
    logo: {
      width: "100px",
      position: "left"
    },
  };

  export const getTheme = (theme) => {
    return createTheme({
      palette: {
        mode: theme.darkMode ? 'dark' : 'light',
        primary: {
          main: theme.button.backgroundColor,
          dark: theme.button.backgroundColor,
          light: theme.button.backgroundColor,
          contrastText: '#fff',
        },
        secondary: {
          main: theme.button.hoverBackgroundColor,
          dark: theme.button.hoverBackgroundColor,
          light: theme.button.hoverBackgroundColor,
          contrastText: '#000',
        },
        background: {
          default: theme.backgroundColor,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: theme.paper.backgroundColor,
              padding: '10px 0px',
              maxWidth: "1280px"
            },
          },
        },
        MuiLinearProgress: {
          styleOverrides: {
            root: {
              margin: '0px 24px 10px 24px',
            }
          }
        },
        mainCustomNextButton: {
          variant: theme.customNextButton.variant,
        },
      }
    })
  }