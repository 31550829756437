import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Tooltip from "@mui/material/Tooltip";
import { Typography } from '@mui/material';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { isBlank, stripHtmlTags } from '../../../utils/utils';

export default function SimplePieChart(props) {

    const {
        t,
        item,
        formBlock,
        iterationRef
    } = props;

    const [title, setTitle] = useState("");

    useEffect(() => {
        const formBlockBattery = formBlock.battery.items.find(i => i.id === item.id);
        let titleText = `${formBlock.ref}_${formBlockBattery.ref}${(isBlank(iterationRef) ? '' : `_${iterationRef}`)}: ${formBlockBattery.name}`;
        
        if (formBlock.battery.itemsStyle === "thisOrThat") {
            titleText = (
                <div style={{textAlign: 'center', color: 'rgb(51,51,51)'}}>
                    <Typography style={{ fontSize: '1.2em', fontWeight: "bold"}}>{`${formBlock.ref}.${formBlockBattery.ref}: ${formBlockBattery.name} `}</Typography>
                    <Tooltip title={stripHtmlTags(formBlockBattery.textLeft)}>
                        <span style={{  alignItems: 'center' }}>
                            <AlignHorizontalLeftIcon style={{ verticalAlign: 'middle', color: 'rgb(51,51,51)', marginRight: 5 }} />
                            <span style={{fontWeight: 500}}>{t('react.surveys.thisOrThat.left.label')}</span>
                        </span>
                    </Tooltip>
                    {" / "}
                    <Tooltip title={stripHtmlTags(formBlockBattery.textRight)}>
                        <span style={{ alignItems: 'center' }}>
                            <span style={{fontWeight: 500}}>{t('react.surveys.thisOrThat.right.label')}</span>
                            <AlignHorizontalRightIcon style={{ verticalAlign: 'middle', color: 'rgb(51,51,51)', marginLeft: 5 }} />
                        </span>
                    </Tooltip>
                </div>
            );
        }

        setTitle(titleText);
    }, [formBlock, item]);

    const simplePieChart = () => {
        let values = [];

        item.answers.forEach((element) => (
            values.push({
                name: element.text,
                y: element.nbAnswers
            })
        ));

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: null
            },
            tooltip: {
                formatter: function () {
                    return this.point.name + ': <b>' + Math.round(this.y / this.total * 100) + '% </b>'
                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}',
                        distance: 30,
                        style: {
                            fontWeight: 300
                        }
                    },
                    size: 240
                }
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: values
            }],
            credits: {
                enabled: false
            },

        }
    }

    return (
        <div>
            <div>{title}</div>
            <HighchartsReact highcharts={Highcharts} options={simplePieChart()} />
        </div>
    );
}