import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import Image from './Image';
import CustomNextButton from './CustomNextButton';
import DialogFreeField from './DialogFreeField';
import { CollectorContext, CollectorTestContext } from './context';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import { isBlank, sleep, stripHtmlTags, stripHtmlTagsPreserveSpaces } from '../../utils/utils';
import { calculateWidthCol, IMAGE_WIDTH_AUTO } from '../../utils/surveysUtils';
import BarTimeout from './BarTimeout';
import { range, random, shuffle } from 'lodash';
import CustomSlider from './CustomSlider';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CollectorsTestRef from './CollectorsTestRef';

const useStyles = makeStyles()(theme => ({
  positionRelative: {
    position: 'relative',
  },
  helperTextnbExpectedAnswers: {
    marginTop: 10,
    fontStyle: 'italic',
    opacity: '0.5',
    fontSize: '90%',
    textAlign: 'center'
  },
  containerButtonCarousel: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'center', 
    marginTop: 17,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',  // change to flexDirection column for smaller screens
      }
  },
  button: {
    textTransform: 'none',
  },
  mobileStepper: {
    width: 600, 
    height: 20, 
    backgroundColor: 'white', 
    marginLeft: '320px',
      [theme.breakpoints.down('md')]: {
        width: '300px',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 0
      }
  },
  textItem: {
    fontWeight: 500, 
    fontSize: '1.3em', 
    marginTop: 20,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center'
  },
  withLeftBorder: {
    textAlign: 'center', 
    borderRight: '1px solid rgba(224, 224, 224, 1)', 
    borderLeft: '1px solid rgba(224, 224, 224, 1)'
  },
  withoutLeftBorder: {
    textAlign: 'center', 
    borderRight: '1px solid rgba(224, 224, 224, 1)', 
  },
  marginHorizontalZero: {
    marginLeft: 0,
    marginRight: 0
  },
  flexBaseline: {
    display: 'flex',
    alignItems: 'baseline'
  },
  centeredFlexBaseline: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center'
  }
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

export default function BatteryOfItems(props) {

  const { classes } = useStyles();

  const {
    t, showSpinner, openSnackbar, block, testMode
  } = props;

  const {
    collectParameter, collectorsService, handleNextBlock, handleQuotaFull,
    handleScreenOut, participantId, imagesOfForm, userHistory, embeddedReplaceDynamicElements
  } = useContext(CollectorContext);

  // get the TEST context
  const _testContext_ = useContext(CollectorTestContext);

  const [step, setStep] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
  const [arrayToSave, setArrayToSave] = useState([]);
  const [batteryBlockImage, setBatteryBlockImage] = useState(undefined);
  const [nbColumnsCata, setNbColumnsCata] = useState(0);
  const [arrayCata, setArrayCata] = useState([[], [], [], [], []]);
  const [optimalCataButtonHeight, setOptimalCataButtonHeight] = useState(50);
  const [hasTimeoutRunning, setHasTimeoutRunning] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [openPopupDialogFreeField, setOpenPopupDialogFreeField] = useState(false);
  const [editedItemFreeField, setEditedItemFreeField] = useState(0);
  const [labelAnswerFreeField, setLabelAnswerFreeField] = useState(undefined);
  const [realMinAnswers, setRealMinAnswers] = useState(null);
  const [realMaxAnswers, setRealMaxAnswers] = useState(null);

  const [imageReady, setImageReady] = useState(false);
  const [answersImagesReady, setAnswersImagesReady] = useState(false);
  const [answersImages, setAnswersImages] = useState([]);
  const [batteryItemImages, setBatteryItemImages] = useState([]);
  const [batteryItemImagesReady, setBatteryItemImagesReady] = useState(false);

  const ControlComponent = block.battery.type === 'single' ? Radio : Checkbox;

  useEffect(() => {
    setDataReady(false);

    if (!block) return;

    // always reset all fields when a new bloc comes
    const newArrayToSave = block.battery.items
      .map(item => ({
        itemId: item.id,
        answerIds: [],
        enteredValue: ''
      }));
    setArrayToSave(newArrayToSave);
    setStep(0);
    setAnimationKey(0);
    setHasTimeoutRunning(block.timeout > 0);

    // If minExpectedAnswers > total number of answers, we have to change it
    setRealMinAnswers(block.battery.nbMinExpectedAnswers > block.battery.answers.length ? block.battery.answers.length : block.battery.nbMinExpectedAnswers);
    setRealMaxAnswers(block.battery.nbMaxExpectedAnswers > block.battery.answers.length ? block.battery.answers.length : block.battery.nbMaxExpectedAnswers);

    let newOptimalCataButtonHeight = 50;
    let newArrayCata = [[], [], [], [], []];
    let numberOfColumns = block.battery.nbCatalogColumns;

    if (block.battery.itemsStyle !== 'slider' && block.battery.itemsStyle !== 'rating') {
      let maxAnswerLength = 0;

      if (window.screen.width < 800) {
        block.battery.mode = "carousel";
        numberOfColumns = 1;
      }

      if (numberOfColumns > 0) {
        newArrayCata = Array.from({ length: numberOfColumns }, () => []);
        for (let i = 0; i < block.battery.answers.length; i++) {
          const answer = block.battery.answers[i];
          const indiceTableauDestination = i % numberOfColumns;
          newArrayCata[indiceTableauDestination].push(answer);

          if (answer.text.length > maxAnswerLength) {
            maxAnswerLength = answer.text.length;
          }
        }
      }

      if (window.screen.width < 800) {
        newOptimalCataButtonHeight = maxAnswerLength < 35 ? 35 : maxAnswerLength;
      } else if (numberOfColumns === 5) {
        newOptimalCataButtonHeight = maxAnswerLength < 25 ? 50 : maxAnswerLength * 2;
      } else {
        newOptimalCataButtonHeight = maxAnswerLength < 25 ? 35 : maxAnswerLength * 1.4;
      }
    }

    setArrayCata(newArrayCata);
    setNbColumnsCata(numberOfColumns);
    setOptimalCataButtonHeight(newOptimalCataButtonHeight);

    // --------------------------------------------------------------------------------------------
    // -- IMPORTANT NOTE: This last control (skippable) must be done at the end 
    // -- because in testMode the behavior is like a normal block during tests
    // --------------------------------------------------------------------------------------------

    // skip the block if there is nothing to select or if it can be done automaticaly
    if(isBlockSkippable()) {
      var autoSaveData = block.battery.items
        .map(item => ({
          itemId: item.id,
          answerIds: block.battery.answers.map(a => a.id),
          enteredValue: ''
        }));

      if(testMode) {
        setArrayToSave(autoSaveData);
        setDataReady(true);
      } else {
        // go direct to the end
        setStep(block.battery.items.length > 1 ? block.battery.items.length-1 : 0);
        saveBlock(autoSaveData);
      }

      // don't go further in any case
      return;
    }

    // --------------------------------------------------------------------------------------------

    setDataReady(true);
  }, [block]);

  /*
   * This useEffect() is only used to control the display of the block image
   */
  useEffect(() => {
    setImageReady(false);

    if (!block || block.imageId === 0) {
      setBatteryBlockImage(undefined);
      setImageReady(true);
      return;
    }

    let img = imagesOfForm.get(block.imageId);
    if (img !== undefined) {
      setBatteryBlockImage(img);
      setImageReady(true);
      return;
    }

    showSpinner(true);

    collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
      .then(result => {
        imagesOfForm.set(block.imageId, result.url);
        setBatteryBlockImage(result.url);
        setImageReady(true);
        showSpinner(false);
      }).catch(e => {
        setBatteryBlockImage(undefined);
        setImageReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  /*
   * This useEffect() is only used to control the display of answers images
   */
  useEffect(() => {
    setAnswersImagesReady(false);

    var promises = block.battery.answers
      .filter(answer => answer.imageId && answer.imageId > 0)
      .map(answer => collectorsService.getPublicFormFileUrl(collectParameter, answer.imageId));

    if (promises.length === 0) {
      setAnswersImages([]);
      setAnswersImagesReady(true);
      return;
    }

    showSpinner(true);

    Promise.all(promises)
      .then(results => {
        setAnswersImages(results);
        setAnswersImagesReady(true);
        showSpinner(false);
      }).catch(e => {
        setAnswersImages([]);
        setAnswersImagesReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  /*
   * This useEffect() is only used to control the display of items images
   */
  useEffect(() => {
    setBatteryItemImagesReady(false);

    var promises = block.battery.items
      .filter(item => item.imageId && item.imageId > 0)
      .map(item => collectorsService.getPublicFormFileUrl(collectParameter, item.imageId));

    if (promises.length === 0) {
      setBatteryItemImages([]);
      setBatteryItemImagesReady(true);
      return;
    }

    showSpinner(true);

    Promise.all(promises)
      .then(results => {
        setBatteryItemImages(results);
        setBatteryItemImagesReady(true);
        showSpinner(false);
      }).catch(e => {
        setBatteryItemImages([]);
        setBatteryItemImagesReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  const isBlockSkippable = () => {
    if(block.battery.items.length === 0 || block.battery.answers.length === 0) return true;
    if(block.battery.type === "single" && block.battery.answers.length === 1) return true;
    if(block.battery.type === "multiple" && block.battery.answers.length <= block.battery.nbMinExpectedAnswers) return true;
    return false;
  };

  const isSelected = (answer, item) => {
    const pos = arrayToSave.findIndex(i => i.itemId === item.id);
    return pos >= 0 ? arrayToSave[pos].answerIds.includes(answer.id) : false;
  };

  const saveBlock = async (autoSaveSelectedAnswers = undefined) => {
    // pass to next item (step) until this end of the list of items
    if (block.battery.mode === "carousel" && step < block.battery.items.length-1) {
      setStep(step+1);
      window.scrollTo({top: 0});
      setAnimationKey(animationKey+1);
      return;
    }

    showSpinner(true);

    let data = (autoSaveSelectedAnswers || [...arrayToSave]).map(it => ({
        ...it,
        iterationId: block.iterationId,
        iterationLoopIndex: block.iterationLoopIndex
      }));

    await collectorsService.collectBatteryOfItemsAnswer(participantId, block.id, data)
      .then(response => {
        setDataReady(false);
        showSpinner(false);
        pushAnswersToUserHistory(data);
        handleNextBlock();
      }).catch(error => {
        setDataReady(false);
        showSpinner(false);
        if (error.response?.status === 302) {
          handleQuotaFull();
        } else if (error.response?.status === 307) {
          handleScreenOut();
        } else {
          openSnackbar('error', t('react.error.save.message'));
          handleScreenOut();
        }
      });
  };

  const pushAnswersToUserHistory = (data) => {
    // append user answers of the question to the user answers list for text replacement
    const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id && it.iterationId === block.iterationId);
    data.forEach(it => {
      const objectRef = `${block.ref}.${block.battery.items.find(i => i.id === it.itemId).ref}`;
      const answers = it.answerIds.map(answerId => {
          const answer = block.battery.answers.find(ba => ba.id === answerId);
          return { id: answer.id, ref: answer.ref, text: answer.text, freeField: answer.freeField };
        });
      const userAnswerToAdd = { objectRef: objectRef, answers: answers, enteredValue: it.enteredValue };
      userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
    });
  };

  /**
   * IMPORTANT NOTE: IT IS IMPORTANT TO UPDATE SUR setAutomaticAnsweringCallback() BECAUSE IF YOU NEED
   * TO ACCESS OTHER STATES (e.g: arrayToSave, step) OF THE COMPONENT, THE CALLBACK MUST BE UPDATED TOO (EVEN IF IT'S A FUNCTION!)
   */
  useEffect(() => {
    if(_testContext_) {
      // if we are in test mode, we have a testContext - replace the automatic answering system
      _testContext_.setAutomaticAnsweringCallback(() => _test_automaticallyAnswerToQuestion_);
    }
  }, [block, arrayToSave, step, realMinAnswers, realMaxAnswers /* add more states you need to monitor in _test_automaticallyAnswerToQuestion_() */]);

  const _test_automaticallyAnswerToQuestion_ = () => {
    let newArrayToSave = [];

    let numberOfAnswers = block.battery.type === "multiple"
      ? random(realMinAnswers, realMaxAnswers)
      : 1;

    if(block.battery.mode === "carousel") {
      let answers = shuffle(block.battery.answers)
        .slice(0, numberOfAnswers);

      if(answers.some(a => a.exclusive)) {
        // remove all non-exclusive answers
        answers = answers.filter(a => a.exclusive);
      }

      const answerIds = answers.map(answer => answer.id);

      // in "carousel" mode, only feed the current item (step)
      newArrayToSave = [...arrayToSave];
      let idxOfItemToChange = newArrayToSave.findIndex(it => it.itemId === block.battery.items[step].id);
      if(idxOfItemToChange >= 0) {
        newArrayToSave[idxOfItemToChange].answerIds = answerIds;
      }
    } else {
      // in others, regenerate all results in one shot
      newArrayToSave= block.battery.items
        .map(item => {
          let answers = shuffle(block.battery.answers)
            .slice(0, numberOfAnswers);
  
          if(answers.some(a => a.exclusive)) {
            // remove all non-exclusive answers
            answers = answers.filter(a => a.exclusive);
          }
    
          const answerIds = answers.map(answer => answer.id);
  
          return {
            itemId: item.id,
            answerIds: answerIds,
            enteredValue: ''
          };
        });
    }

    setArrayToSave(newArrayToSave);
  };

  const selectAnswer = (answer, item, autoSave = false) => {
    let tempArr = [...arrayToSave];
    let openFreeFieldDialog = false;


    let indexToChange = tempArr.findIndex(i => i.itemId === item.id);

    // if exclusive answer, do this code
    if (tempArr[indexToChange].answerIds.length > 0) {
      var selectedAnswer = block.battery.answers.find(a => a.id === tempArr[indexToChange].answerIds[0]);
      if (selectedAnswer.exclusive && (selectedAnswer.id !== answer.id)) {
        return;
      }
    }

    if (block.battery.type === "single") {
      tempArr[indexToChange].answerIds = [answer.id];

      if(answer.freeField) {
        // do not enable the freefield in slider mode for the moment
        openFreeFieldDialog = (block.battery.itemsStyle !== "slider" && block.battery.itemsStyle !== "rating");
      } else {
        // always clear the enteredValue if it's not the freefield
        tempArr[indexToChange].enteredValue = '';
      }
    } else {
      let existsAtIdx = tempArr[indexToChange].answerIds.indexOf(answer.id);
      if (existsAtIdx >= 0) {
        // remove if exists and clear the enteredValue if it is the freeField
        tempArr[indexToChange].answerIds.splice(existsAtIdx, 1);
        if(answer.freeField) tempArr[indexToChange].enteredValue = '';
      } else {
        // else add
        if (answer.exclusive === true) {
          tempArr[indexToChange].answerIds = [answer.id];
        } else {
          tempArr[indexToChange].answerIds.push(answer.id);
        }
        openFreeFieldDialog = answer.freeField;
      }
    }

    setArrayToSave(tempArr);

    if (openFreeFieldDialog) {
      setEditedItemFreeField(item.id);
      setLabelAnswerFreeField(answer.text);
      setOpenPopupDialogFreeField(true);

      // never do the auto-save when the user has to enter a freefield value
      return;
    }

    if (autoSave) {
      // in order to have a smooth behavior, there is a delay of 1s before passing to the next block.
      // it lets the user see its answer (e.g. with slider on mobile)
      sleep(500).then(() => {
        saveBlock(tempArr);
      });
    }
  };

  const isButtonDisabled = () => {
    if(testMode) return false;

    if (arrayToSave.length === 0) {
      return;
    }

    let state = false;  
      if (block.battery.mode === "row" || block.battery.mode === "column") {
        if (block.battery.type === "single") {
          if (!arrayToSave.every(i => i.answerIds.length > 0)) {
            state = true;
          }
        } else {
          if (realMinAnswers && !arrayToSave.every(i => i.answerIds.length >= realMinAnswers)) {
            state = true;
          } 
          if (realMaxAnswers && !arrayToSave.every(i => i.answerIds.length <= realMaxAnswers)) {
            state = true;
          } 
        }
      } else {
        if (block.battery.type === "single") {
          if (!arrayToSave[step].answerIds.length > 0) {
            state = true;
          }
        } else {          
          if (realMinAnswers && arrayToSave[step].answerIds.length < realMinAnswers) {
            state = true;
          }
          if (realMaxAnswers && arrayToSave[step].answerIds.length > realMaxAnswers) {
            state = true;
          }
        }
      }
    return state;
  };

  const handleBarTimeoutExpired = () => {
    setHasTimeoutRunning(false);
  };

  const handleSelectAnswerCustomSlider = (answer) => {
    selectAnswer(answer, block.battery.items[step], true);
  };

  const getSelectedItemAnswerForCustomSlider = () => {
    var itemData = arrayToSave.find(d => d.itemId === block.battery.items[step].id);
    if(itemData) return itemData.answerIds.length > 0 ? block.battery.answers.findIndex(a => a.id === itemData.answerIds[0]) : -1;
    return -1;
  };

  const getCorrespondingAnswerImage = (idP) => {
    const image = answersImages.find(img => img.id === idP);
    return image ? image.url : undefined;
  };

  const getCorrespondingItemImage = (idP) => {
    const image = batteryItemImages.find(img => img.id === idP);
    return image ? image.url : undefined;
  };

  const helperText = () => {
    if(hasTimeoutRunning || block.battery.type !== "multiple") return null;

    const suffixTableOrNot = (block.battery.mode === "row" || block.battery.mode === "column") ? '.TABLE' : '';

    var textToReturn = null;

    if (realMinAnswers > 0 && realMinAnswers === realMaxAnswers) {
      textToReturn = t(`LBL.PQ.MANDATORY.ANSWERS${suffixTableOrNot}`, {
        numAnswers: realMinAnswers,
      });
    } else if (realMinAnswers > 0 && realMaxAnswers < block.battery.answers.length) {
      textToReturn = t(`LBL.PQ.MINIMUM_AND_MAXIMUM.ANSWERS${suffixTableOrNot}`, {
        numMinAnswers: realMinAnswers,
        numMaxAnswers: realMaxAnswers,
      });
    } else if (realMinAnswers) {
      textToReturn = t(`LBL.PQ.MINIMUM.ANSWERS${suffixTableOrNot}`, {
        numAnswers: realMinAnswers,
      });
    } else if (realMaxAnswers) {
      textToReturn = t(`LBL.PQ.MAXIMUM.ANSWERS${suffixTableOrNot}`, {
        numAnswers: realMaxAnswers,
      });
    }

    return textToReturn ? <div className={classes.helperTextnbExpectedAnswers}>{textToReturn}</div> : null;
  };

  const displayNextButton = () => {
    // while the time-out is not expired, do not display button
    if (hasTimeoutRunning) return false;

    if(testMode) return true;

    // do not enable the freefield in slider mode for the moment
    if(block.battery.itemsStyle === "slider" || block.battery.itemsStyle === "rating") return false;

    // always display the next button when there is a freeField
    if(block.battery.answers.findIndex(a => a.freeField) >= 0) return true;

    // the NextButton is hidden only when it's a carrousel in single mode (what ever is the itemsStyle)
    const isCarouselSingle = block.battery.mode === "carousel" && block.battery.type === "single";

    return !isCarouselSingle;
  };

  const handleCloseDialogFreeField = (userEnteredValue) => {
    let tempArr = [...arrayToSave];
    const indexToChange = tempArr.findIndex(it => it.itemId === editedItemFreeField);
    tempArr[indexToChange].enteredValue = userEnteredValue;
    setArrayToSave(tempArr);

    // close and clean the popup
    setOpenPopupDialogFreeField(false);
    setEditedItemFreeField(0);
    setLabelAnswerFreeField(undefined);
  };

  const getUserEnteredValueAt = (answerParam, indexOfAnswer, displayAsIconWithTooltip = false) => {
    // if it's not a freefield or if the answer index doesn't exist or empty
    if (!answerParam.freeField || indexOfAnswer >= arrayToSave.length || isBlank(arrayToSave[indexOfAnswer].enteredValue)) {
      return '';
    }

    return displayAsIconWithTooltip
      ? <Tooltip title={arrayToSave[indexOfAnswer].enteredValue}><IconButton><MoreHorizIcon/></IconButton></Tooltip>
      :  ` (${arrayToSave[indexOfAnswer].enteredValue})`;
  };

  const disableExclusive = (answer, item) => {
    let disabled = false;
    let itemAnswers = arrayToSave.find(i => i.itemId === item.id);

    // if exclusive answer, do this code
    if (itemAnswers && itemAnswers.answerIds.length > 0) {
      var selectedAnswer = block.battery.answers.find(a => a.id === itemAnswers.answerIds[0]);
      if (selectedAnswer.exclusive && (selectedAnswer.id !== answer.id)) {
        disabled = true;
      }
    }
    return disabled;
  };

  if(!dataReady || !imageReady || !answersImagesReady || !batteryItemImagesReady) return null;

  return (
    <Container className={classes.positionRelative}>
      <DialogFreeField
        {...props}
        openPopupDialogFreeField={openPopupDialogFreeField}
        setOpenPopupDialogFreeField={setOpenPopupDialogFreeField}
        labelAnswerFreeField={labelAnswerFreeField}
        handleCloseDialogFreeField={handleCloseDialogFreeField}
      />
      <Grid container spacing={6}>
        {batteryBlockImage !== undefined &&
          <Grid item xs={12}>
            <Image
              imageUrl={batteryBlockImage}
              position={block.imagePosition}
              width={block.imageWidth}
            />
          </Grid>
        }
        {hasTimeoutRunning && <Grid item xs={12} md={8} className={classes.positionRelative}>
          <BarTimeout
            {...props}
            handleBarTimeoutExpired={handleBarTimeoutExpired}
          />
        </Grid>}
        {!hasTimeoutRunning && <Grid item xs={12} key={`Grid-${block.id}`}>
          {block.question.length > 0 && <Box className={classes.flexBaseline}>
            <CollectorsTestRef {...props} displayType={"typography"} refToDisplay={block.ref}/>
            <div className="ql-view" style={{marginBottom: 20, marginTop: 10, }} dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.question) }} />
          </Box>}
          {block.question.length > 0 && block.battery.mode === "carousel" && <Divider/>}
          {block.battery.mode !== "carousel" && arrayToSave.length > 0 ?  
          <TableContainer>
            <Table>
              <TableHead>
                {block.battery.mode === "row" ? 
                  <TableRow>
                    <TableCell></TableCell>
                    {block.battery.answers.map((answer, index) => (
                      <TableCell key={index} className={index === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                        <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                        {!block.battery.displayOnlyAnswerImages && stripHtmlTagsPreserveSpaces(embeddedReplaceDynamicElements(answer.text))}
                        {answer.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingAnswerImage(answer.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}
                            /> 
                          </div>
                        }
                      </TableCell>
                    ))}
                    {block.battery.itemsStyle === "thisOrThat" && <TableCell></TableCell>}
                  </TableRow> : 
                  <TableRow>
                    <TableCell></TableCell>
                    {block.battery.items.map((item, index) => (
                      <TableCell key={index} className={index === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                        <Box className={classes.centeredFlexBaseline}>
                          <CollectorsTestRef {...props} refToDisplay={item.ref}/>
                          <span dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.text)}}/>
                        </Box>
                        {item.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingItemImage(item.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(item.text))}
                            /> 
                          </div>
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                }
              </TableHead>
              {block.battery.mode === "row" ?
                <TableBody>
                  {block.battery.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {block.battery.itemsStyle === "thisOrThat" ? 
                          <Box className={classes.flexBaseline}>
                            <CollectorsTestRef {...props} refToDisplay={item.ref}/>  
                            <span dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.textLeft)}}/>
                          </Box>: 
                          <Box className={classes.flexBaseline}>
                            <CollectorsTestRef {...props} refToDisplay={item.ref}/>  
                            <span dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.text)}}/>
                          </Box>
                        }
                        {item.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingItemImage(item.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(item.text))}
                            /> 
                          </div>
                        }
                        </TableCell>
                      {block.battery.answers.map((answer, subIndex) => (
                        <TableCell key={subIndex} className={subIndex === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                          <FormControlLabel 
                            disabled={disableExclusive(answer, item)}
                            className={classes.marginHorizontalZero} 
                            control={<ControlComponent checked={isSelected(answer, item)} onClick={() => selectAnswer(answer, item)}/>}
                          /> 
                          {getUserEnteredValueAt(answer, index, true)}
                        </TableCell>
                      ))}
                       {block.battery.itemsStyle === "thisOrThat" && <TableCell className={classes.flexBaseline}>
                        <CollectorsTestRef {...props} refToDisplay={item.ref}/>
                        <span dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.textRight)}}/>
                      </TableCell>}
                    </TableRow>
                  ))}
                </TableBody> :
                <TableBody>
                  {block.battery.answers.map((answer, index) => (
                    <TableRow key={index}>
                      <TableCell >
                        <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                        {!block.battery.displayOnlyAnswerImages && stripHtmlTagsPreserveSpaces(embeddedReplaceDynamicElements(answer.text))}
                        {answer.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingAnswerImage(answer.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}
                            /> 
                          </div>
                        }
                        </TableCell>
                      {block.battery.items.map((item, subIndex) => (
                        <TableCell key={subIndex} className={subIndex === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                          <FormControlLabel 
                            disabled={disableExclusive(answer, item)}
                            className={classes.marginHorizontalZero} 
                            control={<ControlComponent checked={isSelected(answer, item)} onClick={() => selectAnswer(answer, item)}/>}
                          /> 
                          {getUserEnteredValueAt(answer, subIndex, true)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              }
            </Table>
          </TableContainer> : block.battery.mode === "carousel" && arrayToSave.length > 0 &&
          <div>
            <div
              key={animationKey}
              className={`${step > 0 ? "animate" : undefined} ${classes.textItem}`}
            >
              <CollectorsTestRef {...props} refToDisplay={block.battery.items[step].ref}/>
              <span dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.battery.items[step].text)}}/>
              {block.battery.items[step].imageId > 0 &&
                <Image
                  imageUrl={getCorrespondingItemImage(block.battery.items[step].imageId)}
                  width={IMAGE_WIDTH_AUTO}
                  maxWidth={400}
                  maxHeight={400}
                  title={stripHtmlTags(embeddedReplaceDynamicElements(block.battery.items[step].text))}
                />
              }
            </div>
            {!isBlank(block.battery.instructions) && block.battery.mode === "carousel" && <div style={{marginTop: 30, marginBottom: -20, textAlign: 'center'}}>{block.battery.instructions}</div>}
            {nbColumnsCata !== 0 ? 
              <div style={{marginTop: 20}}>
                <Grid style={calculateWidthCol(nbColumnsCata)} container spacing={nbColumnsCata > 1 ? 3 : 0} columns={nbColumnsCata === 1 ? 3 : nbColumnsCata === 2 ? 6 : nbColumnsCata === 3 ? 9 : nbColumnsCata === 4 ? 12 : 15}>
                  {range(nbColumnsCata).map(indexCol => (
                    <Grid item xs={3} key={indexCol}>
                      {arrayCata[indexCol].length > 0 && arrayCata[indexCol].map((answer) => (
                        <div key={answer.id}>
                          {!block.battery.displayOnlyAnswerImages && <Button 
                            disabled={disableExclusive(answer, block.battery.items[step])}
                            variant={arrayToSave[step].answerIds.includes(answer.id) ? "contained" : "outlined"} 
                            style={{width: '100%', textTransform: 'none', height: optimalCataButtonHeight, marginBottom: 13}} 
                            onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                            key={answer.id}
                          >
                            <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                            {stripHtmlTagsPreserveSpaces(embeddedReplaceDynamicElements(answer.text))}{getUserEnteredValueAt(answer, step)}
                          </Button>}
                          {answer.imageId > 0 &&
                            <ImageButton
                              disabled={disableExclusive(answer, block.battery.items[step])}
                              onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                              focusRipple
                              title={`${stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}${getUserEnteredValueAt(answer, step)}`}
                              style={{ 
                                width: '100%', 
                                border: arrayToSave[step].answerIds.includes(answer.id) ? '2px solid #003945' : '2px solid rgba(0,0,0,0)', 
                                marginTop: block.battery.displayOnlyAnswerImages ? 0 : -13, 
                                marginBottom: block.battery.displayOnlyAnswerImages ? 0 : 10, 
                                padding: 2 
                              }}
                            >
                              <img src={getCorrespondingAnswerImage(answer.imageId)} width={'auto'} style={{maxHeight: 200, maxWidth: 200}}/>
                            </ImageButton>
                          }
                        </div>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </div>
              : (block.battery.itemsStyle === "slider" || block.battery.itemsStyle === "rating") ?
                <div style={{marginTop: 20}}>
                  <CustomSlider 
                    {...props}
                    identifier={block.battery.items[step].id}
                    selectAnswer={handleSelectAnswerCustomSlider}
                    answers={block.battery.answers.map(a => ({text: a.text, id: a.id, ref: a.ref}))}
                    selectedIndex={getSelectedItemAnswerForCustomSlider()}
                    style={block.battery.itemsStyle}
                    position='center'
                  />
                </div> :
              <div className={classes.containerButtonCarousel}>
                {block.battery.answers.map((answer, index) => (
                  <div style={{position: 'relative', display: 'flex', flexDirection: 'column', width: '100%'}} key={index}>
                    {!block.battery.displayOnlyAnswerImages &&
                      <Button 
                        key={index}
                        disabled={disableExclusive(block.battery.items[step], answer)}
                        style={{margin: 10, width: '96%', marginLeft: '2%', height: '100%'}} 
                        variant={arrayToSave[step].answerIds.includes(answer.id) ? "contained": "outlined"}
                        onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                        className={classes.button}
                      >
                        <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                        {stripHtmlTagsPreserveSpaces(embeddedReplaceDynamicElements(answer.text))}{getUserEnteredValueAt(answer, step)}
                      </Button>} 
                    {answer.imageId > 0 &&
                      <ImageButton
                        disabled={disableExclusive(block.battery.items[step], answer)}
                        onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                        focusRipple
                        title={`${stripHtmlTagsPreserveSpaces(embeddedReplaceDynamicElements(answer.text))}${getUserEnteredValueAt(answer, step)}`}
                        style={{ 
                          width: block.battery.displayOnlyAnswerImages ? '100%' : '96%',
                          marginLeft: block.battery.displayOnlyAnswerImages ? '0%' : '2%',
                          border: arrayToSave[step].answerIds.includes(answer.id) ? '2px solid #003945' : '2px solid rgba(0,0,0,0)',
                          marginTop: block.battery.displayOnlyAnswerImages ? 0 : -10,
                          padding: 2
                        }}
                      >
                        <img src={getCorrespondingAnswerImage(answer.imageId)} width={'auto'} style={{maxHeight: 200, maxWidth: 200}} />
                      </ImageButton>
                    }
                  </div>
                ))}
              </div>                          
            }
          </div>
          }           
        </Grid>}
      </Grid>
      {!hasTimeoutRunning && block.battery.mode === "carousel" && block.battery.displayStepper &&
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <MobileStepper
            variant="progress"
            steps={block.battery.items.length+1}
            position="static"
            activeStep={step+1}
            className={classes.mobileStepper}
          />
        </div>
      }
      {helperText()}
      {displayNextButton() &&
        <CustomNextButton
          {...props}
          disabled={isButtonDisabled()}
          handleNextBlock={() => saveBlock()}
        />
      }
      <style>
        {`
          @keyframes slideAnimation {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          .animate {
            animation: slideAnimation 0.8s ease-in-out;
          }
        `}
      </style>
    </Container>
  );
};
