import { sleep } from '../../utils/utils.js';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    spinnerBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        position: 'relative'
    },
    spinnerArea: {
        position: 'absolute', 
        top: -38, 
        right: 120
    },
}));

export default function CircularProgressTimer(props) {

    const { classes } = useStyles();

    const { block, handleTimerExpired } = props;

    const [counter, setCounter] = useState(undefined);
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        if (!block) return;

        // initial load will fire a new useEffect, that's why we can return
        if (block.responseTimeoutInSeconds && block.responseTimeoutInSeconds > 0) {
            setCounter(block.responseTimeoutInSeconds);
            setExpired(false);
        } else {
            setExpired(true);
            setCounter(undefined);
        }        
    }, [block])

    useEffect(() => {
        if(!counter) return;

        sleep(1000).then(() => {
            let newCounter = counter - 1;
            if (newCounter < 1) {
                setExpired(true);
            } else {
                setCounter(newCounter);
            }
        });
    }, [counter]);

    useEffect(() => {
        if (!expired) return;
        handleTimerExpired();
    }, [expired]);

    if (!block) return null;

    return (
        <Box className={classes.spinnerBox}>
            <Box className={classes.spinnerArea}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" style={{color: expired && "rgba(0,0,0,0.1)"}} value={expired ? 100 : (counter * 100 / block.responseTimeoutInSeconds)-2}/>
                    <Box sx={{top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography
                            variant="caption"
                            component="div"
                            style={{color: expired && "rgba(0,0,0,0.3)", fontWeight: 600}}
                        >
                            {expired ? 0 : counter}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}