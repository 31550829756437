import React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const useStyles = makeStyles()(theme => ({
    focusVisible: {
      backgroundColor: '#eee'
    },
    buttonBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    customButton: {
      marginTop: 20,
      right: 0,
      paddingLeft: 15,
    },
}));

const CustomArrowForwardIosIcon = styled(ArrowForwardIosIcon)({
  fontSize: '1em',
  marginLeft: 5,
});

export default function CustomNextButton(props) {

  const { classes } = useStyles();
  const theme = useTheme();

  const { t, handleNextBlock, disabled } = props;

  return (
    <Box className={classes.buttonBox}>
      <Button
        id="mainCustomNextButton01"
        onClick={() => handleNextBlock()}
        disabled={disabled}
        className={classes.customButton}
        focusVisibleClassName={classes.focusVisible}
        variant={theme.components.mainCustomNextButton.variant}
        disableRipple>
          {t('LBL.BUTTON.NEXT')} <CustomArrowForwardIosIcon/>
      </Button>
    </Box>
  );
}
