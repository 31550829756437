//FIXME il y a potentiellement un pb de colonne qd on est pas admin
import React, {useEffect, useState} from "react";
import { Grid, Toolbar, Typography, Tooltip, IconButton, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox,} from "@mui/material";
import { toolbarStyles } from "../../common";
import EditThematizationDialog from "./Dialogs/EditThematizationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import {LoadData} from "../../Constants";
import PopupTwoButtons from "../shared/PopupTwoButtons/PopupTwoButtons";
import ImportDialog from "./Dialogs/ImportDialog";
import GenerateCodePlanDialog from "./Dialogs/GenerateCodePlanDialog";
import ThematizationService from "../../services/ThematizationService";
import {makeStyles} from "tss-react/mui";
import ThematizationDialog from "./Dialogs/ThematizationDialog";
import CodePlanDialog from "./Dialogs/CodePlanDialog";
import {appendSuffixIfGreaterThan} from "../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import {getItemAriaLabel} from "../../utils/pagination";
import LinearProgress from "@mui/material/LinearProgress";
import TableSortLabel from "@mui/material/TableSortLabel";
import {AuthService} from "../../services/AuthService";
import clsx from "clsx";
import TopicIcon from '@mui/icons-material/Topic';
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ClearIcon from "@mui/icons-material/Clear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CachedIcon from "@mui/icons-material/Cached";

const thematizationService = new ThematizationService();

const useStyles = makeStyles()((theme) => ({
	fileList: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingRight: 0,
		width: "20%",
	},
	tableWrapper: {
		overflowX: "auto",
	},
	list: {
		padding: 0,
		display: "flex",
		alignItems: "center",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	table: {
		minWidth: 750,
	},
	p : {
		margin: 0,
		padding: 0,
	},
	flexCenter: {
		display: "flex",
		gap: "8px",
		justifyContent: "center",
	},
	tableRowCursorPointer: {
		cursor: "pointer",
	},
}));

const headCells = [
	{ id: "name", label: "react.aitools.thematization.filename", minWidth: 50 },
	{ id: "description", label: "react.aitools.thematization.desc", minWidth: 200 },
	{ id: "lang", label: "react.aitools.thematization.lang", minWidth: 20 },
	{ id: "state", label: "react.aitools.thematization.state", minWidth: 20 },
	{ id: "createdBy", label: "react.aitools.thematization.user", minWidth: 20, adminView: true },
];

function ThematizationHead(props) {

	const { classes, t, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						id="checkbox-all"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headCells
					.filter(function (headCell) {
						if (headCell.adminView === true && !AuthService.isAdmin()) {
							return false; // skip
						} else if (headCell.adminView === false && AuthService.isAdmin()) {
							return false; // skip
						}
						return true;
					})
					.map((headCell) => (
						<TableCell
							key={headCell.id}
							align={headCell.align}
							padding={headCell.disablePadding ? "none" : "normal"}
							sortDirection={
								sortBy === headCell.id ? sortDirection : false
							}
						>
							{headCell.sortable === false ? (
								t(headCell.label)
							) : (
								<TableSortLabel
									active={sortBy === headCell.id}
									direction={sortDirection}
									onClick={(e) => handleChangeSort(e, headCell.id)}
								>
									{t(headCell.label)}
									{sortBy === headCell.id ? (
										<span className={classes.visuallyHidden}>{sortDirection === "desc" ? "sorted descending" : "sorted ascending"}</span>
									) : null}
								</TableSortLabel>
							)}
						</TableCell>
					))}
			</TableRow>
		</TableHead>
	);
}

const ThematizationToolbar = (props) => {
	const { classes } = toolbarStyles();
	const { t, selected, countForPagination, keywords, handleKeywordsChange, 
		setEditThematizationDialogOpen, setDeleteThematizationDialogOpen, setLoadData} = props;

	const numSelected = selected.length;

	const handleClickRefresh = (event) => {
		event.preventDefault();
		setLoadData(LoadData.Load);
	};

	return (
		<Toolbar className={clsx(classes.root, {[classes.highlight]: numSelected > 0,})}>
			<TopicIcon className={classes.mainIcon} />
			{numSelected > 0 ? (
				<Typography
					className={classes.title}
					color="inherit"
					variant="subtitle1"
				>
					{t("react.aitools.thematization.title.number.selected", {numSelected: numSelected,})}
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle">
					{t("react.aitools.thematization.title", {
						countForPagination: countForPagination,
					})}
				</Typography>
			)}
			<Input
				id="search-filter-basic"
				value={keywords}
				className={classes.searchfield}
				placeholder={t("react.translationtool.search.title")}
				onChange={(e) => handleKeywordsChange(e.target.value)}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={(e) => handleKeywordsChange("")} edge="end" size="large">
							<ClearIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
			{numSelected > 0 && (
				<Tooltip title="react.aitools.thematization.remove">
					<IconButton
						size="large"
						disabled={Object.keys(selected).length === 0}
						onClick={() => setDeleteThematizationDialogOpen(true)}
					>
						<DeleteIcon style={{ transform: "scaleX(-1)" }} />
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title={t('react.aitools.thematization.add')}>
				<IconButton onClick={() => setEditThematizationDialogOpen(true)}>
					<ControlPointIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title={t("react.button.refresh")}>
				<IconButton onClick={handleClickRefresh} size="large">
					<CachedIcon />
				</IconButton>
			</Tooltip>
		</Toolbar>
	);
};

export default function ThematizationsList(props) {
	const { classes: toolbarClasses } = toolbarStyles();
	const {classes} = useStyles();

	const { t, showSpinner, openSnackbar } = props;

	const [thematizations, setThematizations] = useState([]);
	const [users, setUsers] = useState([]);

	const [editThematization, setEditThematization] = useState(undefined);

	const [selectedThematizations, setSelectedThematizations] = useState([]);
	const [mouseOverThematization, setMouseOverThematization] = useState(null);

	const [openCodePlanDialog, setOpenCodePlanDialog] = useState(false);
	const [editThematizationDialogOpen, setEditThematizationDialogOpen] = useState(false);
	const [deleteThematizationDialogOpen, setDeleteThematizationDialogOpen] = useState(false);
	const [generateCodePlanDialogOpen, setGenerateCodePlanDialogOpen] = useState(false);
	const [importDialogOpen, setImportDialogOpen] = useState(false);
	const [openThematizationDialog, setOpenThematizationDialog] = useState(false);

	const [countForPagination, setCountForPagination] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [sortBy, setSortBy] = useState("type");
	const [sortDirection, setSortDirection] = useState("asc");
	const [keywords, setKeywords] = useState("");
	const [loadKeywordsChange, setLoadKeywordsChange] = useState(false);

	const [loadData, setLoadData] = useState(LoadData.Load);

	useEffect(() => {
		if (loadData !== LoadData.Load) return;
		setLoadData(LoadData.Loading);

		thematizationService.getAllThematizations(page, rowsPerPage, sortBy, sortDirection, keywords)
			.then(response => {
				setUsers(users.concat(response.data.users));
				setThematizations(response.data.hits);
				setCountForPagination(response.data.total);
			})
			.catch(err => {
				setUsers([]);
				setThematizations([]);
				setCountForPagination(0);
				openSnackbar('error', t("react.aitools.thematization.error.loading"));
			}).finally(() => {
				setLoadData(LoadData.Loaded);
				setLoadKeywordsChange(false);
			});
	}, [loadData, page, rowsPerPage, sortBy, sortDirection, loadKeywordsChange, keywords]);

	const handleDeleteThematizations = () => {
		const deletePromises = selectedThematizations.map(thematizationId =>
			thematizationService.deleteThematizations([thematizationId])
		);

		Promise.all(deletePromises)
			.then(responses => {
				openSnackbar('success', t("react.aitools.thematization.success.delete"));
			})
			.catch(err => {
				openSnackbar('error', t("react.aitools.thematization.error.delete"));
			})
			.finally(() => {
				setLoadData(LoadData.Load);
				setSelectedThematizations([]);
				setDeleteThematizationDialogOpen(false);
			});
	};

	const handleChangePage = (event, newPage) => {
		event.preventDefault();
		setPage(newPage);
		setLoadData(LoadData.Load);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		setLoadData(LoadData.Load);
	};

	const handleChangeSort = (event, headCellId) => {
		event.preventDefault();
		if (sortBy !== headCellId) {
			setSortBy(headCellId);
			setSortDirection("asc");
		} else {
			setSortDirection(sortDirection === "desc" ? "asc" : "desc");
		}
		setLoadData(LoadData.Load);
	};

	const handleKeywordsChange = (newValue) => {
		setPage(0);
		setKeywords(newValue);
		setLoadKeywordsChange(true);
		setLoadData(LoadData.Load);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = thematizations.map((n) => n.id);
			setSelectedThematizations(newSelecteds);
			return;
		}
		setSelectedThematizations([]);
	};

	const handleRenameFolder = (e, thematization) => {
		setEditThematization(thematization);
		setEditThematizationDialogOpen(true);
	};

	const handleOpenCodePlan = (e, thematization) => {
		setEditThematization(thematization);
		setOpenCodePlanDialog(true);
	};

	const getOwnerName = (thematization) => {
		let value = '';
		const realUser = users.find((el) => el.id === thematization.userId);
		if (realUser) {
			value = `${realUser.firstname} ${realUser.lastname}`;
		}
		return value;
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, thematizations.length);

	return (
		<Grid item xs={12}>
			{loadData !== LoadData.Loaded && !loadKeywordsChange ? (
				<LinearProgress />
			) : (
				<Paper className={classes.paper}>
					<ThematizationDialog
						{...props}
						openState={openThematizationDialog}
						editThematization={editThematization}
						callbackOnclose={() => {setOpenThematizationDialog(false);}}
					/>
					<CodePlanDialog
						{...props}
						openState={openCodePlanDialog}
						editThematization={editThematization}
						callbackOnclose={() => {setOpenCodePlanDialog(false); setEditThematization(undefined);}}
						setLoadAllData={setLoadData}
						setOpenThematizationDialog={setOpenThematizationDialog}
						setImportDialogOpen={setImportDialogOpen}
						setGenerateCodePlanDialogOpen={setGenerateCodePlanDialogOpen}
					/>
					<PopupTwoButtons
						variant='warning'
						openState={deleteThematizationDialogOpen}
						callbackOnclose={() => setDeleteThematizationDialogOpen(false)}
						callbackOnclickLeftButton={() => setDeleteThematizationDialogOpen(false)}
						callbackOnclickRightButton={handleDeleteThematizations}
						title={t('react.project.tab.filters.delete.title')}
						content={t('react.project.tab.filters.delete.body')}
						leftButton={t('react.button.cancel')}
						rightButton={t('react.button.delete')}
					/>
					<ImportDialog
						{...props}
						openState={importDialogOpen}
						editThematization={editThematization}
						setLoadData={setLoadData}
						callbackOnclose={() => setImportDialogOpen(false)}
					/>
					<GenerateCodePlanDialog
						{...props}
						openState={generateCodePlanDialogOpen}
						editThematization={editThematization}
						callbackOnclose={() => setGenerateCodePlanDialogOpen(false)}
					/>
					<EditThematizationDialog
						{...props}
						openState={editThematizationDialogOpen}
						editThematization={editThematization}
						setLoadData={setLoadData}
						callbackOnclose={() => {setEditThematizationDialogOpen(false); setEditThematization(undefined);}}
					/>
					<ThematizationToolbar
						{...props}
						keywords={keywords}
						selected={selectedThematizations}
						countForPagination={countForPagination}
						handleDeletePrompt={handleDeleteThematizations}
						handleKeywordsChange={handleKeywordsChange}
						setLoadData={setLoadData}
						setEditThematizationDialogOpen={setEditThematizationDialogOpen}
						setDeleteThematizationDialogOpen={setDeleteThematizationDialogOpen}
					/>
					<div className={classes.tableWrapper}>
						<Table size="medium" className={classes.table}>
							<ThematizationHead
								classes={classes}
								numSelected={selectedThematizations.length}
								onSelectAllClick={(event) => handleSelectAllClick(event)}
								rowCount={thematizations.length}
								sortBy={sortBy}
								sortDirection={sortDirection}
								handleChangeSort={handleChangeSort}
								t={t}
							/>
							<TableBody>
								{thematizations.map((thematization) => (
									<React.Fragment key={`thematization-fragment-${thematization.id}`}>
										<TableRow
											role="checkbox"
											tabIndex={-1}
											hover
											className={classes.tableRowCursorPointer}
											onClick={(e) => {
												handleOpenCodePlan(e, thematization);
											}}
										>
											<TableCell padding="checkbox">
												<Checkbox
													onClick={(e) => e.stopPropagation()}
													checked={selectedThematizations.includes(thematization.id)}
													onChange={(event) => setSelectedThematizations(prev => event.target.checked
														? [...prev, thematization.id]
														: prev.filter(id => id !== thematization.id)
													)}
												/>
											</TableCell>
											<TableCell
												className={classes.fileList}
												onMouseOver={() => setMouseOverThematization(thematization)}
												onMouseOut={() => setMouseOverThematization(null)}
												onClick={(e) => {
													e.stopPropagation();
													handleRenameFolder(e, thematization);
												}}
											>
												<div className={classes.list}>
													<p>{thematization.name}</p>
                                                        <Tooltip
	                                                        title={t('react.project.tab.filters.rename.tooltip')}
	                                                        sx={{ display: mouseOverThematization === thematization ? 'inline-flex' : 'none' }}
                                                        >
                                                        <IconButton>
	                                                        <EditOutlinedIcon fontSize="small" />
														</IconButton>
														</Tooltip>
												</div>
											</TableCell>
											<TableCell>
												{appendSuffixIfGreaterThan(thematization.description, "...", 30)}
											</TableCell>
											<TableCell>
												{thematization.lang}
											</TableCell>
											<TableCell>
												{thematization.state}
											</TableCell>
											<TableCell>
												{getOwnerName(thematization)}
											</TableCell>
										</TableRow>
									</React.Fragment>
								))}
								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={countForPagination}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage={t('react.list.number.lignes.per.page')}
							labelDisplayedRows={({from, to, count}) => t('react.list.number.from.to.count', {
								'from': from,
								'to': to,
								'count': count
							})}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							getItemAriaLabel={getItemAriaLabel}
						/>
					</div>
				</Paper>)}
		</Grid>
	);
}
