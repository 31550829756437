import React, { useState, useEffect, useContext, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, RadioGroup } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Image from './Image';
import CustomNextButton from './CustomNextButton';
import CustomSlider from './CustomSlider';
import { CollectorContext, CollectorTestContext } from './context';
import TextField from '@mui/material/TextField';
import { isBlank, sleep, stripHtmlTags, stripHtmlTagsPreserveSpaces } from '../../utils/utils';
import { extractMinMaxOfInterval, extractListOfNumerics, calculateWidthCol } from '../../utils/surveysUtils';
import BarTimeout from './BarTimeout';
import { range, random, shuffle } from 'lodash';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import DialogFreeField from './DialogFreeField';
import CollectorsTestRef from './CollectorsTestRef';
import Box from '@mui/material/Box';

const useStyles = makeStyles()(theme => ({
  positionRelative: {
    position: 'relative'
  },
  disabledQuestion: {
    display: 'none',
  },
  helperTextnbExpectedAnswers: {
    marginTop: 10,
    fontStyle: 'italic',
    opacity: '0.5',
    fontSize: '90%'
  },
  marginTop: {
    marginTop: 10
  },
  freeField : {
    paddingTop: '6px'
  },
  flex: {
    display: 'flex'
  },
  flexBaseline: {
    display: 'flex',
    alignItems: 'baseline'
}
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

export default function ProfileQuestion(props) {

  const { classes } = useStyles();

  const {
    t, showSpinner, openSnackbar, block, testMode
  } = props;

  const {
    collectParameter, collectorsService, handleNextBlock, handleQuotaFull,
    handleScreenOut, participantId, imagesOfForm, userHistory, embeddedReplaceDynamicElements
  } = useContext(CollectorContext);

  // get the TEST context
  const _testContext_ = useContext(CollectorTestContext);

  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [profileQuestionImage, setProfileQuestionImage] = useState(undefined);
  const [valueUserResponse, setValueUserResponse] = useState('');
  const [idFreeField, setIdFreeField] = useState(-1);
  const [nbColumnsCata, setNbColumnsCata] = useState(0);
  const [arrayCata, setArrayCata] = useState([[], [], [], [], []]);
  const [optimalCataButtonHeight, setOptimalCataButtonHeight] = useState(50);
  const [hasTimeoutRunning, setHasTimeoutRunning] = useState(false);
  const [openPopupDialogFreeField, setOpenPopupDialogFreeField] = useState(false);
  const [labelAnswerFreeField, setLabelAnswerFreeField] = useState(undefined);
  const [realMinAnswers, setRealMinAnswers] = useState(null);
  const [realMaxAnswers, setRealMaxAnswers] = useState(null);

  const [dataReady, setDataReady] = useState(false);
  const [imageReady, setImageReady] = useState(false);
  const [answersImagesReady, setAnswersImagesReady] = useState(false);
  const [answersImages, setAnswersImages] = useState([]);

  // we use these constants to determine which type of HTML tags to use
  const GroupComponent = block.profile.type === 'single' ? RadioGroup : FormGroup;
  const ControlComponent = block.profile.type === 'single' ? Radio : Checkbox;

  useEffect(() => {
    setDataReady(false);

    if (!block) return;

    // always reset all fields when a new bloc comes
    setSelectedAnswers([]);
    setValueUserResponse('');
    setHasTimeoutRunning(block.timeout > 0);

    // If minExpectedAnswers > total number of answers, we have to change it
    setRealMinAnswers(block.profile.nbMinExpectedAnswers > block.profile.answers.length ? block.profile.answers.length : block.profile.nbMinExpectedAnswers);
    setRealMaxAnswers(block.profile.nbMaxExpectedAnswers > block.profile.answers.length ? block.profile.answers.length : block.profile.nbMaxExpectedAnswers);

    let newIdFreeField = -1;
    block.profile.answers.forEach((answer) => {
        if (answer.freeField) {
          newIdFreeField = answer.id;
        }
      });
    setIdFreeField(newIdFreeField);

    let maxAnswerLength = 0;
    let newArrayCata = [[], [], [], [], []];
    var numberOfColumns = block.profile.nbCatalogColumns;

    if (window.screen.width < 800 && block.profile.nbCatalogColumns > 0) {
      numberOfColumns = 1;
    } 
    
    if (numberOfColumns > 0) {
      newArrayCata = Array.from({ length: numberOfColumns }, () => []);
      for (let i = 0; i < block.profile.answers.length; i++) {
        const answer = block.profile.answers[i];
        const indiceTableauDestination = i % numberOfColumns;
        newArrayCata[indiceTableauDestination].push(answer);

        if (answer.answer.length > maxAnswerLength) {
          maxAnswerLength = answer.answer.length;
        }
      }
    }
    if (window.screen.width < 800) {
      setOptimalCataButtonHeight(maxAnswerLength < 35 ? 35 : maxAnswerLength);
    } else if (numberOfColumns === 5) {
      setOptimalCataButtonHeight(maxAnswerLength < 25 ? 50 : maxAnswerLength * 2);
    } else {
      setOptimalCataButtonHeight(maxAnswerLength < 25 ? 35 : maxAnswerLength * 1.4);
    }
    setArrayCata(newArrayCata);
    setNbColumnsCata(numberOfColumns);

    // --------------------------------------------------------------------------------------------
    // -- IMPORTANT NOTE: This two last controls (automatic & skippable) must be done at the end 
    // -- because in testMode the behavior is like a normal block during tests
    // --------------------------------------------------------------------------------------------

    // if type is "automatic"
    if (block.profile.type === "automatic") {
      // select answers automaticaly
      getAutomaticalySelectedAnswerIds()
        .then(([isAllowed, selectAnswerIds]) => {
          if(!isAllowed) {
            handleScreenOut();
            return;
          }

          if(testMode) {
            // just display selected answers in test mode
            setValueUserResponse('');
            setSelectedAnswers(selectAnswerIds);
            setDataReady(true);
          } else {
            // else, in normal mode, directly save the selection
            saveBlock(selectAnswerIds);
          }
        });

      // don't go further in any case
      return;
    }

    // skip the block if there is nothing to select or if it can be done automaticaly
    if(isBlockSkippable()) {
      // auto select everything available (that's the rule)
      var selectAnswerIds = block.profile.answers.map(a => a.id);

      if(testMode) {
        setValueUserResponse('');
        setSelectedAnswers(selectAnswerIds);
        setDataReady(true);
      } else {
        saveBlock(selectAnswerIds);
      }

      // don't go further in any case
      return;
    }

    // --------------------------------------------------------------------------------------------

    setDataReady(true);
  }, [block]);

  /*
   * This useEffect() is only used to control the display of the block image
   */
  useEffect(() => {
    setImageReady(false);

    if (!block || block.imageId === 0) {
      setProfileQuestionImage(undefined);
      setImageReady(true);
      return;
    }

    let img = imagesOfForm.get(block.imageId);
    if (img !== undefined) {
      setProfileQuestionImage(img);
      setImageReady(true);
      return;
    }

    showSpinner(true);

    collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
      .then(result => {
        imagesOfForm.set(block.imageId, result.url);
        setProfileQuestionImage(result.url);
        setImageReady(true);
        showSpinner(false);
      }).catch(e => {
        setProfileQuestionImage(undefined);
        setImageReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  /*
   * This useEffect() is only used to control the display of answers images
   */
  useEffect(() => {
    setAnswersImagesReady(false);

    var promises = block.profile.answers
      .filter(answer => answer.imageId && answer.imageId > 0)
      .map(answer => collectorsService.getPublicFormFileUrl(collectParameter, answer.imageId));

    if (promises.length === 0) {
      setAnswersImages([]);
      setAnswersImagesReady(true);
      return;
    }

    showSpinner(true);

    Promise.all(promises)
      .then(results => {
        setAnswersImages(results);
        setAnswersImagesReady(true);
        showSpinner(false);
      }).catch(e => {
        setAnswersImages([]);
        setAnswersImagesReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  const isBlockSkippable = () => {
    if(block.profile.answers.length === 0) return true;
    if(block.profile.type === "single" && block.profile.answers.length === 1) return true;
    if(block.profile.type === "multiple" && block.profile.answers.length <= block.profile.nbMinExpectedAnswers) return true;
    return false;
  };

  const isSelected = (answer) => {
    return selectedAnswers.indexOf(answer.id) >= 0;
  };

  const getAutomaticalySelectedAnswerIds = useCallback(async () => {
    var isAllowed = false;
    var selectAnswerIds = [];

    await collectorsService.getParticipantValueAssignmentAtQuestion(participantId, collectParameter, block.id, block.iterationId, block.iterationLoopIndex)
      .then(result => {
        // check that the returned response is available
        if(result.data.answerIds.every(answerId => block.profile.answers.find(answer => answer.id === answerId))) {
          isAllowed = result.data.canParticipate;
          selectAnswerIds = result.data.answerIds;
        }
      }).catch(e => {
        isAllowed = false;
      });

    return [isAllowed, selectAnswerIds];
  }, [block]);

  const saveBlock = async (autoSaveSelectedAnswers = undefined) => {
    showSpinner(true);

    var userResponse = autoSaveSelectedAnswers || [...selectedAnswers];

    if (block.profile.bindFieldEnable) {
      const nValueUserResponse = parseInt(valueUserResponse);

      if(block.profile.bindType === 'interval') {
        block.profile.answers.forEach((answerItem, index) => {
          let intervalValues = extractMinMaxOfInterval(answerItem.answer);
          if (intervalValues !== null) {
              if (nValueUserResponse >= intervalValues[0] && nValueUserResponse <= intervalValues[1]) {
                userResponse.push(answerItem.id);
              }
          }
        });
      } else if (block.profile.bindType === 'list') {
        block.profile.answers.forEach((answerItem, index) => {
          let listValues = extractListOfNumerics(answerItem.answer);
          if (listValues !== null) {
            if (listValues.indexOf(nValueUserResponse) >= 0) {
              userResponse.push(answerItem.id);
            }
          }
        });
      }
    }

    let data = { 
      answers: userResponse, 
      enteredValue: valueUserResponse, 
      iterationId: block.iterationId, 
      iterationLoopIndex: block.iterationLoopIndex 
    };

    // appropriated codes are returned by the following call to API
    await collectorsService.collectQuestionsAnswer(participantId, block.id, data)
      .then(response => {
        setDataReady(false);
        showSpinner(false);
        pushAnswersToUserHistory(data);
        handleNextBlock();
      }).catch(error => {
        setDataReady(false);
        showSpinner(false);
        if (error.response?.status === 302) {
          handleQuotaFull();
        } else if (error.response?.status === 307) {
          // [R3MSCORE-478] if mandatory question are not selected => handleScreenOut the participant
          // can be: a 'isScreenOut' is checked OR no bind answer automaticaly
          handleScreenOut();
        } else {
          openSnackbar('error', t('react.error.save.message'));
          handleScreenOut();
        }
      });
  };

  const pushAnswersToUserHistory = (data) => {
    // append user answers of the question to the user answers list for text replacement
    const answers = data.answers.map(answerId => {
        const answer = block.profile.answers.find(a => a.id === answerId);
        return { id: answer.id, ref: answer.ref, text: answer.answer, freeField: answer.freeField };
      });
    const userAnswerToAdd = { objectRef: block.ref, answers: answers, enteredValue: data.enteredValue};
    const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id && it.iterationId === block.iterationId);
    userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
  };

  /**
   * IMPORTANT NOTE: IT IS IMPORTANT TO UPDATE SUR setAutomaticAnsweringCallback() BECAUSE IF YOU NEED
   * TO ACCESS OTHER STATES OF THE COMPONENT, THE CALLBACK MUST BE UPDATED TOO (EVEN IF IT'S A FUNCTION!)
   */
  useEffect(() => {
    if(_testContext_) {
    // if we are in test mode, we have a testContext - replace the automatic answering system
    _testContext_.setAutomaticAnsweringCallback(() => _test_automaticallyAnswerToQuestion_);
    }
  }, [block, realMinAnswers, realMaxAnswers /* add more states you need to monitor in _test_automaticallyAnswerToQuestion_() */]);

  const _test_automaticallyAnswerToQuestion_ = () => {
    // special case when having a textField to answer
    if(block.profile.bindFieldEnable) {
      // if there is no data - stop
      if(block.profile.answers.length === 0) return;

      let randomUserResponse = undefined;
      let randomAnswer = shuffle(block.profile.answers)[0];

      if(block.profile.bindType === 'interval') {
        let intervalValues = extractMinMaxOfInterval(randomAnswer.answer);
        if (intervalValues !== null) {
          randomUserResponse = shuffle(intervalValues)[0];
        }
      } else if(block.profile.bindType === 'list') {
        let listValues = extractListOfNumerics(randomAnswer.answer);
        if (listValues !== null) {
          randomUserResponse = shuffle(listValues)[0];
        }
      }

      setValueUserResponse(randomUserResponse);
      return;
    }

    let numberOfAnswers = block.profile.type === "multiple"
      ? random(realMinAnswers, realMaxAnswers)
      : 1;

    let answers = shuffle(block.profile.answers)
      .slice(0, numberOfAnswers);

    if(answers.some(a => a.exclusive)) {
      // remove all non-exclusive answers
      answers = answers.filter(a => a.exclusive);
    }

    const answerIds = answers.map(answer => answer.id);
    setSelectedAnswers(answerIds);
  };

  const selectAnswer = (answer, autoSave = false) => {
    let openFreeFieldDialog = false;

    // if exclusive answer, do this code
    if (selectedAnswers.length > 0) {
      var blockSelected = block.profile.answers.find(a => a.id === selectedAnswers[0]);
      if (blockSelected.exclusive && (blockSelected.id !== answer.id)) {
        return;
      }
    }
    let newselectedAnswers = [...selectedAnswers];

    if (block.profile.type === "single") {
      // in this case, just select this new answer id
      newselectedAnswers = [answer.id];

      if(answer.freeField) {
        // do not enable the freefield in slider mode for the moment
        openFreeFieldDialog = (block.profile.itemsStyle !== "slider" && block.profile.itemsStyle !== "rating");
      } else {
        // always clear the enteredValue if it's not the freefield
        setValueUserResponse('');
      }
    } else /* if 'multiple' or 'automatic' it's OK we can pass here */ {
      // check if in the list
      let idxA = newselectedAnswers.indexOf(answer.id);
      if (idxA >= 0) {
        // remove if exists
        newselectedAnswers.splice(idxA, 1);
        if (answer.freeField) setValueUserResponse('');
      } else {
        // else add
        if (answer.exclusive === true) {
          newselectedAnswers = [answer.id];
        } else {
          newselectedAnswers.push(answer.id);
        }
        openFreeFieldDialog = answer.freeField;
      }
    }

    if (idFreeField !== -1 && !newselectedAnswers.includes(idFreeField)) {
      setValueUserResponse('');
    }

    setSelectedAnswers(newselectedAnswers);

    if (openFreeFieldDialog) {
      setLabelAnswerFreeField(answer.answer);
      setOpenPopupDialogFreeField(true);

      // never do the auto-save when the user has to enter a freefield value
      return;
    }

    if (autoSave) {
      // in order to have a smooth behavior, there is a delay of 1s before passing to the next block.
      // it lets the user see its answer (e.g. with slider on mobile)
      sleep(500).then(() => {
        saveBlock(newselectedAnswers);
      });
    }
  };

  const disableExclusive = (answerParam) => {
    let disabled = false;
    selectedAnswers.filter(a => block.profile.answers.forEach(answer => {
      if (answer.id === a && answer.exclusive === true && answerParam.id !== answer.id) {
        disabled = true;
      }
    }));
    return disabled;
  };

  const handleCloseDialogFreeField = (userEnteredValue) => {
    setValueUserResponse(userEnteredValue);

    // close and clean the popup
    setOpenPopupDialogFreeField(false);
    setLabelAnswerFreeField(undefined);
  };

  const isButtonDisabled = () => {
    if(testMode) return false;

    let state = false;

    if (block.profile.bindFieldEnable) {
      state = isBlank(valueUserResponse);
    } else {
      if (block !== undefined && block.profile !== undefined) {
        if (block.profile.type === 'single') {
          // when a question of type Single has no selected answer id, return true
          if (selectedAnswers.length === 0) {
            state = true;
          }
        }
        else if (block.profile.type === "multiple") {
          // when a question of type Multiple has less selected answers than required, return true
          if (realMinAnswers && selectedAnswers.length < realMinAnswers) {
            state = true;
          }
          if (realMaxAnswers && selectedAnswers.length > realMaxAnswers) {
            state = true;
          }
        }
      }
    }
    if (selectedFreeField() && isBlank(valueUserResponse) && nbColumnsCata === 0) {
      state = true;
    }

    return state;
  };

  const changeFieldValueUser = (valueUser) => {
    if (/^$|^\d+$/.test(valueUser)) {
      setValueUserResponse(valueUser);
    }
  };

  const selectedFreeField = () => {
    if (idFreeField !== -1) {
      return selectedAnswers.includes(idFreeField);
    }
    return false;
  };

  const handleBarTimeoutExpired = () => {
    setHasTimeoutRunning(false);
  };

  const getCorrespondingImage = (idP) => {
    if (answersImages.length === 0) {
      return;
    }
    return answersImages.find(img => img.id === idP).url;
  };

  const showNextButton = () => {
    // while the time-out is not expired, do not display button
    if (hasTimeoutRunning) return false;

    if(testMode) return true;

    // do not enable the freefield in slider mode for the moment
    if (block.profile.itemsStyle === "slider" || block.profile.itemsStyle === "rating") return false;

    // always display the next button when there is a freeField
    if (block.profile.answers.findIndex(a => a.freeField) >= 0) return true;

    // if single and mode cata, dont display button
    if (block.profile.type === "single" && nbColumnsCata !== 0) return false;

    // Default behaviour
    return true;
  };

  const handleSelectAnswerCustomSlider = (answer) => {
    selectAnswer(answer, true);
  };

  const helperText = () => {
    var textToReturn = null;
    if (realMinAnswers > 0 && realMinAnswers === realMaxAnswers) {
      textToReturn = t('LBL.PQ.MANDATORY.ANSWERS', {
        numAnswers: realMinAnswers,
      });
    } else if (realMinAnswers > 0 && realMaxAnswers < block.profile.answers.length) {
      textToReturn = t('LBL.PQ.MINIMUM_AND_MAXIMUM.ANSWERS', {
        numMinAnswers: realMinAnswers,
        numMaxAnswers: realMaxAnswers,
      });
    } else if (realMinAnswers > 0) {
      textToReturn = t('LBL.PQ.MINIMUM.ANSWERS', {
        numAnswers: realMinAnswers,
      });
    } else if (realMaxAnswers > 0 && realMaxAnswers < block.profile.answers.length) {
      textToReturn = t('LBL.PQ.MAXIMUM.ANSWERS', {
        numAnswers: realMaxAnswers,
      });
    }
    if (textToReturn !== null) {
      return (<div className={classes.helperTextnbExpectedAnswers}>{textToReturn}</div>);
    } else {
      return null;
    }
  };

  const getUserEnteredValueAt = (answerParam) => {
    // if it's not a freefield or if the answer index doesn't exist or empty
    if (!answerParam.freeField || isBlank(valueUserResponse)) {
      return '';
    }
    return ` (${valueUserResponse})`;
  };

  if(!dataReady || !imageReady || !answersImagesReady) return null;

  return (
    <Container className={classes.positionRelative} style={{display: block.profile.type === "automatic" && !testMode ? "none" : "block"}}>
      <DialogFreeField
        {...props}
        openPopupDialogFreeField={openPopupDialogFreeField}
        setOpenPopupDialogFreeField={setOpenPopupDialogFreeField}
        labelAnswerFreeField={labelAnswerFreeField}
        handleCloseDialogFreeField={handleCloseDialogFreeField}
      />
      <Grid container spacing={6}>
        {profileQuestionImage !== undefined &&
          <Grid item xs={12}>
            <Image
              imageUrl={profileQuestionImage}
              position={block.imagePosition}
              width={block.imageWidth}
              openFullscreen={block.openImageFullscreen}
            />
          </Grid>
        }
        {hasTimeoutRunning && <Grid item xs={12} md={8} className={classes.positionRelative}>
            <BarTimeout
                {...props}
                handleBarTimeoutExpired={handleBarTimeoutExpired}
            />
        </Grid>}
        {!hasTimeoutRunning && <Grid item xs={12} key={`Grid-${block.id}`}>
        {/* use inline stye because the CSS depends on nbColumnsCata */}
        {block.profile.question && <Box style={{display: 'flex', justifyContent: nbColumnsCata !== 0 ? 'center' : 'left', alignItems: 'baseline', marginBottom: nbColumnsCata !== 0 ? 27 : 20}} >
          <CollectorsTestRef {...props} displayType={"typography"} refToDisplay={block.ref}/>
          <span className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.profile.question) }} />
        </Box>}
          {block.profile.bindFieldEnable ? 
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              label={t('LBL.PQ.NUMERIQUE.ANSWER')}
              value={valueUserResponse}
              onChange={(e) => changeFieldValueUser(e.target.value)}
              className={classes.marginTop}
              InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.input,
                }
              }}
            />
            : nbColumnsCata !== 0 ? 
              <div>
                <Grid style={calculateWidthCol(nbColumnsCata)} container spacing={3} columns={nbColumnsCata === 1 ? 3 : nbColumnsCata === 2 ? 6 : nbColumnsCata === 3 ? 9 : nbColumnsCata === 4 ? 12 : 15}>
                  {range(nbColumnsCata).map(indexCol => (
                    <Grid item xs={3} key={indexCol}>
                      {arrayCata[indexCol].length > 0 && arrayCata[indexCol].map((answer) => (
                        <div key={answer.id}>
                          {!block.profile.displayOnlyAnswerImages &&
                            <Button 
                              disabled={disableExclusive(answer)}
                              variant={isSelected(answer) ? "contained" : "outlined"} 
                              style={{width: '100%', textTransform: 'none', height: optimalCataButtonHeight, marginBottom: 13 }} 
                              onClick={() => selectAnswer(answer, block.profile.type === "single")}
                              key={answer.id}
                            >
                              <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                              {embeddedReplaceDynamicElements(answer.answer)}
                              {getUserEnteredValueAt(answer)}
                            </Button>
                          }
                          {answer.imageId > 0 &&
                            <ImageButton
                              onClick={() => selectAnswer(answer, block.profile.type === "single")}
                              focusRipple
                              style={{border: isSelected(answer) ? '2px solid #003945' : '2px solid white', width: '100%', borderRadius: 3, marginTop: block.profile.displayOnlyAnswerImages ? 10 : -13, padding: 2, marginBottom: 10}}
                            >
                              <img src={getCorrespondingImage(answer.imageId)} width={'100%'} title={`${stripHtmlTags(embeddedReplaceDynamicElements(answer.answer))}${getUserEnteredValueAt(answer)}`}/>
                            </ImageButton>
                          }
                        </div>
                      ))}
                    </Grid>
                  ))}
                  {helperText()}
                </Grid>
              </div>
          : (block.profile.itemsStyle === 'slider' || block.profile.itemsStyle === 'rating') ? 
          <CustomSlider 
            {...props}
            identifier={block.profile.id}
            selectAnswer={handleSelectAnswerCustomSlider}
            answers={block.profile.answers.map(a => ({text: a.answer, id: a.id, ref: a.ref}))}
            selectedIndex={selectedAnswers.length > 0 ? block.profile.answers.findIndex(a => a.id === selectedAnswers[0]) : -1}
            style={block.profile.itemsStyle}
          />
          : <GroupComponent key={block.profile.id}>
            {block.profile.answers.map(answer => (
              <div key={answer.id} className={classes.flex}>
                {!block.profile.displayOnlyAnswerImages && <Box className={classes.flex}>
                  <FormControlLabel
                    value={answer.answer}
                    disabled={disableExclusive(answer)}
                    control={<ControlComponent checked={isSelected(answer)} onClick={() => selectAnswer(answer)} />}
                    label={
                      <Box className={classes.flexBaseline}>
                        <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                        {stripHtmlTagsPreserveSpaces(embeddedReplaceDynamicElements(answer.answer))}
                        {getUserEnteredValueAt(answer)}
                      </Box>
                    }/>
                </Box>}
                <div>
                  {answer.imageId > 0 &&
                    <ImageButton
                      onClick={() => selectAnswer(answer)}
                      focusRipple
                      style={{border: isSelected(answer) ? '2px solid #003945' : '2px solid white', width: '300px',borderRadius: 3,marginTop: block.profile.displayOnlyAnswerImages ? 10 : 0, padding: 2}}
                    >
                      <img src={getCorrespondingImage(answer.imageId)} width={'100%'} title={`${stripHtmlTags(embeddedReplaceDynamicElements(answer.answer))}${getUserEnteredValueAt(answer)}`}/>
                    </ImageButton>
                  }
                </div>
              </div>
            ))}
            {helperText()}
          </GroupComponent>}
        </Grid>}
      </Grid>
      {showNextButton() && 
      <CustomNextButton
        {...props}
        disabled={isButtonDisabled()}
        handleNextBlock={() => saveBlock()}
      />
      }
    </Container>
  );
};
