import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

export default function CircularSaveProgress(props) {

    const {
        loading
    } = props;

    const [success, setSuccess] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[500],
            },
            bgcolor: '#ddd !important',
        }),
    };

    useEffect(() => {
        setSuccess(!loading);
    }, [loading]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'fixed', top: 0, right: 0, margin: '10px 10px 0 0', opacity: '40%' }}>
            <Fab
                size='small'
                aria-label="save"
                color="primary"
                sx={buttonSx}
                disabled={true}
            >
                {success ? <CheckIcon /> : <SaveIcon />}
            </Fab>
            {loading && (
                <CircularProgress
                    disableShrink
                    size={46}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -3,
                        left: -3,
                        zIndex: 1,
                    }}
                />
            )}
        </Box>);
}