import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { SurveyContext } from './context';
import { TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { isBlank } from '../../utils/utils';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
    textFieldEditAnswer: {
        marginBottom: "10px",
        marginLeft: "17px",
        marginTop: '15px',
        width: "360px"
    },
    editIcon: {
        marginRight: '10px',
        verticalAlign: 'middle'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 18,
        marginRight: 20,
    },
    labelSwitchQuestion: {
        marginTop: 5,
    },
}));

export default function ParamsQuestionsBatteryOfItems(props) {

    const { classes } = useStyles();

    const { t, uuid, setOpenDialogQuestions, openDialogQuestions, answerIndex } = props;

    const { setNeedToSave, getComponentConfiguration } = useContext(SurveyContext);
    const [valueQuestion, setValueQuestion] = useState('');
    const [valueScreenOut, setValueScreenOut] = useState(false);

    useEffect(() => {
        if (!openDialogQuestions) return;
        setValueQuestion(getComponentConfiguration(uuid).hotspot.answers[answerIndex].name);
        setValueScreenOut(getComponentConfiguration(uuid).hotspot.answers[answerIndex].screenOut);
    }, [openDialogQuestions]);

    const saveParamsAnswer = () => {
        setOpenDialogQuestions(false);
        getComponentConfiguration(uuid).hotspot.answers[answerIndex].name = valueQuestion;
        getComponentConfiguration(uuid).hotspot.answers[answerIndex].screenOut = valueScreenOut;
        setNeedToSave(true);
    };

    if(!openDialogQuestions) return null;

    return (
        <Dialog
            open={openDialogQuestions}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitleWithCloseIcon
                startIcon={<EditIcon color='secondary' className={classes.editIcon} />}
                title={t("react.project.collectforms.params.answer")}
                callbackOnclose={() => setOpenDialogQuestions(false)}
            />
            <DialogContent component="div" className={classes.dialogComponent} >
                <DialogContentText component="div">
                    <TextField
                        value={valueQuestion}
                        multiline={true}
                        onChange={(e) => setValueQuestion(e.target.value)}
                        className={classes.textFieldEditAnswer}
                        label={t('react.project.collectforms.params.edit.answer')}
                        variant="outlined"
                        error={isBlank(valueQuestion)}
                        fullWidth
                    />
                    <div className={classes.flexBetween}>
                        <Typography className={classes.labelSwitchQuestion}>{t("react.project.collectforms.answer.screenout")}</Typography>
                        <FormControlLabel
                            control={<Switch checked={valueScreenOut === true} color="secondary" onChange={() => setValueScreenOut(!valueScreenOut)} />}
                            labelPlacement="start"
                        />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CloseIcon />}
                    onClick={() => setOpenDialogQuestions(false)}
                >
                    {t('react.button.cancel')}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<SaveIcon />}
                    color="primary"
                    disabled={isBlank(valueQuestion)}
                    onClick={() => saveParamsAnswer()}
                >
                    {t('react.button.save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
