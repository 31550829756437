import React, { useState, useContext, useReducer, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { SurveyContext } from './context';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { isBlank } from '../../utils/utils.js';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import QuillEditor from './QuillEditor.js';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ParamsImage from './ParamsImage';
import Button from '@mui/material/Button';
import BlockHeader from './BlockHeader.js';
import TripleStateButton from '../shared/TripleStateButton/TripleStateButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles()(theme => ({
    previewRoot: {
        marginBottom: '25px',
        margin: '0',
        padding: '5px',
        width: `250px`,
        height: `150px`,
        display: 'inline-block',
        borderStyle: 'dotted',
        borderRadius: '7px',
    },
    previewImg: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '140px',
        position: 'relative',
        top: '-50px'
    },
    previewBtns: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        height: '50px',
        position: 'relative', top: '25%', zIndex: '100'
    },
    alignCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '200px'
    },
    paddingPaper: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 1,
        paddingBottom: 30,
    },
    marginBottomTop: {
        marginBottom: 20,
        marginTop: 20,
    },
    formControlSelect: {
        minWidth: '150px'
    },
    gridImage: {
        marginTop: 20,
        marginBottom: 20,
    },
    textFieldContent: {
        marginTop: 40
    },
    flexBetweenTripleStateButton: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '20px',
        marginBottom: '10px'
    },
    boxLabelsAnswers: {
        display: 'flex', 
        alignItems: 'center', 
        gap: '10px',
        paddingLeft: '10px'
    },
    zoneLabels: {
        display: 'flex', 
        flexDirection: "column",
        width: '100%',
    },
    marginTopTypoLabel: {
        marginTop: 2
    },
    textFieldEditLabel: {
        marginTop: -5, 
        marginBottom: -5
    },
    flexBetweenMinAnswers: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    labelSwitchQuestion: {
        marginTop: 10,
    },
    switchSingleOrMultiple: {
        marginRight: -5,
        padding: 0,
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: -5
    },
}));

const defaultLabelKeyByIndex = [
    'react.collectform.openquestion.answer.first', 
    'react.collectform.openquestion.answer.second', 
    'react.collectform.openquestion.answer.third', 
    'react.collectform.openquestion.answer.fourth', 
    'react.collectform.openquestion.answer.fifth'
];

export default function OpenQuestion(props) {

    const { classes } = useStyles();

    const { t, uuid } = props

    const { formIdParameter, generateSetImageButton, generateDeleteImageButton,
        setNeedToSave, getComponentConfiguration, mediaFiles } = useContext(SurveyContext);

    const [uploading, setUploading] = useState(false);
    const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [openDialogImage, setOpenDialogImage] = useState(false);
    const [renameText, setRenameText] = useState(undefined);
    const [mouseOverIndexLabel, setMouseOverIndexLabel] = useState(undefined);
    const [labelIndexCurrentlyEdited, setLabelIndexCurrentlyEdited] = useState(undefined);
    const [activeTimeout, setActiveTimeout] = useState(getComponentConfiguration(uuid).responseTimeoutInSeconds === 0 ? false : true);

    const quillRef = useRef();

    useEffect(() => {
        // initialize labels per zone (usefull for retro-compatibility)
        if (getComponentConfiguration(uuid).openQuestion.zonesLabels.length === 0) {
            for (let i = 0; i < getComponentConfiguration(uuid).openQuestion.nbTextFields; i++) {
                getComponentConfiguration(uuid).openQuestion.zonesLabels.push(t(defaultLabelKeyByIndex[i]));
            }
        }

        setRenameText(undefined);
        setMouseOverIndexLabel(undefined);
        setLabelIndexCurrentlyEdited(undefined);
    }, [uuid])

    const changeField = (fieldName, value) => {
        switch (fieldName) {
            case "ref":
                getComponentConfiguration(uuid).ref = value;
                break;
            case "title":
                getComponentConfiguration(uuid).openQuestion.stimulusName = value;
                break;
            case "question":
                getComponentConfiguration(uuid).openQuestion.question = value;
                break;
            case "enabledBlock":
                getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
                break;
            case "minimumCharacters":
                if (value >= 0) {
                    getComponentConfiguration(uuid).openQuestion.minimumNumberOfCharacters = value;
                }
                break;
            case "minimumCharactersType":
                getComponentConfiguration(uuid).openQuestion.minimumCharactersType = value;
                break;
            case "switchEnableTimer":
                if(activeTimeout) {
                    getComponentConfiguration(uuid).responseTimeoutInSeconds = 0;
                  } else {
                    getComponentConfiguration(uuid).responseTimeoutInSeconds = 30;
                  }
                  setActiveTimeout(!activeTimeout);
                break;
            case "textFieldTimer":
                if (value >= 0) {
                    getComponentConfiguration(uuid).responseTimeoutInSeconds = value;
                } 
                break;
        }
        setNeedToSave(true);
        forceUpdate();
    }

    const handleMouseOver = (value) => {
        setMouseOverIndexLabel(value);
    };

    const handleStartRename = (event, indexLabel) => {
        event.preventDefault();
        setRenameText(getComponentConfiguration(uuid).openQuestion.zonesLabels[indexLabel]);
        setLabelIndexCurrentlyEdited(indexLabel)
    };

    const handleClickAway = (event) => {
        event.preventDefault();
        setRenameText(undefined);
        setLabelIndexCurrentlyEdited(undefined);
    };
    
    const handleValidRename = (event) => {
        event.preventDefault();
        if (labelIndexCurrentlyEdited !== undefined && !isBlank(renameText)) {
            getComponentConfiguration(uuid).openQuestion.zonesLabels[labelIndexCurrentlyEdited] = renameText.trim();
            setRenameText(undefined);
            setLabelIndexCurrentlyEdited(undefined);
            setNeedToSave(true);
            forceUpdate();
        }
    };

    const handleChangeStateMinChar = (newStateValue) => {
        changeField("minimumCharactersType", newStateValue)
    };

    const handleChangeTextFieldNumber = (event) => {
        const newLength = event.target.value;
        const { zonesLabels } = getComponentConfiguration(uuid).openQuestion;
    
        for (let i = 0; i < newLength; i++) {
            if (!zonesLabels[i]) {
                zonesLabels[i] = t(defaultLabelKeyByIndex[i]);
            }
        }
        zonesLabels.length = newLength;
        getComponentConfiguration(uuid).openQuestion.nbTextFields = newLength;
        setNeedToSave(true);
        forceUpdate();
    }

    const handleChangeTextFieldRows = (event) => {
        getComponentConfiguration(uuid).openQuestion.nbTextFieldRows = event.target.value;
        setNeedToSave(true);
        forceUpdate();
    }
    
    const printLabel = (value, indexLabel) => {        
        if (labelIndexCurrentlyEdited === indexLabel) {
            return (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                        <TextField
                            className={classes.textFieldEditLabel}
                            type="text"
                            value={renameText}
                            onChange={(e) => setRenameText(e.target.value)}
                            fullWidth
                            error={isBlank(renameText)}
                            autoFocus
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title={t("react.button.save")}>
                                        <span>
                                            <IconButton
                                                component="span"
                                                onClick={handleValidRename}
                                                disabled={isBlank(renameText)}
                                                size="large"
                                            >
                                                <DoneIcon style={{color: !isBlank(renameText) ? '#66bb6a' : '#eee'}}/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Box>
                </ClickAwayListener>
            );
        }
    
        return (
            <Typography className={classes.marginTopTypoLabel}>
                {value}
                <Tooltip title={t('react.button.rename')}>
                    <IconButton component="span" onClick={(e) => handleStartRename(e, indexLabel)}>
                        <EditOutlinedIcon fontSize="small" sx={{ verticalAlign: 'sub', opacity: mouseOverIndexLabel === indexLabel ? 1 : 0 }} />
                    </IconButton>
                </Tooltip>
            </Typography>
        );
    };

    return (
        <>
        {/* Define some popup and dialog */}
        <ParamsImage
            {...props}
            uuid={uuid}
            openDialogImage={openDialogImage}
            setOpenDialogImage={setOpenDialogImage}
            enableFullScreenMode={false}
        />
        {/* Header of the block */}
        <BlockHeader
            {...props}
            blockRef={getComponentConfiguration(uuid).ref}
            setBlockRef={(newValue) => changeField('ref', newValue)}
            isActive={getComponentConfiguration(uuid).active}
            setIsActive={(newValue) => changeField('enabledBlock', newValue)}
            title={t("react.project.collectforms.block.openQuestion")}
        />
        <Paper className={classes.paddingPaper}>
            <Grid item xs={3} xm={4} className={classes.gridImage}>
                <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                    onMouseOver={e => setUploadButtonVisibility(true)}
                    onMouseOut={e => setUploadButtonVisibility(false)}
                >
                    {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                        <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
                            {!uploading && generateSetImageButton(uuid, setUploading)}
                            {!uploading && generateDeleteImageButton(uuid)}
                            {uploading && <CircularProgress />}
                        </div>
                    </div>}
                    {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
                        <img
                            id={`img_${uuid}`}
                            className={classes.previewImg}
                            src={mediaFiles[getComponentConfiguration(uuid).imageId]}
                            style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                    </div>}
                </div>
            </Grid>
            {getComponentConfiguration(uuid).imageId > 0 &&
                <Button style={{marginBottom: 20}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
                    {t("react.surveys.button.params.image")}
                </Button>
            }
            <TextField
                error={isBlank(getComponentConfiguration(uuid).openQuestion.stimulusName)}
                label={`${t("react.project.collectforms.block.stimulusname")} (${(getComponentConfiguration(uuid).openQuestion.stimulusName || '').length} / 255)`}
                fullWidth
                value={getComponentConfiguration(uuid).openQuestion.stimulusName}
                onChange={e => changeField('title', e.target.value)}
                inputProps={{ maxLength: 255 }}
            ></TextField>
            <QuillEditor
                ref={quillRef}
                value={getComponentConfiguration(uuid).openQuestion.question}
                onChange={(value) => changeField('question', value)}
                isError={isBlankHtml(getComponentConfiguration(uuid).openQuestion.question)}
                style={{height: 400, marginTop: 30, paddingBottom: 42}}
                placeholder= {t("react.project.collectforms.openquestioncontent")}
                {...props}
            />
            <Grid container className={classes.gridImage}>
                <Grid item xs={4}>
                    <FormControl className={classes.formControlSelect}>
                        <TextField
                            select
                            value={getComponentConfiguration(uuid).openQuestion.nbTextFields}
                            onChange={handleChangeTextFieldNumber}
                            label={t("react.project.collectforms.numberTextFields")}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.formControlSelect}>
                        <TextField
                            select
                            value={getComponentConfiguration(uuid).openQuestion.nbTextFieldRows}
                            onChange={handleChangeTextFieldRows}
                            label={t("react.project.collectforms.numberTextFieldRows")}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{ position: 'relative' }}>
                    <TextField
                        value={getComponentConfiguration(uuid).openQuestion.minimumNumberOfCharacters}
                        onChange={e => changeField('minimumCharacters', e.target.value)}
                        label={t("react.project.collectforms.minimumNumberOfCharacters")}
                        type='number'
                        className={classes.formControlSelect}
                    />
                </Grid>
            </Grid>
            <Box className={classes.flexBetweenTripleStateButton}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.openQuestion.nbMinChar.state')}</Typography>
                <TripleStateButton
                    disabled={getComponentConfiguration(uuid).openQuestion.minimumNumberOfCharacters <= 0}
                    stateValues={['total', 'eachZone', 'firstZone']}
                    stateLabels={[t('react.surveys.openQuestion.nbMinCharState.total'), t('react.surveys.openQuestion.nbMinCharState.eachZone'), t('react.surveys.openQuestion.nbMinCharState.firstZone')]}
                    stateValue={getComponentConfiguration(uuid).openQuestion.minimumCharactersType}
                    setStateValue={handleChangeStateMinChar}
                    className={classes.tripleStateButton}
                    style = {{height: '25px', marginTop: '10px'}}
                />
            </Box>
            <Box className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.switch.timer')}</Typography>
                <FormControlLabel
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={activeTimeout} color="secondary" onChange={e => changeField('switchEnableTimer')} />}
                    labelPlacement="start"
                />
            </Box>
            {getComponentConfiguration(uuid).responseTimeoutInSeconds !== 0 &&
                <Box className={classes.flexBetweenMinAnswers} >
                    <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.textField.timer')}</Typography>
                    <TextField
                        value={getComponentConfiguration(uuid).responseTimeoutInSeconds}
                        onChange={(e) => changeField("textFieldTimer", e.target.value)}
                        type='number'
                        style={{ width: 60 }}
                    />
                </Box>
            }
            <Box className={classes.zoneLabels}>
                <Divider style={{margin: '10px 0 15px 0', width: '100%'}}/>
                <Typography variant="subtitle2">{t("react.surveys.openQuestion.labels")}</Typography>
                {getComponentConfiguration(uuid).openQuestion.zonesLabels.map((value, index) => (
                    <Box key={`label-${index}`} className={classes.boxLabelsAnswers}
                        onMouseOver={e => handleMouseOver(index)} 
                        onMouseOut={e => handleMouseOver(undefined)}
                    >
                        <Typography>{t('react.surveys.openQuestion.label.field', { field: index+1 })}</Typography> {printLabel(value, index)}
                    </Box>
                ))}
            </Box>
        </Paper>
    </>
    );
}
