import React, { useState, useContext, useReducer, useEffect, useRef, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { SurveyContext } from './context';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import ParamsQuestions from './ParamsQuestions';
import ParamsImage from './ParamsImage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import RedoIcon from '@mui/icons-material/Redo';
import { PinIcon } from '../../common.js';
import { isBlank } from '../../utils/utils';
import { extractMinMaxOfInterval, extractListOfNumerics, isBlankHtml, computeNextElementRef } from '../../utils/surveysUtils';
import GradeIcon from '@mui/icons-material/Grade';
import SubjectIcon from '@mui/icons-material/Subject';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import CheckIcon from '@mui/icons-material/Check';
import { Trans } from 'react-i18next';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import InfoIcon from '@mui/icons-material/Info';
import AnswerGeneratorDialog from './AnswerGeneratorDialog';
import ImageIcon from '@mui/icons-material/Image';
import QuillEditor from './QuillEditor.js';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import BlockHeader from './BlockHeader.js';
import ButtonGroup from '@mui/material/ButtonGroup';
import SurveysService from '../../services/SurveysService';

const useStyles = makeStyles()(theme => ({
    previewRoot: {
        marginBottom: '25px',
        margin: '0',
        padding: '5px',
        width: `250px`,
        height: `150px`,
        display: 'inline-block',
        borderStyle: 'dotted',
        borderRadius: '7px',
    },
    previewImg: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '140px',
        position: 'relative',
        top: '-50px'
    },
    previewBtns: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        height: '50px',
        position: 'relative', top: '25%', zIndex: '100'
    },
    alignCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '200px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    flexBetweenTripleStateButton: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    flexBetweenMinAnswers: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    heightDivAnswers: {
        minHeight: '140px',
    },
    addNewAnswer: {
        paddingLeft: '5px',
        display: 'flex',
    },
    addNewAnswerButton: {
        right: -15,
        marginTop: '12px'
    },
    marginResponses: {
        marginTop: 10,
    },
    fullWidth: {
        margin: '0',
        padding: '0',
        width: '100%',
        height: '100px',
        display: 'table',
    },
    paddingPaper: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 1,
        paddingBottom: 30,
    },
    listSubheader: {
        marginTop: 15,
        marginBottom: 10,
        marginRight: 5,
        background: '#eee',
        padding: '3px'        
    },
    listItem: {
        paddingLeft: 5,
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 14,
    },
    listItemSecondaryAction: {
        margin: 0,
        padding: 0,
        right: 2,
    },
    switchSingleOrMultiple: {
        marginRight: -5,
        padding: 0,
    },
    quotaValue: {
        fontSize: '0.9em',
    },
    labelSwitchQuestion: {
        marginTop: 10,
    },
    iconsAnswerPosition: {
        display: 'flex',
        marginRight: -12,
    },
    IconButtonMinMax: {
        minWidth: '30px',
        maxWidth: '30px',
    },
    formControlLabel: {
        marginLeft: 0,
        marginTop: 0,
    },
    dialogComponent: {
        minHeight: '400px',
        height: 400, 
        width: 500
    },
    scale09: {
        transform: 'scale(0.9)',
    },
    gridImage: {
        marginTop: 20,
        marginBottom: 20,
    },
    textFieldContent: {
        marginTop: 40
    },
    infoIconHelp: {
        top: '8px',
        left: '5px',
        opacity: '0.8',
        transform: 'scale(0.8)',
    },
    answerRef: {
        color: "#BBB",
        fontStyle: "italic",
        width: '30px',
        marginLeft: 20,
    },
    buttonGroupAnswerSameOrderAs: {
        height: 25, 
        marginTop: 5
    },
    flex: {
        display: 'flex'
    },
}));

const surveysService = new SurveysService();

export default function ProfileQuestion(props) {

    const { classes } = useStyles();

    const { t, uuid } = props;

    const { formIdParameter, generateSetImageButton, generateDeleteImageButton, getFormConfiguration, notifyStructuralChange,
        mediaFiles, setNeedToSave, getComponentConfiguration, flattenBlocksForm, getWrongBindIntervals, getWrongBindLists } = useContext(SurveyContext);

    const [uploading, setUploading] = useState(false);
    const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
    const [newAnswer, setNewAnswer] = useState(''); // contenu de la question en dessous des réponses, n'est pas envoyé dans le json
    const [openDialogQuestions, setOpenDialogQuestions] = useState(false);
    const [openDialogImage, setOpenDialogImage] = useState(false);
    const [answerIndex, setAnswerIndex] = useState(-1);
    const [openPopupGenerator, setOpenPopupGenerator] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [previousQuestionType , setPreviousQuestionType ] = useState('single');
    const [activeTempo, setActiveTempo] = useState(getComponentConfiguration(uuid).timeout === 0 ? false : true);

    const [enableSortByRef, setEnableSortByRef ] = useState(false);
    const [refUsedToSortAnswers, setRefUsedToSortAnswers ] = useState('');

    const quillRef = useRef();

    useEffect(() => {
        let enableSortByRef = false;
        let refUsedToSortAnswers = '';
        if(getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid) {
            // check if the referenced block is still available
            let blockUsedToSortAnswers = findBlockByRefOrUuidAndUsableToSortElements(getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid);
            if(!blockUsedToSortAnswers) {
                getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid = undefined;
                getComponentConfiguration(uuid).question.orderAnswersLikeBlockField = 'answers';
                setNeedToSave(true);
            }
            enableSortByRef = blockUsedToSortAnswers !== undefined;
            refUsedToSortAnswers = blockUsedToSortAnswers ? blockUsedToSortAnswers.configuration.ref : '';
        }
        setEnableSortByRef(enableSortByRef);
        setRefUsedToSortAnswers(refUsedToSortAnswers);
    }, [uuid]);

    useEffect(() => {
        if (getComponentConfiguration(uuid).imageId === 0) {
            getComponentConfiguration(uuid).timeout = 0;
            setActiveTempo(false);
        }
    }, [getComponentConfiguration(uuid).imageId]);

    const changeField = (fieldName, value) => {
        switch (fieldName) {
            case "ref":
                getComponentConfiguration(uuid).ref = value;
                break;
            case "enabledBlock":
                getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
                break;
            case "title":
                getComponentConfiguration(uuid).question.filter = value;
                break;
            case "question":
                getComponentConfiguration(uuid).question.question = value;
                break;
            case "type":
                if (getComponentConfiguration(uuid).question.type === 'multiple') {
                    getComponentConfiguration(uuid).question.type = 'single';
                    getComponentConfiguration(uuid).question.nbMinExpectedAnswers = getComponentConfiguration(uuid).question.answers.length > 0 ? 1 : 0;
                    getComponentConfiguration(uuid).question.nbMaxExpectedAnswers = getComponentConfiguration(uuid).question.answers.length;
                    getComponentConfiguration(uuid).question.answers.forEach(a => a.exclusive = false);
                } else if (getComponentConfiguration(uuid).question.type === 'single') {
                    getComponentConfiguration(uuid).question.type = 'multiple';
                    getComponentConfiguration(uuid).question.nbMinExpectedAnswers = getComponentConfiguration(uuid).question.answers.length > 0 ? 1 : 0;
                    getComponentConfiguration(uuid).question.nbMaxExpectedAnswers = getComponentConfiguration(uuid).question.answers.length;
                    if (getComponentConfiguration(uuid).question.itemsStyle === "slider" || getComponentConfiguration(uuid).question.itemsStyle === "rating") {
                        getComponentConfiguration(uuid).question.itemsStyle = 'classic';
                    }
                }
                break;
            case "nbMinExpectedAnswers":
                if (value >= 0 && value <= getComponentConfiguration(uuid).question.answers.length && value <= getComponentConfiguration(uuid).question.nbMaxExpectedAnswers) {
                    getComponentConfiguration(uuid).question.nbMinExpectedAnswers = value;
                }
                break;
            case "nbMaxExpectedAnswers":
                if (value >= 0 && value <= getComponentConfiguration(uuid).question.answers.length && value >= getComponentConfiguration(uuid).question.nbMinExpectedAnswers) {
                    getComponentConfiguration(uuid).question.nbMaxExpectedAnswers = value;
                }
                break;
            case "shuffle":
                getComponentConfiguration(uuid).question.shuffle = !getComponentConfiguration(uuid).question.shuffle;
                if (getComponentConfiguration(uuid).question.shuffle) {
                    getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid = undefined;
                    setEnableSortByRef(false);
                    setRefUsedToSortAnswers('');
                }
                break;
            case "enableSortByRef":
                let newStatus = !enableSortByRef;
                if(!newStatus) {
                    setRefUsedToSortAnswers('');
                    getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid = undefined;
                    getComponentConfiguration(uuid).question.orderAnswersLikeBlockField = 'answers';
                } else {
                    getComponentConfiguration(uuid).question.shuffle = false;
                }
                setEnableSortByRef(newStatus);
                break;
            case "orderAnswersLikeBlockUuid":
                getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid = value;
                break;
            case "orderAnswersLikeBlockField":
                getComponentConfiguration(uuid).question.orderAnswersLikeBlockField = value;
                break;
            case "bindFieldEnable":
                getComponentConfiguration(uuid).question.bindFieldEnable = !getComponentConfiguration(uuid).question.bindFieldEnable;
                if (getComponentConfiguration(uuid).question.bindFieldEnable) {
                    getComponentConfiguration(uuid).question.itemsStyle = 'classic';
                }
                break;
            case "enabledTemporisation":
                if(activeTempo) {
                    // Tempo is going to be desactivated
                    getComponentConfiguration(uuid).timeout = 0;
                } else {
                    // else Tempo is going to be activated => default timeout of 3
                    getComponentConfiguration(uuid).timeout = 3;
                }
                setActiveTempo(!activeTempo);
                break;
            case "temporisationDelay":
                if (value >= 0) {
                    getComponentConfiguration(uuid).timeout = value;
                }
                break;
            case "bindType":
                getComponentConfiguration(uuid).question.bindType = value;
                break;
            case "switchExcludeFromPairing":
                getComponentConfiguration(uuid).question.excludeFromPairing = !getComponentConfiguration(uuid).question.excludeFromPairing;
                break;
            case "switchDisplayOnlyImages":
                getComponentConfiguration(uuid).question.displayOnlyAnswerImages = !getComponentConfiguration(uuid).question.displayOnlyAnswerImages;
                break;
            case "cata":
                if (value >= 1 && value <= 5) {
                    getComponentConfiguration(uuid).question.nbCatalogColumns = value;
                }
                break;
            case "switchAutomatic":
                if (getComponentConfiguration(uuid).question.type !== "automatic") {
                    setPreviousQuestionType(getComponentConfiguration(uuid).question.type);
                    getComponentConfiguration(uuid).question.type = "automatic";
                } else {
                    getComponentConfiguration(uuid).question.type = previousQuestionType ;
                }
                break;
            case "answers":
                const found = getComponentConfiguration(uuid).question.answers.find(obj => obj.answer === newAnswer);
                if (newAnswer !== undefined && !isBlank(newAnswer) && found === undefined) {
                    if (getComponentConfiguration(uuid).question.nbMaxExpectedAnswers === getComponentConfiguration(uuid).question.answers.length) {
                        getComponentConfiguration(uuid).question.nbMaxExpectedAnswers += 1;
                    }
                    getComponentConfiguration(uuid).question.answers.push({
                        answer: newAnswer,
                        quota: 0,
                        screenOut: false,
                        fixedPosition: false,
                        exclusive: false,
                        uuid: uuidv4(),
                        ref: getNextAnswerRef()
                    });
                    setNewAnswer('');
                }
                break;
            case "itemsStyle":
                if (value === 'classic') {
                    getComponentConfiguration(uuid).question.nbCatalogColumns = 0;
                } else if (value === 'cata') {
                    getComponentConfiguration(uuid).question.nbCatalogColumns = 1;
                } else if (value === 'slider' || value === 'rating') {
                    getComponentConfiguration(uuid).question.nbCatalogColumns = 0;
                    getComponentConfiguration(uuid).question.type = "single";
                    getComponentConfiguration(uuid).question.shuffle = false;
                    getComponentConfiguration(uuid).question.bindFieldEnable = false;
                    getComponentConfiguration(uuid).question.displayOnlyAnswerImages = false;
                }
                getComponentConfiguration(uuid).question.itemsStyle = value;
                break;
            case "multipleQuestionsMode":
                getComponentConfiguration(uuid).question.multipleQuestionsMode = value;
                break;
        }
        setNeedToSave(true);
        forceUpdate();
    };

    const getNextAnswerRef = () => {
        return computeNextElementRef(getComponentConfiguration(uuid).question.answers, 'A');
    };

    const findBlockByRefOrUuidAndUsableToSortElements = (refOrUuid) => {
        return surveysService.findBlockByRefOrUuidAndUsableToSortElements(refOrUuid, flattenBlocksForm, uuid);
    };

    const blockUsedAsRefToSortAnswers = useMemo(() => {
        return findBlockByRefOrUuidAndUsableToSortElements(getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid);
    }, [uuid, getComponentConfiguration(uuid).question.orderAnswersLikeBlockUuid]);

    const handleChangeRefUsedToSort = (value) => {
        setRefUsedToSortAnswers(value);
        let b = findBlockByRefOrUuidAndUsableToSortElements(value);
        changeField("orderAnswersLikeBlockUuid", b ? b.uuid : undefined);
    };

    const deleteAnswer = () => {
        getComponentConfiguration(uuid).question.answers.splice(answerIndex, 1);
        setAnswerIndex(-1);
        if (getComponentConfiguration(uuid).question.nbMinExpectedAnswers > getComponentConfiguration(uuid).question.answers.length) {
            getComponentConfiguration(uuid).question.nbMinExpectedAnswers = getComponentConfiguration(uuid).question.answers.length;
        }
        if (getComponentConfiguration(uuid).question.nbMaxExpectedAnswers > getComponentConfiguration(uuid).question.answers.length) {
            getComponentConfiguration(uuid).question.nbMaxExpectedAnswers = getComponentConfiguration(uuid).question.answers.length;
        }
        forceUpdate();
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const accessParamsQuestion = () => {
        setOpenDialogQuestions(true);
    };

    const selectAnswer = (subindex) => {
        setAnswerIndex(subindex);
    };

    const moveAnswerUpward = (event) => {
        event.preventDefault();
        let newIndex = answerIndex - 1;
        let thisItem = getComponentConfiguration(uuid).question.answers[newIndex + 1];
        getComponentConfiguration(uuid).question.answers.splice(answerIndex, 1);
        getComponentConfiguration(uuid).question.answers.splice(newIndex, 0, thisItem);
        setNeedToSave(true);
        setAnswerIndex(answerIndex - 1);
    };

    const moveAnswerDownward = (event) => {
        event.preventDefault();
        let newIndex = answerIndex + 1;
        let thisItem = getComponentConfiguration(uuid).question.answers[newIndex - 1];
        getComponentConfiguration(uuid).question.answers.splice(answerIndex, 1);
        getComponentConfiguration(uuid).question.answers.splice(newIndex, 0, thisItem);
        setNeedToSave(true);
        setAnswerIndex(answerIndex + 1);
    };

    const canIMoveUp = () => {
        if (answerIndex === -1 || answerIndex - 1 < 0) {
            return false;
        } else {
            return true;
        }
    };

    const canIMoveDown = () => {
        if (answerIndex === -1 || answerIndex === getComponentConfiguration(uuid).question.answers.length - 1) {
            return false;
        } else {
            return true;
        }
    };

    const sortAnswers = () => {
        getComponentConfiguration(uuid).question.answers.sort((a, b) => ("" + a.answer).localeCompare(b.answer, undefined, { numeric: true }));
        setNeedToSave(true);
        forceUpdate();
    };

    const disabledBindField = () => {
        if(getComponentConfiguration(uuid).question.itemsStyle === 'slider' || getComponentConfiguration(uuid).question.itemsStyle === 'rating') {
            return true;
        }

        if (getComponentConfiguration(uuid).question.type === "multiple") {
            return true;
        }
        if (getComponentConfiguration(uuid).question.shuffle) {
            return true;
        }
        if (enableSortByRef) {
            return true;
        }
        if(getComponentConfiguration(uuid).question.answers.some(a => a.freeField)) {
            return true;
        }
        return false;
    };

    const getDisplayVisibility = () => {
        return getComponentConfiguration(uuid).question.type === "automatic" ? "none" : "";
    };

    const onSaveAnswerGeneratorCallback = () => {
        setOpenPopupGenerator(false);
        forceUpdate();
        setNeedToSave(true);
        notifyStructuralChange();
    };

    var wrongBindIntervals = getWrongBindIntervals(getComponentConfiguration(uuid).question);
    var wrongBindLists = getWrongBindLists(getComponentConfiguration(uuid).question);

    const getAnswerContentValidityMark = (answer, answerIndex) => {
        // if it's not a bind field, do no control content => NO SPECIAL MARK
        if(!getComponentConfiguration(uuid).question.bindFieldEnable) return null;

        let color = "error";
        let title = undefined;

        if(getComponentConfiguration(uuid).question.bindType === 'interval') {
            title = t("react.project.collectforms.bindinterval.invalid.tooltip");

            if(wrongBindIntervals.indexOf(answerIndex) === -1 /* it's a valid interval */) {
                // extract values from the interval
                const minMax = extractMinMaxOfInterval(answer.answer);
                title = <Trans i18nKey={"react.project.collectforms.bindinterval.valid.tooltip"} values={{from: minMax[0], to: minMax[1]}} />;
                color = "success";
            }
        } else if (getComponentConfiguration(uuid).question.bindType === 'list') {
            title = t("react.project.collectforms.bindlist.invalid.tooltip");

            if(wrongBindLists.indexOf(answerIndex) === -1 /* it's a valid list of values */) {
                // extract values from the list
                const values = extractListOfNumerics(answer.answer);
                title = <Trans i18nKey={"react.project.collectforms.bindlist.valid.tooltip"} values={{values: values}} />;
                color = "success";
            }
        }

        if(!title) return null;

        return (
            <div>    
                <Tooltip title={title}>
                    <IconButton className={classes.IconButtonMinMax} size='small'>
                        <CheckIcon color={color} fontSize='small' style={{height: 18}}/>
                    </IconButton>
                </Tooltip> 
            </div>
        );
    };

    const hasBindFieldEnable = () => {
        return getComponentConfiguration(uuid).question.bindFieldEnable;
    };

    return (
        <>
        {/* Define some popup and dialog */}
        <AnswerGeneratorDialog {...props} classes={classes} 
            openPopupGenerator={openPopupGenerator} setOpenPopupGenerator={setOpenPopupGenerator}
            onSaveAnswerGeneratorCallback={onSaveAnswerGeneratorCallback}
            syntaxStructure="question"
            getNextAnswerRef={getNextAnswerRef}
        />
        <ParamsImage
            {...props}
            uuid={uuid}
            openDialogImage={openDialogImage}
            setOpenDialogImage={setOpenDialogImage}
            enableFullScreenMode={false}
        />
        {answerIndex !== -1 &&
            <ParamsQuestions
                {...props}
                uuid={uuid}
                answerIndex={answerIndex}
                openDialogQuestions={openDialogQuestions}
                setOpenDialogQuestions={setOpenDialogQuestions}
            />
        }
        {/* Header of the block */}
        <BlockHeader
            {...props}
            blockRef={getComponentConfiguration(uuid).ref}
            setBlockRef={(newValue) => changeField('ref', newValue)}
            isActive={getComponentConfiguration(uuid).active}
            setIsActive={(newValue) => changeField('enabledBlock', newValue)}
            title={t("react.project.collectforms.block.question.title")}
        />
        <Paper className={classes.paddingPaper}>
            <div>
                <FormControlLabel
                    className={classes.formControlLabel}
                    control={<Switch checked={getComponentConfiguration(uuid).question.excludeFromPairing} color="secondary" onChange={e => changeField('switchExcludeFromPairing', e.target.value)} />}
                    label={t("react.project.collectforms.excludePairing")}
                    labelPlacement="start"
                />
            </div>
            <div>
                <FormControlLabel
                    className={classes.formControlLabel}
                    control={<Switch checked={getComponentConfiguration(uuid).question.type === "automatic"} color="secondary" onChange={e => changeField('switchAutomatic', e.target.value)} />}
                    label={<>{t("react.project.collectforms.automatic")}
                        <Tooltip title={t("react.project.collectforms.automatic.help")} placement="right-start" >
                            <InfoIcon className={classes.infoIconHelp} />
                        </Tooltip>
                    </>}
                    labelPlacement="start"
                />
            </div>
            <Grid item xs={3} xm={4} className={classes.gridImage} style={{display: getDisplayVisibility()}}>
                <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                    onMouseOver={e => setUploadButtonVisibility(true)}
                    onMouseOut={e => setUploadButtonVisibility(false)}
                >
                    {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                        <div style={{ display: (formIdParameter > 0 && (uploading || true === true || uploadButtonVisibility)) ? 'block' : 'none' }}>
                            <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
                                {!uploading && generateSetImageButton(uuid, setUploading)}
                                {!uploading && generateDeleteImageButton(uuid)}
                                {uploading && <CircularProgress />}
                            </div>
                        </div>
                    </div>}
                    {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
                        <img
                            id={`img_${uuid}`}
                            className={classes.previewImg}
                            src={mediaFiles[getComponentConfiguration(uuid).imageId]}
                            style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                    </div>}
                </div>
            </Grid>
            {getComponentConfiguration(uuid).imageId > 0 &&
                <Button style={{marginBottom: 20, display: getDisplayVisibility()}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
                    {t("react.surveys.button.params.image")}
                </Button>
            }
            <Grid item md={12}>
                <TextField
                    style={{marginTop: getComponentConfiguration(uuid).question.type === "automatic" ? "10px" : ""}}
                    error={isBlank(getComponentConfiguration(uuid).question.filter)}
                    label={getComponentConfiguration(uuid).question.type === "automatic" ? 
                    `${t("react.project.collectforms.block.filter.automatic")} (${(getComponentConfiguration(uuid).question.filter || '').length} / 255)` : 
                    `${t("react.project.collectforms.block.filter")} (${(getComponentConfiguration(uuid).question.filter || '').length} / 255)`
                    }
                    fullWidth
                    value={getComponentConfiguration(uuid).question.filter}
                    onChange={e => changeField("title", e.target.value)}
                    inputProps={{ maxLength: 255 }}
                ></TextField>
                 <QuillEditor
                    ref={quillRef}
                    value={getComponentConfiguration(uuid).question.question}
                    onChange={(value) => changeField('question', value)}
                    isError={isBlankHtml(getComponentConfiguration(uuid).question.question)}
                    style={{height: 300, marginTop: 30, paddingBottom: 42, display: getDisplayVisibility()}}
                    placeholder= {t("react.project.collectforms.block.question")}
                    {...props}
                />
                <div className={classes.heightDivAnswers}>
                    <div className={classes.marginResponses}>
                        <div className={classes.flexBetweenTripleStateButton} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.project.surveys.mode')}</Typography>
                            <FormControl style={{width: 200}}>
                                <Select
                                    value={getComponentConfiguration(uuid).question.itemsStyle}
                                    label="Mode"
                                    onChange={e => changeField('itemsStyle', e.target.value)}
                                >
                                    <MenuItem value={'classic'}>{t('react.surveys.mode.classic')}</MenuItem>
                                    <MenuItem value={'cata'}>{t('react.surveys.mode.cata')}</MenuItem>
                                    <MenuItem value={'slider'}>{t('react.surveys.mode.slider')}</MenuItem>
                                    <MenuItem value={'rating'}>{t('react.surveys.mode.rating')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {getComponentConfiguration(uuid).question.nbCatalogColumns !== 0 && 
                            <div className={classes.flexBetweenMinAnswers} style={{display: getDisplayVisibility()}}>
                                <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.cata.texthelper.columns')}</Typography>
                                <TextField
                                    value={getComponentConfiguration(uuid).question.nbCatalogColumns}
                                    onChange={(e) => changeField("cata", e.target.value)}
                                    type='number'
                                    style={{ width: 40 }}
                                />
                            </div>
                        }
                        <div className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}><Trans i18nKey={'react.project.collectforms.switch.bindFieldEnable.answers'} /></Typography>
                            <FormControlLabel
                                disabled={disabledBindField()}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={getComponentConfiguration(uuid).question.bindFieldEnable} color="secondary" onChange={e => changeField('bindFieldEnable', e.target.value)} />}
                                labelPlacement="start"
                            />
                        </div>
                        {getComponentConfiguration(uuid).question.bindFieldEnable && 
                            <div className={classes.flexBetween} style={{paddingTop: 10, display: getDisplayVisibility()}}>
                                <TextField
                                    select
                                    label={t("react.project.collectforms.block.question.bind.type")}
                                    value={getComponentConfiguration(uuid).question.bindType}
                                    onChange={(e) => changeField("bindType", e.target.value)}
                                    autoComplete="off"
                                >
                                    <MenuItem value="interval">{t("react.project.collectforms.block.question.bind.type.interval")}</MenuItem>
                                    <MenuItem value="list">{t("react.project.collectforms.block.question.bind.type.list")}</MenuItem>
                                </TextField>
                            </div>
                        }
                        <div className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.temporisation')}</Typography>
                            <FormControlLabel
                                className={classes.switchSingleOrMultiple}
                                control={<Switch disabled={getComponentConfiguration(uuid).imageId === 0} checked={activeTempo} color="secondary" onChange={e => changeField('enabledTemporisation', e.target.value)}/>}
                                labelPlacement="start"
                            />
                        </div>
                        {getComponentConfiguration(uuid).timeout !== 0 &&
                            <div className={classes.flexBetweenMinAnswers} style={{display: getDisplayVisibility()}}>
                                <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.timeout')}</Typography>
                                <TextField
                                    value={getComponentConfiguration(uuid).timeout}
                                    onChange={e => changeField('temporisationDelay', e.target.value)}
                                    type='number'
                                    style={{ width: 40 }}
                                />
                            </div>
                        }
                        <div className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}><Trans i18nKey={'react.project.collectforms.switch.displayOnlyImage'} /></Typography>
                            <FormControlLabel
                                disabled={getComponentConfiguration(uuid).question.itemsStyle === 'slider' || getComponentConfiguration(uuid).question.itemsStyle === 'rating'}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={getComponentConfiguration(uuid).question.displayOnlyAnswerImages} color="secondary" onChange={e => changeField('switchDisplayOnlyImages')} />}
                                labelPlacement="start"
                            />
                        </div>
                        <Divider style={{paddingTop: '20px', paddingBottom: '10px', display: getDisplayVisibility()}} textAlign="left"><Typography>{t('react.surveys.battery.configure.answers')}</Typography></Divider>
                        <div className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.switch.singleOrMultiple')}</Typography>
                            <FormControlLabel
                                disabled={getComponentConfiguration(uuid).question.itemsStyle === 'slider' || getComponentConfiguration(uuid).question.itemsStyle === 'rating' || getComponentConfiguration(uuid).question.bindFieldEnable}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={getComponentConfiguration(uuid).question.type === "multiple"} color="secondary" onChange={() => changeField('type')} />}
                                labelPlacement="start"
                            />
                        </div>
                        {getComponentConfiguration(uuid).question.type !== "single" &&
                            <>
                                <div className={classes.flexBetweenMinAnswers} >
                                    <div className={classes.labelSwitchQuestion}>{t('react.project.collectforms.minimum.answers.number')}</div>
                                    <TextField
                                        value={getComponentConfiguration(uuid).question.nbMinExpectedAnswers}
                                        onChange={(e) => changeField("nbMinExpectedAnswers", e.target.value)}
                                        type='number'
                                        style={{ width: 40 }}
                                    />
                                </div>
                                <div className={classes.flexBetweenMinAnswers} >
                                    <div className={classes.labelSwitchQuestion}>{t('react.surveys.maximum.answers.number')}</div>
                                    <TextField
                                        value={getComponentConfiguration(uuid).question.nbMaxExpectedAnswers}
                                        onChange={(e) => changeField("nbMaxExpectedAnswers", e.target.value)}
                                        type='number'
                                        style={{ width: 40 }}
                                    />
                                </div>
                            </>
                        }
                        <div className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.switch.shuffle.answers')}</Typography>
                            <FormControlLabel
                                disabled={hasBindFieldEnable()}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={getComponentConfiguration(uuid).question.shuffle} color="secondary" onChange={e => changeField('shuffle', e.target.value)}  />}
                                labelPlacement="start"
                            />
                        </div>
                        <Box className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.answersSameOrderAs')}</Typography>
                            <FormControlLabel
                                disabled={hasBindFieldEnable()}
                                className={classes.switchSingleOrMultiple}
                                control={<Switch checked={enableSortByRef} color="secondary" onChange={e => changeField('enableSortByRef', e.target.value)} />}
                                labelPlacement="start"
                            />
                        </Box>
                        {enableSortByRef &&
                            <>
                                <Box className={classes.flexBetweenMinAnswers} style={{display: getDisplayVisibility()}}>
                                    <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.refPreviousBlockOrder')}</Typography>
                                    <Box className={classes.flex}>
                                        {!isBlank(refUsedToSortAnswers) && <Tooltip title={blockUsedAsRefToSortAnswers ? t('react.surveys.tooltip.answerSameOrderAsExist') : t('react.surveys.tooltip.answerSameOrderAsNotExist')}>
                                            <IconButton className={classes.IconButtonMinMax} size='small'>
                                                <CheckIcon color={blockUsedAsRefToSortAnswers ? "success" : "error"} fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>}
                                        <TextField
                                            value={refUsedToSortAnswers}
                                            onChange={(e) => handleChangeRefUsedToSort(e.target.value)}
                                            style={{ width: 200}}
                                            error={!isBlank(refUsedToSortAnswers) && !blockUsedAsRefToSortAnswers}
                                        />
                                    </Box>
                                </Box>
                                {blockUsedAsRefToSortAnswers?.configuration.type === "battery" && <Box className={classes.flexBetween} style={{display: getDisplayVisibility()}}>
                                    <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.answersSameOrderAs.type')}</Typography>
                                    <ButtonGroup className={classes.buttonGroupAnswerSameOrderAs} size="small">
                                        <Button 
                                            variant={getComponentConfiguration(uuid).question.orderAnswersLikeBlockField === "answers" ? "contained" : "outlined"} 
                                            onClick={() => changeField('orderAnswersLikeBlockField', "answers")}>
                                            {t('react.surveys.answersSameOrderAs.answers')}
                                        </Button>
                                        <Button 
                                            variant={getComponentConfiguration(uuid).question.orderAnswersLikeBlockField === "items" ? "contained" : "outlined"} 
                                            onClick={() => changeField('orderAnswersLikeBlockField', "items")}>
                                            {t('react.surveys.answersSameOrderAs.items')}
                                        </Button>
                                    </ButtonGroup>
                                </Box>}
                            </>
                        }
                        <div>
                            <List dense subheader={<ListSubheader className={classes.listSubheader}>
                                <Typography variant="subtitle2">{t("react.project.collectforms.answers")}</Typography>
                                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                    <Tooltip title={t("react.project.collectforms.customanswer.tooltip")}>
                                        <IconButton
                                            onClick={() => setOpenPopupGenerator(true)}
                                            className={classes.IconButtonMinMax}
                                            component='span'
                                            size='small'
                                        >
                                            <SubjectIcon fontSize='small'/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("react.project.collectforms.sort.tooltip")}>
                                        <IconButton
                                            className={classes.IconButtonMinMax}
                                            component='span'
                                            onClick={(event) => sortAnswers(event)}
                                            size='small'
                                            disabled={getComponentConfiguration(uuid).question.answers.length === 0}>
                                            <SortByAlphaIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("react.project.collectforms.moveDown.tooltip")}>
                                        <IconButton
                                            className={classes.IconButtonMinMax}
                                            component='span'
                                            disabled={!canIMoveDown()}
                                            onClick={(event) => moveAnswerDownward(event)}
                                            size='small'>
                                            <ArrowDownwardIcon fontSize='small' className={classes.downwardIcon} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("react.project.collectforms.moveUp.tooltip")}>
                                        <IconButton
                                            className={classes.IconButtonMinMax}
                                            component='span'
                                            disabled={!canIMoveUp()}
                                            onClick={(event) => moveAnswerUpward(event)}
                                            size='small'>
                                            <ArrowUpwardIcon fontSize='small' className={classes.upwardIcon} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("react.project.collectforms.nextBlock.tooltip")}>
                                        <IconButton
                                            className={classes.IconButtonMinMax}
                                            component='span'
                                            disabled={answerIndex === -1}
                                            onClick={() => accessParamsQuestion()}
                                            size='small'>
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("react.project.collectforms.deleteAnswer.tooltip")}>
                                        <IconButton
                                            className={classes.IconButtonMinMax}
                                            component='span'
                                            disabled={answerIndex === -1}
                                            onClick={() => deleteAnswer()}
                                            size='small'>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListSubheader>}>
                                {getComponentConfiguration(uuid).question.answers.map((value, subindex) => {
                                    const labelId = `answer-label-${value.answer}`;
                                    return (
                                        <ListItem
                                            key={`pq-subindex-${subindex}`}
                                            onClick={() => selectAnswer(subindex)}
                                            selected={answerIndex === subindex}
                                            button
                                            className={classes.listItem}
                                        >
                                            <ListItemText id={labelId} primary={value.answer} style={{width: '100%'}}/>
                                            <div className={classes.iconsAnswerPosition}>
                                                {value.fixedPosition && getComponentConfiguration(uuid).question.shuffle && <div>
                                                    <Tooltip title={t("react.project.collectforms.fixedPosition.tooltip")}>
                                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                                            <PinIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>}
                                                {value.screenOut && <div>
                                                    <Tooltip title={t("react.project.collectforms.screenOut.tooltip")}>
                                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                                            <RedoIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>}
                                                {value.freeField && <div>
                                                    <Tooltip title={t("react.project.collectforms.freeField.tooltip")}>
                                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                                            <TextFieldsIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>}
                                                {getComponentConfiguration(uuid).question.type === "multiple" && value.exclusive && <div>
                                                    <Tooltip title={t("react.project.collectforms.exclusive.tooltip")}>
                                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                                            <GradeIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>}
                                                {value.quota > 0 && getFormConfiguration().nbMaxAnswers > 0 && <div>
                                                    <Tooltip title={t("react.project.collectforms.quotaNumber.tooltip")}>
                                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                                            <div className={classes.quotaValue}>{value.quota}</div>
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>}
                                                {value.imageId > 0 && <div>
                                                    <Tooltip title={t("react.surveys.profileQuestion.tooltip.image")}>
                                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                                            <ImageIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>}
                                                {getAnswerContentValidityMark(value, subindex)}
                                            </div>   
                                            <ListItemText primary={value.ref} className={classes.answerRef} />                                                 
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                    </div>
                    <div className={classes.addNewAnswer}>
                        <TextField
                            fullWidth
                            multiline={true}
                            label={t("react.project.collectforms.addanswer")}
                            className={classes.marginBottomType}
                            value={newAnswer}
                            onChange={e => setNewAnswer(e.target.value)}
                        ></TextField>
                        <Button className={classes.addNewAnswerButton} disabled={newAnswer.length > 255} onClick={e => changeField('answers')} size='small'><AddIcon fontSize='small' /></Button>
                    </div>
                </div>
            </Grid>
        </Paper>
        </>
    );
}
