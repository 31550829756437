import React, { useState, useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LinkIcon from '@mui/icons-material/Link';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import Looks3Icon from '@mui/icons-material/Looks3';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MessageIcon from '@mui/icons-material/Message';
import SurveysService from '../../services/SurveysService';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { SurveyContext } from './context';
import CopyLinkDialog from './CopyLinkDialog';
import { toolbarStyles, ExportIcon } from '../../common.js';
import SendIcon from '@mui/icons-material/Send';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { FaFileZipper, FaFileInvoice } from "react-icons/fa6";
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import FireSimulationsDialog from './FireSimulationsDialog.js';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import CachedIcon from '@mui/icons-material/Cached';
import TranslateIcon from "@mui/icons-material/Translate";
import PaletteIcon from "@mui/icons-material/Palette";
import SettingsIcon from "@mui/icons-material/Settings";
import EditThemeDialog from "./EditThemeDialog";
import TranslationsDialog from "./TranslationsDialog";
import Badge from '@mui/material/Badge';

const surveysService = new SurveysService();

export default function ToolBar(props) {

    const { getFormConfiguration, needToSave, formIdParameter, selectedElement, setSelectedElement } = useContext(SurveyContext);

    const {
        t,
        openSnackbar,
        showSpinner,
        createBlock,
        hasThankYou,
        hasIntroduction,
        saveAll,
        handleClickRefresh,
        warnWhenModifiedBySomeoneElse
    } = props;

    const { classes } = toolbarStyles();

    const [showConfirmDeleteDataPopup, setShowConfirmDeleteDataPopup] = useState(false);
    const [showFireSimulationsPopup, setShowFireSimulationsPopup] = useState(false);
    const [openTranslationsDialog, setOpenTranslationsDialog] = useState(false);
    const [openEditThemeDialog, setOpenEditThemeDialog] = useState(false);
    const [linkPopup, setLinkPopup] = useState(false);

    const handleSaveForm = (event) => {
        event.preventDefault();
        saveAll();
    };

    const [anchorElDownloadAnswers, setAnchorElDownloadAnswers] = React.useState(null);
    const handleCloseDownloadAnswers = () => {
        setAnchorElDownloadAnswers(null);
    };
    const handleClickDownloadExperiencesAnswers = (event) => {
        setAnchorElDownloadAnswers(event.currentTarget);
    };

    const handleClickDownloadExperiencesAnswersAsR3mscore = (event) => {
        event.preventDefault();
        setAnchorElDownloadAnswers(null);
        showSpinner(true);
        surveysService.getAnswersAsExcelFile(formIdParameter)
            .then(response => {
                var regExp = new RegExp('filename="([^"]+)"', 'i');
                let filename = regExp.exec(response.headers['content-disposition'])[1];
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                openSnackbar('error', t("react.project.error.download.file"));
            })
            .finally(function () {
                showSpinner(false);
            });
    };

    const handleClickDownloadExperiencesAnswersAsTriple_s = (event) => {
        event.preventDefault();
        setAnchorElDownloadAnswers(null);
        showSpinner(true);
        surveysService.getAnswersAsTriple_sFile(formIdParameter)
            .then(response => {
                var regExp = new RegExp('filename="([^"]+)"', 'i');
                let filename = regExp.exec(response.headers['content-disposition'])[1];
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                openSnackbar('error', t("react.project.error.download.file"));
            })
            .finally(function () {
                showSpinner(false);
            });
    };

    const handleClickDownloadAsCrosstab = (event) => {
        event.preventDefault();
        setAnchorElDownloadAnswers(null);
        showSpinner(true);
        surveysService.getReportingAsExcelFile(formIdParameter)
            .then(response => {
                var regExp = new RegExp('filename="([^"]+)"', 'i');
                let filename = regExp.exec(response.headers['content-disposition'])[1];
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                openSnackbar('error', t("react.project.error.download.file"));
            })
            .finally(function () {
                showSpinner(false);
            });
    };

    const handleCopyFormLinkInClipboard = (linkOfTheForm) => {
        navigator.clipboard.writeText(linkOfTheForm).then(function () {
            openSnackbar('success', t("react.project.collectforms.link.clipboarded"));
        }, function (err) {
            openSnackbar('error', t("react.project.collectforms.link.clipboarded.failed"));
        });

        setLinkPopup(false);
    };

    const handleConfirmDeleteCollectedData = () => {
        setShowConfirmDeleteDataPopup(false);
        showSpinner(true);
        surveysService.deleteCollectedData(formIdParameter)
            .then(response => {
                openSnackbar('success', t("react.project.success.delete.collected.data"));
            })
            .catch(err => {
                openSnackbar('error', t("react.project.error.delete.collected.data"));
            })
            .finally(function () {
                showSpinner(false);
            });
    };

    const handleCancelDeleteCollectedData = () => {
        setShowConfirmDeleteDataPopup(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClose = () => {
       setAnchorEl(null);
    };
    const [nbLinksToGenerate, setNbLinksToGenerate] = useState(100);
    const handleAskNbLinksToGenerate = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClickDownloadFormLinksFile = (event) => {
      setAnchorEl(null);
      event.preventDefault();
      showSpinner(true);
      surveysService.getFormLinksAsExcelFile(formIdParameter, nbLinksToGenerate)
          .then(response => {
              var regExp = new RegExp('filename="([^"]+)"', 'i');
              let filename = regExp.exec(response.headers['content-disposition'])[1];
              let url = window.URL.createObjectURL(new Blob([response.data]));
              let link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
          })
          .catch(err => {
              openSnackbar('error', t("react.project.error.download.file"));
          })
          .finally(function () {
              showSpinner(false);
          });
    };

    const goingToReporting = (event, idParam) => {
        event.preventDefault();
        props.navigate(`/surveys/${idParam}/reporting`);
    };

    const goingToDatamap = (event) => {
        event.preventDefault();
        props.navigate(`/surveys/${formIdParameter}/datamap`);
    };

    const goToParams = (event) => {
        event.preventDefault();
        setSelectedElement(undefined);
    };

    const goToTranslationMenu = (event) => {
        event.preventDefault();
        setOpenTranslationsDialog(true);
    };

    const goToThemeMenu = (event) => {
        event.preventDefault();
        setOpenEditThemeDialog(true);
    };

    const handleClickFireSimulations = (jsonResult) => {
        surveysService.fireAutoThrustSurvey(formIdParameter, jsonResult)
            .then(response => {
                openSnackbar('success', t("react.project.collectforms.fire.simulations.success"));
            })
            .catch(err => {
                openSnackbar('error', t("react.project.collectforms.fire.simulations.error"));
            });
    };

    const isDisabled = () => {
        return (formIdParameter <= 0 || formIdParameter === "add");
    };

    const selectedGroupIsCellAssigner = () => {
        return selectedElement?.configuration.group?.type === "cellAssigner";
    };

    return (
        <Toolbar className={classes.rootDashboard} style={{position: 'absolute', top: '-55px', display: "flex", justifyContent: "center", width: "100%"}}>
            <PopupTwoButtons
                variant='warning'
                openState={showConfirmDeleteDataPopup}
                callbackOnclose={handleCancelDeleteCollectedData}
                callbackOnclickLeftButton={handleCancelDeleteCollectedData}
                callbackOnclickRightButton={handleConfirmDeleteCollectedData}
                title={t('react.project.collectforms.delete.data.confirm.title')}
                content={t('react.project.collectforms.delete.data.confirm.content')}
                leftButton={t('react.button.cancel')}
                rightButton={t('react.button.delete')}
            />
            <FireSimulationsDialog
                {...props}
                openState={showFireSimulationsPopup}
                callbackOnclose={() => setShowFireSimulationsPopup(false)}
                handleClickFireSimulations={handleClickFireSimulations}
            />
            <CopyLinkDialog
                {...props}
                openState={linkPopup}
                callbackOnclose={() => setLinkPopup(false)}
                handleCopyFormLinkInClipboard={handleCopyFormLinkInClipboard}
            />
            <EditThemeDialog
                {...props}
                openEditThemeDialog={openEditThemeDialog}
                setOpenEditThemeDialog={setOpenEditThemeDialog}
                openSnackbar={openSnackbar}
            />
            <TranslationsDialog
                {...props}
                open={openTranslationsDialog}
                setOpen={setOpenTranslationsDialog}
            />
            <Tooltip title={t("react.project.collectforms.buttton.createintro")}>
                <IconButton onClick={() => createBlock('introduction')} disabled={hasIntroduction} component="span" size='small'>
                    <PlayCircleFilledOutlinedIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.buttton.createexperience")}>
                <IconButton disabled={selectedGroupIsCellAssigner()} onClick={() => createBlock('experience')} component="span" size='small'>
                    <Looks3Icon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.button.createopenquestion")}>
                <IconButton disabled={selectedGroupIsCellAssigner()} onClick={() => createBlock('openQuestion')} component="span" size='small'>
                    <MessageIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.buttton.createquestion")}>
                <IconButton disabled={selectedGroupIsCellAssigner()} onClick={() => createBlock('question')} component="span" size='small'>
                    <QuestionAnswerIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.buttton.createtext")}>
                <IconButton disabled={selectedGroupIsCellAssigner()} onClick={() => createBlock('text')} component="span" size='small'>
                    <TextsmsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.buttton.createhotspot")}>
                <IconButton disabled={selectedGroupIsCellAssigner()} onClick={() => createBlock('hotspot')} component="span" size='small'>
                    <PictureInPictureIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.surveys.battery.createBattery")}>
                <IconButton disabled={selectedGroupIsCellAssigner()} onClick={() => createBlock('battery')} component="span" size='small'>
                    <CalendarViewMonthIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.surveys.group.createGroup")}>
                <IconButton onClick={() => createBlock('group')} component="span" size='small'>
                    <ViewAgendaIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.buttton.createthankyou")}>
                <IconButton onClick={() => createBlock('thankyou')} disabled={hasThankYou} component="span" size='small'>
                    <EmojiPeopleIcon />
                </IconButton>
            </Tooltip>

            <Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />

            <Tooltip title={t("react.project.collectforms.copy.link.tooltip")}><span>
                <IconButton disabled={!getFormConfiguration().active} component="span" size='small' onClick={() => setLinkPopup(true)}>
                    <LinkIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t('react.project.collectforms.download.links.file')}><span>
                <IconButton disabled={!getFormConfiguration().active} size='small' onClick={handleAskNbLinksToGenerate}>
                    <DatasetLinkedIcon />
                </IconButton></span>
            </Tooltip>  
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem>
                    <TextField
                        id="outlined-basic"
                        label={t('react.project.collectforms.download.links.count')}
                        variant="outlined"
                        value={nbLinksToGenerate}
                        onChange={e => setNbLinksToGenerate(e.target.value)}/>
                </MenuItem>
                <MenuItem onClick={handleClickDownloadFormLinksFile}>
                    <ListItemIcon>
                        <SendIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('react.project.collectforms.download.links.generate')}</Typography>
                </MenuItem>
            </Menu>

            <Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />

            <Tooltip title={t("react.project.collectforms.fire.simulations")}><span>
                <IconButton disabled={isDisabled()} component="span" size='small' onClick={() => setShowFireSimulationsPopup(true)}>
                    <MovieFilterIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t("react.collectors.list.tooltip.reporting")}><span>
                <IconButton disabled={isDisabled()} component="span" size='small' onClick={event => goingToReporting(event, getFormConfiguration().id)}>
                    <AssessmentIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t("react.collectors.list.tooltip.datamap")}><span>
                <IconButton disabled={isDisabled()} component="span" size='small' onClick={event => goingToDatamap(event)}>
                    <ListAltIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.download.experiences.answers")}><span>
                <IconButton disabled={isDisabled()} component="span" size='small' onClick={handleClickDownloadExperiencesAnswers}>
                    <CloudDownloadIcon />
                </IconButton></span>
            </Tooltip>
            <Menu
                id="download-answers"
                anchorEl={anchorElDownloadAnswers}
                open={Boolean(anchorElDownloadAnswers)}
                keepMounted
                onClose={handleCloseDownloadAnswers}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleClickDownloadExperiencesAnswersAsR3mscore}>
                    <ListItemIcon>
                        <ExportIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('react.project.collectforms.download.answers.as.r3mscore')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleClickDownloadExperiencesAnswersAsTriple_s}>
                    <ListItemIcon>
                        <FaFileZipper fontSize="20" />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('react.project.collectforms.download.answers.as.triple-s')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleClickDownloadAsCrosstab}>
                    <ListItemIcon>
                        <FaFileInvoice fontSize="20" />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('react.project.collectforms.download.answers.as.crosstab')}</Typography>
                </MenuItem>
            </Menu>
            <Tooltip title={t("react.project.collectforms.delete.data.tooltip")}><span>
                <IconButton disabled={isDisabled()} component="span" size='small' onClick={() => setShowConfirmDeleteDataPopup(true)}>
                    <AutoDeleteIcon />
                </IconButton></span>
            </Tooltip>

            <Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />

            <Tooltip title={t("react.project.collectforms.open.translate.menu")}><span>
                <IconButton disabled={(formIdParameter <= 0 || formIdParameter === "add" || needToSave)} component="span" size='small' onClick={(e) => goToTranslationMenu(e)} >
                    <TranslateIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t("react.project.collectforms.open.theme.menu")}><span>
                <IconButton disabled={(formIdParameter <= 0 || formIdParameter === "add" || needToSave)} component="span" size='small' onClick={(e) => goToThemeMenu(e)}>
                    <Badge badgeContent={'New'} color="info" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <PaletteIcon />
                    </Badge>
                    </IconButton></span>
                </Tooltip>
            <Tooltip title={t("react.project.collectforms.open.settings")}><span>
                <IconButton component="span" size='small' onClick={(e) => goToParams(e)}>
                    <SettingsIcon />
                </IconButton></span>
            </Tooltip>

            <Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />

            {warnWhenModifiedBySomeoneElse 
                ? <Tooltip title={t("react.button.reload")}>
                    <IconButton component="span" size='small' onClick={handleClickRefresh}>
                        <CachedIcon color='error' />
                    </IconButton>
                  </Tooltip> 
                : <Tooltip title={t("react.button.save")}>
                    <IconButton component="span" size='small' onClick={(e) => handleSaveForm(e)}>
                        {needToSave === true ? <SaveIcon color='error' /> : <SaveIcon />}
                    </IconButton>
                  </Tooltip>
            }
        </Toolbar>
    );
}
