import { Typography } from "@mui/material";
import Collectors from "./Collectors";
import { Box } from "@mui/system";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CollectorTestContextProvider } from './context';
import {LoadData} from '../../Constants.js'
import SurveysService from '../../services/SurveysService';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import { makeStyles } from 'tss-react/mui';
import { flatAllSurveyBlocks } from "../../utils/surveysUtils.js";

const useStyles = makeStyles()(theme => ({
    mainBox: {
        display: 'flex', 
        flexDirection: 'column'
    },
    alertBox: {
        color : 'white',
        background: 'linear-gradient(to right, white, red, white)',
        padding: '4px',
        textAlign: 'center',
        borderRadius: '4px',
        fontWeight: 'bold'
    }
}));

export default function CollectorsTest(props) {

    const { 
        collectIdParameter 
    } = useParams();

    const {
        t,
        openSnackbar,
        showSpinner
    } = props;

    const { classes } = useStyles();
    const surveysService = new SurveysService();

    const [survey, setSurvey] = useState(undefined);
    const [queryParameters, setQueryParameters] = useState(undefined);

    const [loadData, setLoadData] = useState(LoadData.Load);

    useEffect(() => {
        if(loadData !== LoadData.Load) return;

        showSpinner(true);

        // read parameters
        let queryParameters = queryString.parse(window.location.search);
        setQueryParameters(queryParameters);

        async function fetchData() {
            await surveysService.fetchForm(collectIdParameter)
                .then(result => {
                    setSurvey(result.data);
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);
                })
                .catch(e => {
                    setSurvey(undefined);
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);

                    if (e.response && e.response.status && e.response.status === 403) {
                        openSnackbar('error', t('react.error.collectform.forbidden'));
                    } else {
                        openSnackbar('error', t('react.error.fetch.message'));
                    }
                });
        }
        fetchData();
    }, [collectIdParameter]);

    if (loadData !== LoadData.Loaded) return null;

    if(survey === undefined) {
        return (<Box>{t('react.error.collectform.forbidden.message')}</Box>);
    }

    return (
        <Box className={classes.mainBox}>
            <CollectorTestContextProvider values={{survey, flattenedBlocks: flatAllSurveyBlocks(survey.blocks)}}>
                <Typography variant="caption" gutterBottom className={classes.alertBox}>
                    MODE TEST - DO NOT USE THIS LINK FOR PRODUCTION
                </Typography>
                <Collectors 
                    {...props} 
                    providedSurveyId={survey.id} 
                    providedParticipantUuid={uuidv4()} 
                    providedQueryParameters={queryParameters} 
                    testMode={true}
                />
            </CollectorTestContextProvider>
        </Box>);
};