import SvgIcon from '@mui/material/SvgIcon';
import { lighten } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const toolbarStyles = makeStyles()((theme) => {
return {
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  mainIcon: {
    marginRight: '12px',
  },
  searchfield: {
    marginRight: '12px',
    minWidth: '250px !important',
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
        color: '#003945',
        backgroundColor: lighten('#003945', 0.65),
      }
      : {
        color: '#003945',
        backgroundColor: lighten('#003945', 0.65),
      },
  title: {
    flex: '1 1 100%',
  },
  formControl: {
    marginRight: '15px !important',
    marginTop: '0px !important',
    minWidth: '120px !important',
    maxWidth: '300px !important',
  },
  formControlDashboard: {
    marginRight: '6px !important',
    marginTop: '0px !important',
    minWidth: 'fit-content !important',
  },
  rootDashboard: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    float: 'right',
    maxHeight: '32px !important',
    minHeight: '32px !important',
    paddingBottom: '10px'
  },
  divider: {
    marginLeft: '5px', 
    marginRight: '7px', 
    height: '20px', 
    backgroundColor: '#bbb'
  }
};
});

export const ImgIcon = (props) => {
  return <SvgIcon color={props.color ? props.color : "inherit"} fontSize='small'><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" /></SvgIcon>;
};

export const FilterIcon = (props) => {
  return <SvgIcon color={props.color ? props.color : "inherit"} style={{ height: '20px' }}><path d="M1 0h22l-9 14.094v9.906l-4-2v-7.906z" /></SvgIcon>;
};

export const ExportIcon = (props) => {
  return <SvgIcon color={props.color ? props.color : "inherit"} style={{ height: '20px' }}><path d="M 15 0 L 0 2.875 L 0 23.125 L 15 26 Z M 16 3 L 16 5.96875 L 19.03125 5.96875 L 19.03125 8 L 16 8 L 16 10 L 19 10 L 19 12 L 16 12 L 16 14 L 19 14 L 19 16 L 16 16 L 16 18 L 19 18 L 19 20 L 16 20 L 16 23 L 25.15625 23 C 25.617188 23 26 22.605469 26 22.125 L 26 3.875 C 26 3.394531 25.617188 3 25.15625 3 Z M 20 6 L 24 6 L 24 8 L 20 8 Z M 3.09375 7.9375 L 5.84375 7.9375 L 7.3125 11 C 7.425781 11.238281 7.535156 11.515625 7.625 11.84375 C 7.683594 11.644531 7.8125 11.359375 7.96875 10.96875 L 9.5625 7.9375 L 12.09375 7.9375 L 9.0625 12.96875 L 12.1875 18.09375 L 9.5 18.09375 L 7.75 14.78125 C 7.683594 14.660156 7.601563 14.421875 7.53125 14.09375 L 7.5 14.09375 C 7.46875 14.25 7.402344 14.496094 7.28125 14.8125 L 5.53125 18.09375 L 2.8125 18.09375 L 6.03125 13.03125 Z M 20 10 L 24 10 L 24 12 L 20 12 Z M 20 14 L 24 14 L 24 16 L 20 16 Z M 20 18 L 24 18 L 24 20 L 20 20 Z" /></SvgIcon>;
};

export const PinIcon = (props) => {
  return <SvgIcon color={props.color ? props.color : "inherit"} style={{ height: '20px' }}><path d="M7.414 10l.956.955a1 1 0 0 1-1.415 1.415L4.293 9.707C3.663 9.077 4.109 8 5 8h5.586L13 5.586V4a1 1 0 0 1 .293-.707l1-1a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-1 1A1 1 0 0 1 20 11h-1.586L16 13.414V19c0 .89-1.077 1.337-1.707.707L10 15.414l-5.293 5.293a1 1 0 1 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L14 16.586V13a1 1 0 0 1 .293-.707l3-3A1 1 0 0 1 18 9h1.586l-5.293-5.293h1.414l-1 1L15 4v2a1 1 0 0 1-.293.707l-3 3A1 1 0 0 1 11 10H7.414z" /></SvgIcon>;
};
