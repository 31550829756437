import React, { useState, useContext, useReducer, useEffect, useRef, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { SurveyContext } from './context';
import Typography from '@mui/material/Typography';
import { isBlank } from '../../utils/utils.js';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ParamsQuestionsBatteryOfItems from './ParamsQuestionsBatteryOfItems';
import AnswerGeneratorDialog from './AnswerGeneratorDialog';
import SubjectIcon from '@mui/icons-material/Subject';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ImageIcon from '@mui/icons-material/Image';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import QuillEditor from './QuillEditor.js';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import GradeIcon from '@mui/icons-material/Grade';
import ParamsImage from './ParamsImage';
import CheckIcon from '@mui/icons-material/Check';
import { isBlankHtml, computeNextElementRef } from '../../utils/surveysUtils.js';
import { PinIcon } from '../../common.js';
import RedoIcon from '@mui/icons-material/Redo';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import BlockHeader from './BlockHeader.js';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import SurveysService from '../../services/SurveysService';

const useStyles = makeStyles()(theme => ({
    paddingPaper: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 1,
        paddingBottom: 30,
    },
    textFieldContent: {
        marginTop: 20,
    },
    textFieldInstructions: {
        marginTop: 15,
        marginBottom: 5
    },
    flexFirst: {
        paddingTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelSwitchQuestion: {
        marginTop: 10,
    },
    switchSingleOrMultiple: {
        marginRight: -5,
        padding: 0,
    },
    addNewAnswer: {
        paddingLeft: '5px',
        display: 'flex',
    },
    addNewAnswerButton: {
        right: -15,
        marginTop: '12px'
    },
    flexBetweenMinAnswers: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    dialogComponent: {
        minHeight: '400px',
        height: 400, 
        width: 500
    },
    IconButtonMinMaxItem: {
        color: '#173c43'
    },
    marginResponses: {
        marginTop: 10,
    },
    listSubheader: {
        marginTop: 15,
        marginBottom: 10,
        marginRight: 5,
        background: '#eee',
        padding: '3px'        
    },
    iconsAnswerPosition: {
        display: 'flex',
        width: '35px',
        marginRight: 10,
    },
    IconButtonMinMax: {
        minWidth: '30px',
        maxWidth: '30px',
    },
    gridImage: {
        marginTop: 20,
        marginBottom: 20,
    },
    previewRoot: {
        marginBottom: '25px',
        margin: '0',
        padding: '5px',
        width: `250px`,
        height: `150px`,
        display: 'inline-block',
        borderStyle: 'dotted',
        borderRadius: '7px',
    },
    previewImg: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '140px',
        position: 'relative',
        top: '-50px'
    },
    previewBtns: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        height: '50px',
        position: 'relative', top: '25%', zIndex: '100'
    },
    alignCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '200px'
    },
    batteryProfileIndex: {
        color: "#BBB",
        fontStyle: "italic",
        width: '30px',
        marginLeft: 10,
    },
    buttonGroupAnswerSameOrderAs: {
        height: 25, 
        marginTop: 5
    },
    flex: {
        display: 'flex'
    },
    marginRefOrderAnswersLikeRef: {
        marginTop: 4
    },
    textFieldRefUsedToSort: {
        width: 200
    }
}));

const surveysService = new SurveysService();

export default function BatteryOfItems(props) {

    const { classes } = useStyles();

    const { t, uuid } = props;

    const { notifyStructuralChange, setNeedToSave, getComponentConfiguration, mediaFiles,
        formIdParameter, generateSetImageButton, generateDeleteImageButton, flattenBlocksForm } = useContext(SurveyContext);

    const [openDialogQuestions, setOpenDialogQuestions] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [newAnswer, setNewAnswer] = useState(''); // contenu de la question en dessous des réponses, n'est pas envoyé dans le json
    const [newItem, setNewItem] = useState(''); // contenu de la question en dessous des items, n'est pas envoyé dans le json
    const [answerIndex, setAnswerIndex] = useState(-1);
    const [itemIndex, setItemIndex] = useState(-1);
    const [openPopupGenerator, setOpenPopupGenerator] = useState(false);
    const [typeOfGenerator, setTypeOfGenerator] = useState('');
    const [typeOfEditing, setTypeOfEditing] = useState('');
    const [collapseInstructions, setCollapseInstructions] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
    const [activeTempo, setActiveTempo] = useState(getComponentConfiguration(uuid).timeout === 0 ? false : true);
    const [openDialogImage, setOpenDialogImage] = useState(false);

    const [enableSortItemsByRef, setEnableSortItemsByRef ] = useState(false);
    const [refUsedToSortItems, setRefUsedToSortItems ] = useState('');
    const [enableSortAnswersByRef, setEnableSortAnswersByRef ] = useState(false);
    const [refUsedToSortAnswers, setRefUsedToSortAnswers ] = useState('');

    const quillRef = useRef();

    useEffect(() => {
        let enableSortItemsByRef = false;
        let refUsedToSortItems = '';
        if(getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid) {
            // check if the referenced block is still available
            let blockUsedToSortItems = findBlockByRefOrUuidAndUsableToSortElements(getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid);
            if(!blockUsedToSortItems) {
                getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid = undefined;
                getComponentConfiguration(uuid).battery.orderItemsLikeBlockField = 'items';
                setNeedToSave(true);
            }
            enableSortItemsByRef = blockUsedToSortItems !== undefined;
            refUsedToSortItems = blockUsedToSortItems ? blockUsedToSortItems.configuration.ref : '';
        }
        setEnableSortItemsByRef(enableSortItemsByRef);
        setRefUsedToSortItems(refUsedToSortItems);

        // ----

        let enableSortAnswersByRef = false;
        let refUsedToSortAnswers = '';
        if(getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid) {
            // check if the referenced block is still available
            let blockUsedToSortAnswers = findBlockByRefOrUuidAndUsableToSortElements(getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid);
            if(!blockUsedToSortAnswers) {
                getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid = undefined;
                getComponentConfiguration(uuid).battery.orderAnswersLikeBlockField = 'answers';
                setNeedToSave(true);
            }
            enableSortAnswersByRef = blockUsedToSortAnswers !== undefined;
            refUsedToSortAnswers = blockUsedToSortAnswers ? blockUsedToSortAnswers.configuration.ref : '';
        }
        setEnableSortAnswersByRef(enableSortAnswersByRef);
        setRefUsedToSortAnswers(refUsedToSortAnswers);
    }, [uuid]);

    useEffect(() => {
        if (getComponentConfiguration(uuid).imageId === 0) {
            getComponentConfiguration(uuid).timeout = 0;
            setActiveTempo(false);
        }
    }, [getComponentConfiguration(uuid).imageId]);

    const changeField = (fieldName, value, index) => {
        switch (fieldName) {
            case "ref":
                getComponentConfiguration(uuid).ref = value;
                break;
            case "title":
                getComponentConfiguration(uuid).title = value;
                break;
            case "content":
                getComponentConfiguration(uuid).content = value;
                break;
            case "instructions":
                getComponentConfiguration(uuid).battery.instructions = value;
                break;
            case "enabledBlock":
                getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
                break;
            case "mode":
                getComponentConfiguration(uuid).battery.mode = value;
                if (value !== "carousel") {
                    getComponentConfiguration(uuid).battery.displayStepper = false;
                }
                break;
            case "shuffleItems":
                getComponentConfiguration(uuid).battery.shuffleItems = !getComponentConfiguration(uuid).battery.shuffleItems;
                if (getComponentConfiguration(uuid).battery.shuffleItems) {
                    getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid = undefined;
                    setEnableSortItemsByRef(false);
                    setRefUsedToSortItems('');
                }
                break;
            case "enableSortItemsByRef":
                let newSortItemsStatus = !enableSortItemsByRef;
                if(!newSortItemsStatus) {
                    setRefUsedToSortItems('');
                    getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid = undefined;
                    getComponentConfiguration(uuid).battery.orderItemsLikeBlockField = 'items';
                } else {
                    getComponentConfiguration(uuid).battery.shuffleItems = false;
                }
                setEnableSortItemsByRef(newSortItemsStatus);
                break;
            case "orderItemsLikeBlockUuid":
                getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid = value;
                break;
            case "orderItemsLikeBlockField":
                getComponentConfiguration(uuid).battery.orderItemsLikeBlockField = value;
                break;
            case "shuffeAnswers":
                getComponentConfiguration(uuid).battery.shuffleAnswers = !getComponentConfiguration(uuid).battery.shuffleAnswers;
                if (getComponentConfiguration(uuid).battery.shuffleAnswers) {
                    getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid = undefined;
                    setEnableSortAnswersByRef(false);
                    setRefUsedToSortAnswers('');
                }
                break;
            case "enableSortAnswersByRef":
                let newSortAnswersStatus = !enableSortAnswersByRef;
                if(!newSortAnswersStatus) {
                    setRefUsedToSortAnswers('');
                    getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid = undefined;
                    getComponentConfiguration(uuid).battery.orderAnswersLikeBlockField = 'answers';
                } else {
                    getComponentConfiguration(uuid).battery.shuffleAnswers = false;
                }
                setEnableSortAnswersByRef(newSortAnswersStatus);
                break;
            case "orderAnswersLikeBlockUuid":
                getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid = value;
                break;
            case "orderAnswersLikeBlockField":
                getComponentConfiguration(uuid).battery.orderAnswersLikeBlockField = value;
                break;
            case "displayStepper":
                getComponentConfiguration(uuid).battery.displayStepper = !getComponentConfiguration(uuid).battery.displayStepper;
                break;
            case "switchDisplayOnlyImages":
                getComponentConfiguration(uuid).battery.displayOnlyAnswerImages = !getComponentConfiguration(uuid).battery.displayOnlyAnswerImages;
                break;
            case "enabledTemporisation":
                if(activeTempo) {
                    // Tempo is going to be desactivated
                    getComponentConfiguration(uuid).timeout = 0;
                } else {
                    // else Tempo is going to be activated => default timeout of 3
                    getComponentConfiguration(uuid).timeout = 3;
                }
                setActiveTempo(!activeTempo);
                break;
            case "temporisationDelay":
                if (value >= 0) {
                    getComponentConfiguration(uuid).timeout = value;
                }
                break;
            case "answers":
                const foundAnswer = getComponentConfiguration(uuid).battery.answers.find(obj => obj.answer === newAnswer);
                if (newAnswer !== undefined && !isBlank(newAnswer) && foundAnswer === undefined) {
                    getComponentConfiguration(uuid).battery.answers.push({
                        text: newAnswer,
                        quota: 0,
                        screenOut: false,
                        fixedPosition: false,
                        exclusive: false,
                        uuid: uuidv4(),
                        ref: getNextAnswerRef()
                    });
                    setNewAnswer('');
                }
                break;
            case "items":
                const foundItem = getComponentConfiguration(uuid).battery.items.find(obj => obj.answer === newItem);
                if (newItem !== undefined && !isBlank(newItem) && foundItem === undefined) {
                    getComponentConfiguration(uuid).battery.items.push({
                        id: 0, 
                        batteryId: 0, 
                        uuid: uuidv4(),
                        text: newItem,
                        name: newItem,
                        textLeft: '',
                        textRight: '',
                        fixedPosition: false,
                        ref: getNextItemRef()
                    });
                    setNewItem('');
                }
                break;
            case "deleteItem":
                getComponentConfiguration(uuid).battery.items.splice(value, 1);
                break;
            case "textItem":
                getComponentConfiguration(uuid).battery.items[index].text = value;
                break;
            case "type":
                if (getComponentConfiguration(uuid).battery.type === 'multiple') {
                    getComponentConfiguration(uuid).battery.type = 'single';
                    getComponentConfiguration(uuid).battery.nbMinExpectedAnswers = 0;
                    getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers = getComponentConfiguration(uuid).battery.answers.length;
                } else if (getComponentConfiguration(uuid).battery.type === 'single') {
                    getComponentConfiguration(uuid).battery.type = 'multiple';
                    getComponentConfiguration(uuid).battery.nbMinExpectedAnswers = 0;
                    getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers = getComponentConfiguration(uuid).battery.answers.length;
                }
                break;
            case "nbMinExpectedAnswers":
                if (value >= 0 && value <= getComponentConfiguration(uuid).battery.answers.length && value <= getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers) {
                    getComponentConfiguration(uuid).battery.nbMinExpectedAnswers = value;
                }
                break;
            case "nbMaxExpectedAnswers":
                if (value >= 0 && value <= getComponentConfiguration(uuid).battery.answers.length && value >= getComponentConfiguration(uuid).battery.nbMinExpectedAnswers) {
                    getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers = value;
                }
                break;
            case "cata":
                if (value >= 1 && value <= 5) {
                    getComponentConfiguration(uuid).battery.nbCatalogColumns = value;
                }
                break;
            case "itemsStyle":
                if (value === 'classic') {
                    getComponentConfiguration(uuid).battery.nbCatalogColumns = 0;
                    getComponentConfiguration(uuid).battery.mode = 'row';
                    getComponentConfiguration(uuid).battery.displayStepper = false;
                } else if (value === 'cata') {
                    getComponentConfiguration(uuid).battery.nbCatalogColumns = 1;
                    getComponentConfiguration(uuid).battery.mode = 'carousel';
                } else if (value === 'slider' || value === 'rating') {
                    getComponentConfiguration(uuid).battery.nbCatalogColumns = 0;
                    getComponentConfiguration(uuid).battery.type = 'single';
                    getComponentConfiguration(uuid).battery.mode = 'carousel';
                    getComponentConfiguration(uuid).battery.shuffleAnswers = false;
                    getComponentConfiguration(uuid).battery.displayOnlyAnswerImages = false;
                } else if (value === 'thisOrThat') {
                    getComponentConfiguration(uuid).battery.nbCatalogColumns = 0;
                    getComponentConfiguration(uuid).battery.type = 'single';
                    getComponentConfiguration(uuid).battery.mode = 'row';
                    getComponentConfiguration(uuid).battery.shuffleAnswers = false;
                    getComponentConfiguration(uuid).battery.displayStepper = false;
                }
                getComponentConfiguration(uuid).battery.itemsStyle = value;
                break;
        }
        setNeedToSave(true);
        forceUpdate();
    };

    const getNextItemRef = () => {
        return computeNextElementRef(getComponentConfiguration(uuid).battery.items, 'I');
    };

    const getNextAnswerRef = () => {
        return computeNextElementRef(getComponentConfiguration(uuid).battery.answers, 'A');
    };

    const findBlockByRefOrUuidAndUsableToSortElements = (refOrUuid) => {
        return surveysService.findBlockByRefOrUuidAndUsableToSortElements(refOrUuid, flattenBlocksForm, uuid);
    };

    const blockUsedAsRefToSortItems = useMemo(() => {
        return findBlockByRefOrUuidAndUsableToSortElements(getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid);
    }, [uuid, getComponentConfiguration(uuid).battery.orderItemsLikeBlockUuid]);

    const handleChangeRefUsedToSortItems = (value) => {
        setRefUsedToSortItems(value);
        let b = findBlockByRefOrUuidAndUsableToSortElements(value);
        changeField("orderItemsLikeBlockUuid", b ? b.uuid : undefined);
    };

    const blockUsedAsRefToSortAnswers = useMemo(() => {
        return findBlockByRefOrUuidAndUsableToSortElements(getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid);
    }, [uuid, getComponentConfiguration(uuid).battery.orderAnswersLikeBlockUuid]);

    const handleChangeRefUsedToSortAnswers = (value) => {
        setRefUsedToSortAnswers(value);
        let b = findBlockByRefOrUuidAndUsableToSortElements(value);
        changeField("orderAnswersLikeBlockUuid", b ? b.uuid : undefined);
    };

    const sortAnswers = () => {
        getComponentConfiguration(uuid).battery.answers.sort((a, b) => ("" + a.text).localeCompare(b.text, undefined, { numeric: true }));
        setNeedToSave(true);
        forceUpdate();
    };

    const sortItems = () => {
        getComponentConfiguration(uuid).battery.items.sort((a, b) => ("" + a.text).localeCompare(b.text, undefined, { numeric: true }));
        setNeedToSave(true);
        forceUpdate();
    };

    const canIMoveUp = () => {
        if (answerIndex === -1 || answerIndex - 1 < 0) {
            return false;
        } else {
            return true;
        }
    };

    const canIMoveDown = () => {
        if (answerIndex === -1 || answerIndex === getComponentConfiguration(uuid).battery.answers.length - 1) {
            return false;
        } else {
            return true;
        }
    };

    const canIMoveUpItem = () => {
        if (itemIndex === -1 || itemIndex - 1 < 0) {
            return false;
        } else {
            return true;
        }
    };

    const canIMoveDownItem = () => {
        if (itemIndex === -1 || itemIndex === getComponentConfiguration(uuid).battery.items.length - 1) {
            return false;
        } else {
            return true;
        }
    };

    const moveAnswerUpward = (event) => {
        event.preventDefault();
        let newIndex = answerIndex - 1;
        let thisItem = getComponentConfiguration(uuid).battery.answers[newIndex + 1];
        getComponentConfiguration(uuid).battery.answers.splice(answerIndex, 1);
        getComponentConfiguration(uuid).battery.answers.splice(newIndex, 0, thisItem);
        setNeedToSave(true);
        setAnswerIndex(answerIndex - 1);
    };

    const moveAnswerDownward = (event) => {
        event.preventDefault();
        let newIndex = answerIndex + 1;
        let thisItem = getComponentConfiguration(uuid).battery.answers[newIndex - 1];
        getComponentConfiguration(uuid).battery.answers.splice(answerIndex, 1);
        getComponentConfiguration(uuid).battery.answers.splice(newIndex, 0, thisItem);
        setNeedToSave(true);
        setAnswerIndex(answerIndex + 1);
    };

    const moveItemDownward = (event) => {
        event.preventDefault();
        let newIndex = itemIndex + 1;
        let thisItem = getComponentConfiguration(uuid).battery.items[newIndex - 1];
        getComponentConfiguration(uuid).battery.items.splice(itemIndex, 1);
        getComponentConfiguration(uuid).battery.items.splice(newIndex, 0, thisItem);
        setItemIndex(itemIndex + 1);
        setNeedToSave(true);
    };

    const moveItemUpward = (event) => {
        event.preventDefault();
        let newIndex = itemIndex - 1;
        let thisItem = getComponentConfiguration(uuid).battery.items[newIndex + 1];
        getComponentConfiguration(uuid).battery.items.splice(itemIndex, 1);
        getComponentConfiguration(uuid).battery.items.splice(newIndex, 0, thisItem);
        setItemIndex(itemIndex - 1);
        setNeedToSave(true);
    };

    const selectAnswer = (subindex) => {
        setAnswerIndex(subindex);
        setItemIndex(-1);
    };

    const selectItem = (subindex) => {
        setItemIndex(subindex);
        setAnswerIndex(-1);
    };

    const deleteAnswer = () => {
        getComponentConfiguration(uuid).battery.answers.splice(answerIndex, 1);
        setAnswerIndex(-1);
        if (getComponentConfiguration(uuid).battery.nbMinExpectedAnswers > getComponentConfiguration(uuid).battery.answers.length) {
            getComponentConfiguration(uuid).battery.nbMinExpectedAnswers = getComponentConfiguration(uuid).battery.answers.length;
        }
        if (getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers > getComponentConfiguration(uuid).battery.answers.length) {
            getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers = getComponentConfiguration(uuid).battery.answers.length;
        }
        forceUpdate();
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const deleteItem = () => {
        getComponentConfiguration(uuid).battery.items.splice(itemIndex, 1);
        setItemIndex(-1);
        forceUpdate();
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const accessParams = (param, subindexParam) => {
        if (subindexParam !== undefined) {
            setItemIndex(subindexParam);
            setAnswerIndex(-1);
        }
        setOpenDialogQuestions(true);
        setTypeOfEditing(param);
    };

    const onSaveAnswerGeneratorCallback = () => {
        setOpenPopupGenerator(false);
        forceUpdate();
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const openGenerator = (param) => {
        setTypeOfGenerator(param);
        setOpenPopupGenerator(true);
    };

    const canShuffleAnswers = () => {
        return getComponentConfiguration(uuid).battery.itemsStyle === "classic" || getComponentConfiguration(uuid).battery.itemsStyle === "cata";
    };

    const getItemContentValidityMark = (subindex) => {
        // if it's not mode thisOrThat => NO SPECIAL MARK
        if(getComponentConfiguration(uuid).battery.itemsStyle !== "thisOrThat") return null;

        let color = "error";
        let title = undefined;

        if (isBlankHtml(getComponentConfiguration(uuid).battery.items[subindex].textLeft) || isBlankHtml(getComponentConfiguration(uuid).battery.items[subindex].textRight)) {
            title = t("react.surveys.thisOrThat.invalid.tooltip");
            color = "error";
        } else {
            title = t("react.surveys.thisOrThat.valid.tooltip");
            color = "success";
        }

        return (
            <div>    
                <Tooltip title={title}>
                    <IconButton className={classes.IconButtonMinMax} size='small'>
                        <CheckIcon color={color} fontSize='small' style={{height: 18}}/>
                    </IconButton>
                </Tooltip> 
            </div>
        );
    };

    return (
        <>
        {/* Define some popup and dialog */}
        <AnswerGeneratorDialog {...props} classes={classes} 
            openPopupGenerator={openPopupGenerator} setOpenPopupGenerator={setOpenPopupGenerator}
            onSaveAnswerGeneratorCallback={onSaveAnswerGeneratorCallback}
            syntaxStructure={typeOfGenerator}
            getNextItemRef={getNextItemRef}
            getNextAnswerRef={getNextAnswerRef}
        />
        <ParamsImage
            {...props}
            uuid={uuid}
            openDialogImage={openDialogImage}
            setOpenDialogImage={setOpenDialogImage}
            enableFullScreenMode={false}
        />
        {(answerIndex !== -1 || itemIndex !== -1) &&
        <ParamsQuestionsBatteryOfItems
            {...props}
            uuid={uuid}
            answerIndex={answerIndex}
            itemIndex={itemIndex}
            openDialogQuestions={openDialogQuestions}
            setOpenDialogQuestions={setOpenDialogQuestions}
            typeOfEditing={typeOfEditing}
        />
        }
        {/* Header of the block */}
        <BlockHeader
            {...props}
            blockRef={getComponentConfiguration(uuid).ref}
            setBlockRef={(newValue) => changeField('ref', newValue)}
            isActive={getComponentConfiguration(uuid).active}
            setIsActive={(newValue) => changeField('enabledBlock', newValue)}
            title={t("react.surveys.battery.title")}
        />
        <Paper className={classes.paddingPaper}>
            <Grid item xs={3} xm={4} className={classes.gridImage}>
                <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                    onMouseOver={e => setUploadButtonVisibility(true)}
                    onMouseOut={e => setUploadButtonVisibility(false)}
                >
                    {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                        <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
                            {!uploading && generateSetImageButton(uuid, setUploading)}
                            {!uploading && generateDeleteImageButton(uuid)}
                            {uploading && <CircularProgress />}
                        </div>
                    </div>}
                    {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
                        <img
                            id={`img_${uuid}`}
                            className={classes.previewImg}
                            src={mediaFiles[getComponentConfiguration(uuid).imageId]}
                            style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                    </div>}
                </div>
            </Grid>
            {getComponentConfiguration(uuid).imageId > 0 &&
                <Button style={{marginBottom: 20}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
                    {t("react.surveys.button.params.image")}
                </Button>
            }
            <TextField
                error={isBlank(getComponentConfiguration(uuid).title)}
                label={`${t("react.project.collectforms.block.stimulusname")} (${(getComponentConfiguration(uuid).title || '').length} / 255)`}
                fullWidth
                value={getComponentConfiguration(uuid).title}
                onChange={e => changeField('title', e.target.value)}
                inputProps={{ maxLength: 255 }}
            ></TextField>
            <QuillEditor
                ref={quillRef}
                value={getComponentConfiguration(uuid).content}
                onChange={(value) => changeField('content', value)}
                style={{height: 300, marginTop: 30, paddingBottom: 42}}
                placeholder= {t("react.surveys.battery.text")}
                {...props}
            />
            <div className={classes.flexFirst}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.project.surveys.mode')}</Typography>
                <FormControl style={{width: 200}}>
                    <Select
                        value={getComponentConfiguration(uuid).battery.itemsStyle}
                        label="Mode"
                        onChange={e => changeField('itemsStyle', e.target.value)}
                    >
                        <MenuItem value={'classic'}>{t('react.surveys.mode.classic')}</MenuItem>
                        <MenuItem value={'cata'}>{t('react.surveys.mode.cata')}</MenuItem>
                        <MenuItem value={'slider'}>{t('react.surveys.mode.slider')}</MenuItem>
                        <MenuItem value={'rating'}>{t('react.surveys.mode.rating')}</MenuItem>
                        <MenuItem value={'thisOrThat'}>{t('react.surveys.mode.thisOrThat')}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.battery.selectMode')}</Typography>
                <FormControl style={{width: 200}}>
                    <Select
                        value={getComponentConfiguration(uuid).battery.mode}
                        label="Mode"
                        onChange={e => changeField('mode', e.target.value)}
                    >
                        <MenuItem value={'row'} disabled={['cata', 'slider', 'rating'].includes(getComponentConfiguration(uuid).battery.itemsStyle)}>{t('react.surveys.battery.selectMode.row')}</MenuItem>
                        <MenuItem value={'column'} disabled={['cata', 'slider', 'rating', 'thisOrThat'].includes(getComponentConfiguration(uuid).battery.itemsStyle)}>{t('react.surveys.battery.selectMode.column')}</MenuItem>
                        <MenuItem value={'carousel'} disabled={['thisOrThat'].includes(getComponentConfiguration(uuid).battery.itemsStyle)}>{t('react.surveys.battery.selectMode.carousel')}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {getComponentConfiguration(uuid).battery.nbCatalogColumns !== 0 && 
                <div className={classes.flexBetweenMinAnswers}>
                    <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.cata.texthelper.columns')}</Typography>
                    <TextField
                        value={getComponentConfiguration(uuid).battery.nbCatalogColumns}
                        onChange={(e) => changeField("cata", e.target.value)}
                        type='number'
                        style={{ width: 40 }}
                    />
                </div>
            }
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.battery.displayStepper')}</Typography>
                <FormControlLabel
                    disabled={getComponentConfiguration(uuid).battery.mode !== "carousel"} 
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={getComponentConfiguration(uuid).battery.displayStepper} color="secondary" onChange={() => changeField('displayStepper')}/>}
                    labelPlacement="start"
                />
            </div>
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.temporisation')}</Typography>
                <FormControlLabel
                    disabled={getComponentConfiguration(uuid).imageId === 0} 
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={activeTempo} color="secondary" onChange={e => changeField('enabledTemporisation', e.target.value)}/>}
                    labelPlacement="start"
                />
            </div>
            {getComponentConfiguration(uuid).timeout !== 0 &&
                <div className={classes.flexBetweenMinAnswers} >
                    <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.timeout')}</Typography>
                    <TextField
                        value={getComponentConfiguration(uuid).timeout}
                        onChange={e => changeField('temporisationDelay', e.target.value)}
                        type='number'
                        style={{ width: 40 }}
                    />
                </div>
            }
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.switch.displayOnlyImage')}</Typography>
                <FormControlLabel
                    disabled={['slider', 'rating', 'thisOrThat'].includes(getComponentConfiguration(uuid).battery.itemsStyle)}
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={getComponentConfiguration(uuid).battery.displayOnlyAnswerImages} color="secondary" onChange={e => changeField('switchDisplayOnlyImages')} />}
                    labelPlacement="start"
                />
            </div>
            <MenuItem className={classes.boxAccordion} onClick={() => setCollapseInstructions(!collapseInstructions)}>
                <Typography style={{marginLeft: -15, marginTop: 5,}}>{t("react.surveys.hotspot.display.instructions")}</Typography>
                {!collapseInstructions ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </MenuItem>
            <Collapse in={collapseInstructions}>
                <TextField
                    variant='outlined'
                    fullWidth
                    multiline={true}
                    minRows='2'
                    label={t("react.surveys.battery.instructions")}
                    helperText={`${(getComponentConfiguration(uuid).battery.instructions || '').length} / 255`}
                    value={getComponentConfiguration(uuid).battery.instructions}
                    onChange={e => changeField('instructions', e.target.value)}
                    inputProps={{ maxLength: 255 }}
                    className={classes.textFieldInstructions}
                />
            </Collapse>
            <Divider style={{paddingTop: '20px', paddingBottom: '10px'}} textAlign="left"><Typography>{t('react.surveys.battery.configure.items')}</Typography></Divider>
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.battery.shuffleItems')}</Typography>
                <FormControlLabel
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={getComponentConfiguration(uuid).battery.shuffleItems} color="secondary" onChange={() => changeField('shuffleItems')}/>}
                    labelPlacement="start"
                />
            </div>
            <Box className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.itemsSameOrderAs')}</Typography>
                <FormControlLabel
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={enableSortItemsByRef} color="secondary" onChange={e => changeField('enableSortItemsByRef', e.target.value)}  />}
                    labelPlacement="start"
                />
            </Box>
            {enableSortItemsByRef &&
                <>
                    <Box className={classes.flexBetweenMinAnswers}>
                        <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.refPreviousBlockOrder')}</Typography>
                        <Box style={{display: 'flex'}}>
                            {!isBlank(refUsedToSortItems) && <Tooltip title={blockUsedAsRefToSortItems ? t('react.surveys.tooltip.answerSameOrderAsExist') : t('react.surveys.tooltip.answerSameOrderAsNotExist')}>
                                <IconButton className={classes.IconButtonMinMax} size='small'>
                                    <CheckIcon color={blockUsedAsRefToSortItems ? "success" : "error"} fontSize='small'/>
                                </IconButton>
                            </Tooltip>}
                            <TextField
                                value={refUsedToSortItems}
                                onChange={(e) => handleChangeRefUsedToSortItems(e.target.value)}
                                className={classes.textFieldRefUsedToSort}
                                error={!isBlank(refUsedToSortItems) && !blockUsedAsRefToSortItems}
                            />
                        </Box>
                    </Box>
                    {blockUsedAsRefToSortItems?.configuration.type === "battery" && <Box className={classes.flexBetween}>
                        <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.itemsSameOrderAs.type')}</Typography>
                        <ButtonGroup size="small" className={classes.buttonGroupAnswerSameOrderAs}>
                            <Button 
                                variant={getComponentConfiguration(uuid).battery.orderItemsLikeBlockField === "answers" ? "contained" : "outlined"} 
                                onClick={() => changeField('orderItemsLikeBlockField', "answers")}>
                                {t('react.surveys.answersSameOrderAs.answers')}
                            </Button>
                            <Button 
                                variant={getComponentConfiguration(uuid).battery.orderItemsLikeBlockField === "items" ? "contained" : "outlined"} 
                                onClick={() => changeField('orderItemsLikeBlockField', "items")}>
                                {t('react.surveys.answersSameOrderAs.items')}
                            </Button>
                        </ButtonGroup>
                    </Box>}
                </>
            }
            <div>
                <List dense subheader={<ListSubheader className={classes.listSubheader}>
                    <Typography variant="subtitle2">{t("react.surveys.battery.list.items")}</Typography>
                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                        <Tooltip title={t("react.surveys.battery.tooltip.generator.items")}>
                            <IconButton
                                onClick={() => openGenerator("battery_items")}
                                className={classes.IconButtonMinMax}
                                component='span'
                                size='small'
                            >
                                <SubjectIcon fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.surveys.battery.tooltip.sort")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                onClick={(event) => sortItems(event)}
                                size='small'
                                disabled={getComponentConfiguration(uuid).battery.items.length === 0}>
                                <SortByAlphaIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.surveys.battery.tooltip.moveDownItem")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={!canIMoveDownItem()}
                                onClick={(event) => moveItemDownward(event)}
                                size='small'>
                                <ArrowDownwardIcon fontSize='small' className={classes.downwardIcon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.surveys.battery.tooltip.moveUpItem")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={!canIMoveUpItem()}
                                onClick={(event) => moveItemUpward(event)}
                                size='small'>
                                <ArrowUpwardIcon fontSize='small' className={classes.upwardIcon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.surveys.battery.tooltip.editItem")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={itemIndex === -1}
                                onClick={() => accessParams('item')}
                                size='small'>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.surveys.battery.tooltip.deleteItem")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={itemIndex === -1}
                                onClick={() => deleteItem()}
                                size='small'>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListSubheader>}>
                    {getComponentConfiguration(uuid).battery.items.map((value, subindex) => {
                        const labelId = `answer-label-${value.text}`;
                        return (
                            <ListItem
                                key={`pq-subindex-${subindex}`}
                                onClick={() => selectItem(subindex)}
                                selected={itemIndex === subindex}
                                button
                            >
                                <ListItemText id={labelId} primary={value.name} style={{width: '100%'}}/>
                                <div className={classes.iconsAnswerPosition}>
                                    {value.fixedPosition && getComponentConfiguration(uuid).battery.shuffleItems && <div>
                                        <Tooltip title={t("react.project.collectforms.fixedPosition.tooltip")}>
                                            <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('item', subindex)}>
                                                <PinIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>}
                                    {value.imageId !== 0 && value.imageId !== undefined && <div>
                                        <Tooltip title={t("react.surveys.battery.tooltip.image")}>
                                            <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('item', subindex)}>
                                                <ImageIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>}
                                    {getItemContentValidityMark(subindex)}
                                </div>
                                <ListItemText primary={value.ref} className={classes.batteryProfileIndex} />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
            <div className={classes.addNewAnswer}>
                <TextField
                    fullWidth
                    multiline={true}
                    label={t("react.project.collectforms.addItem")}
                    value={newItem}
                    onChange={e => setNewItem(e.target.value)}
                ></TextField>
                <Button className={classes.addNewAnswerButton} disabled={newItem.length > 255} onClick={e => changeField('items')} size='small'><AddIcon fontSize='small' /></Button>
            </div> 
            <Divider style={{paddingTop: '20px', paddingBottom: '10px'}} textAlign="left"><Typography>{t('react.surveys.battery.configure.answers')}</Typography></Divider>
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.switch.singleOrMultiple')}</Typography>
                <FormControlLabel
                    disabled={['slider', 'rating', 'thisOrThat'].includes(getComponentConfiguration(uuid).battery.itemsStyle)}
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={getComponentConfiguration(uuid).battery.type === "multiple"} color="secondary" onChange={() => changeField('type')}/>}
                    labelPlacement="start"
                />
            </div>
            {getComponentConfiguration(uuid).battery.type === "multiple" &&
                <>
                    <div className={classes.flexBetweenMinAnswers} >
                        <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.minimum.answers.number')}</Typography>
                        <TextField
                            value={getComponentConfiguration(uuid).battery.nbMinExpectedAnswers}
                            onChange={(e) => changeField("nbMinExpectedAnswers", e.target.value)}
                            type='number'
                            style={{ width: 40 }}
                        />
                    </div>
                    <div className={classes.flexBetweenMinAnswers} >
                        <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.maximum.answers.number')}</Typography>
                        <TextField
                            value={getComponentConfiguration(uuid).battery.nbMaxExpectedAnswers}
                            onChange={(e) => changeField("nbMaxExpectedAnswers", e.target.value)}
                            type='number'
                            style={{ width: 40 }}
                        />
                    </div>
                </>
            }
            <div className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.battery.shuffleAnswers')}</Typography>
                <FormControlLabel
                    disabled={!canShuffleAnswers()} 
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={getComponentConfiguration(uuid).battery.shuffleAnswers} color="secondary" onChange={() => changeField('shuffeAnswers')}/>}
                    labelPlacement="start"
                />
            </div>
            <Box className={classes.flexBetween}>
                <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.answersSameOrderAs')}</Typography>
                <FormControlLabel
                    className={classes.switchSingleOrMultiple}
                    control={<Switch checked={enableSortAnswersByRef} color="secondary" onChange={e => changeField('enableSortAnswersByRef', e.target.value)}  />}
                    labelPlacement="start"
                />
            </Box>
            {enableSortAnswersByRef &&
                <>
                    <Box className={classes.flexBetweenMinAnswers}>
                        <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.refPreviousBlockOrder')}</Typography>
                        <Box className={classes.flex}>
                            {!isBlank(refUsedToSortAnswers) && <Tooltip title={blockUsedAsRefToSortAnswers ? t('react.surveys.tooltip.answerSameOrderAsExist') : t('react.surveys.tooltip.answerSameOrderAsNotExist')}>
                                <IconButton className={classes.IconButtonMinMax} size='small'>
                                    <CheckIcon color={blockUsedAsRefToSortAnswers ? "success" : "error"} fontSize='small'/>
                                </IconButton>
                            </Tooltip>}
                            <TextField
                                value={refUsedToSortAnswers}
                                onChange={(e) => handleChangeRefUsedToSortAnswers(e.target.value)}
                                className={classes.textFieldRefUsedToSort}
                                error={!isBlank(refUsedToSortAnswers) && !blockUsedAsRefToSortAnswers}
                            />
                        </Box>
                    </Box>
                    {blockUsedAsRefToSortAnswers?.configuration.type === "battery" && <Box className={classes.flexBetween}>
                        <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.switch.answersSameOrderAs.type')}</Typography>
                        <ButtonGroup size="small" className={classes.buttonGroupAnswerSameOrderAs}>
                            <Button 
                                variant={getComponentConfiguration(uuid).battery.orderAnswersLikeBlockField === "answers" ? "contained" : "outlined"} 
                                onClick={() => changeField('orderAnswersLikeBlockField', "answers")}>
                                {t('react.surveys.answersSameOrderAs.answers')}
                            </Button>
                            <Button 
                                variant={getComponentConfiguration(uuid).battery.orderAnswersLikeBlockField === "items" ? "contained" : "outlined"} 
                                onClick={() => changeField('orderAnswersLikeBlockField', "items")}>
                                {t('react.surveys.answersSameOrderAs.items')}
                            </Button>
                        </ButtonGroup>
                    </Box>}
                </>
            }
            <div>
                <List dense subheader={<ListSubheader className={classes.listSubheader}>
                    <Typography variant="subtitle2">{t("react.project.collectforms.answers")}</Typography>
                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                        <Tooltip title={t("react.project.collectforms.customanswer.tooltip")}>
                            <IconButton
                                onClick={() => openGenerator("battery_answers")}
                                className={classes.IconButtonMinMax}
                                component='span'
                                size='small'
                            >
                                <SubjectIcon fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.sort.tooltip")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                onClick={(event) => sortAnswers(event)}
                                size='small'
                                disabled={getComponentConfiguration(uuid).battery.answers.length === 0}>
                                <SortByAlphaIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.moveDown.tooltip")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={!canIMoveDown()}
                                onClick={(event) => moveAnswerDownward(event)}
                                size='small'>
                                <ArrowDownwardIcon fontSize='small' className={classes.downwardIcon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.moveUp.tooltip")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={!canIMoveUp()}
                                onClick={(event) => moveAnswerUpward(event)}
                                size='small'>
                                <ArrowUpwardIcon fontSize='small' className={classes.upwardIcon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.nextBlock.tooltip")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={answerIndex === -1}
                                onClick={() => accessParams('answer')}
                                size='small'>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.deleteAnswer.tooltip")}>
                            <IconButton
                                className={classes.IconButtonMinMax}
                                component='span'
                                disabled={answerIndex === -1}
                                onClick={() => deleteAnswer()}
                                size='small'>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListSubheader>}>
                    {getComponentConfiguration(uuid).battery.answers.map((value, subindex) => {
                        const labelId = `answer-label-${value.answer}`;
                        return (
                            <ListItem
                                key={`pq-subindex-${subindex}`}
                                onClick={() => selectAnswer(subindex)}
                                selected={answerIndex === subindex}
                                button
                            >
                                <ListItemText id={labelId} primary={value.text} style={{width: '100%'}}/>
                                {value.imageId > 0 && <div>
                                    <Tooltip title={t("react.surveys.profileQuestion.tooltip.image")}>
                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('answer')}>
                                            <ImageIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                                {value.fixedPosition && getComponentConfiguration(uuid).battery.shuffleAnswers && <div>
                                    <Tooltip title={t("react.project.collectforms.fixedPosition.tooltip")}>
                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('answer')}>
                                            <PinIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                                {value.screenOut && <div>
                                    <Tooltip title={t("react.project.collectforms.screenOut.tooltip")}>
                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('answer')}>
                                            <RedoIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                                {value.freeField && <div>
                                    <Tooltip title={t("react.project.collectforms.freeField.tooltip")}>
                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('answer')}>
                                            <TextFieldsIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                                {getComponentConfiguration(uuid).battery.type === "multiple" && value.exclusive && <div>
                                    <Tooltip title={t("react.project.collectforms.exclusive.tooltip")}>
                                        <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParams('answer')}>
                                            <GradeIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>}

                                <ListItemText primary={value.ref} className={classes.batteryProfileIndex} />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
            <div className={classes.addNewAnswer}>
                <TextField
                    fullWidth
                    multiline={true}
                    label={t("react.project.collectforms.addanswer")}
                    value={newAnswer}
                    onChange={e => setNewAnswer(e.target.value)}
                ></TextField>
                <Button className={classes.addNewAnswerButton} disabled={newAnswer.length > 255} onClick={e => changeField('answers')} size='small'><AddIcon fontSize='small' /></Button>
            </div>
        </Paper>
        </>
    );
}
