import { useState, useContext, useReducer, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { SurveyContext } from './context';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { isBlank } from '../../utils/utils.js';
import { isBlankHtml, computeNextElementRef } from '../../utils/surveysUtils.js';
import { TextField } from '@mui/material';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import ListSubheader from '@mui/material/ListSubheader';
import DrawIcon from '@mui/icons-material/Draw';
import Slider from '@mui/material/Slider';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import { CompactPicker } from 'react-color'
import Popover from '@mui/material/Popover';
import SquareIcon from '@mui/icons-material/Square';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListItem from '@mui/material/ListItem';
import ParamsQuestionsHotSpot from './ParamsQuestionsHotSpot.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import i18next from 'i18next';
import QuillEditor from './QuillEditor.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import RedoIcon from '@mui/icons-material/Redo';
import BlockHeader from './BlockHeader.js';

const useStyles = makeStyles()(theme => ({
  sliderHelp: {
    opacity: 0.8, 
    fontStyle: 'italic', 
    fontSize: 14
  },
  previewRoot: {
    marginBottom: '25px',
    margin: '0',
    padding: '5px',
    width: `250px`,
    height: `150px`,
    display: 'inline-block',
    borderStyle: 'dotted',
    borderRadius: '7px',
  },
  previewImg: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '140px',
    position: 'relative',
    top: '-50px'
  },
  imgHotspot: {
    textAlign: 'center',
    verticalAlign: 'middle',
    position: 'relative',
    border: '1px solid rgb(100,100,100)',
    cursor: 'crosshair',
    /* avoid double click selection */
    userSelect: 'none',  /* standard syntax */
    WebkitUserSelect: 'none',  /* webkit (safari, chrome) browsers */
    MozUserSelect: 'none',     /* mozilla browsers */
    KhtmlUserSelect: 'none',   /* webkit (konqueror) browsers */
    msUserSelect: 'none'       /* IE10+ */
  },
  previewBtns: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    height: '50px',
    position: 'relative', top: '25%', zIndex: '100'
  },
  previewFullscreen: {
    margin: '0px 0 15px 0',
    textWrap: 'nowrap'
  },
  alignCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '200px'
  },
  paddingPaper: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 1,
    paddingBottom: 30
  },
  gridImage: {
    marginTop: 20,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  spaceBetweenList: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px 10px 0px 15px'
  },
  styleDivButton: {
    marginTop: '0px', 
    display: 'flex', 
    justifyContent: 'flex-end'
  },
  listSubheader: {
    paddingLeft: '0px'
  },
  MuiPopoverPaper: {
    padding: '10px',
    backgroundColor: 'unset',
    boxShadow: 'unset',
    marginTop: '-10px',
    marginLeft: '-10px'
  },
  flexBetweenMinAnswers: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10
  },
  listSubheaderQuestions: {
    marginTop: 15,
    marginBottom: 10,
    paddingLeft: 0,
    marginRight: 15
  },
  listItemSecondaryAction: {
    margin: 0,
    padding: 0,
    right: 2,
  },
  IconButtonMinMax: {
    minWidth: '30px',
    maxWidth: '30px',
  },
  addNewAnswer: {
    paddingLeft: '5px',
    display: 'flex',
  },
  addNewAnswerButton: {
    right: -15,
    marginTop: '12px'
  },
  iconsAnswerPosition: {
    display: 'flex',
    marginRight: -12,
  },
  hotspotProfileIndex: {
    color: "#BBB",
    fontStyle: "italic",
    width: '30px'
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listItem: {
    paddingLeft: 5,
    paddingTop: 3,
    paddingRight: 14,
    paddingBottom: 3,
  },
  answerRef: {
    color: "#BBB",
    fontStyle: "italic",
    width: '30px',
    marginLeft: 20,
  }
}));

export default function HotSpot(props) {

  const { classes } = useStyles();

  const { t, uuid } = props

  const { formIdParameter, generateSetImageButton, generateDeleteImageButton, notifyStructuralChange,
    setNeedToSave, getComponentConfiguration, mediaFiles, getFormConfiguration } = useContext(SurveyContext);

  const [uploading, setUploading] = useState(false);
  const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [addPolygon, setAddPolygon] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState(-1);
  const [openPopupHotspot, setOpenPopupHotspot] = useState(false);
  const [openDialogQuestions, setOpenDialogQuestions] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(-1);
  const [newAnswer, setNewAnswer] = useState('');
  const [hasGroupsOfZones, setHasGroupsOfZones] = useState(false);
  const svgRef = useRef(null);

  const quillRef = useRef();

  useEffect(() => {
    setHasGroupsOfZones(!getComponentConfiguration(uuid).hotspot.zones.every(z => z.groupId === 0));
    getComponentConfiguration(uuid).hotspot.zones.forEach(element => {
      // create an array of cleaned points
      const points = element.coordinates
        .split(" ")
        .map(it => it.split(","))
        .filter(arr => arr.length === 2)
        .map(point => [Number.parseInt(point[0]),Number.parseInt(point[1])]);

      element.points = points;
    });
  }, [uuid]);

  const changeField = (fieldName, value, indexParam) => {
    switch (fieldName) {
      case "ref":
        getComponentConfiguration(uuid).ref = value;
        break;
      case "title":
        getComponentConfiguration(uuid).title = value;
        break;
      case "content":
        getComponentConfiguration(uuid).content = value;
        break;
      case "enabledBlock":
        getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
        break;
      case "changeWidth":
        getComponentConfiguration(uuid).hotspot.zones = [];
        getComponentConfiguration(uuid).hotspot.displayWidth = value;
        notifyStructuralChange(/* when cleaning zones of an Hotspot => it's a structural change to notify */);
        break;
      case "changeNameZone":
        getComponentConfiguration(uuid).hotspot.zones[indexParam].name = value;
        break;
      case "displayExclusiveCheckbox":
        if (!getComponentConfiguration(uuid).hotspot.displayExclusiveCheckbox) {
          getComponentConfiguration(uuid).hotspot.displayExclusiveCheckbox = true;
          if (isBlank(getComponentConfiguration(uuid).hotspot.exclusiveCheckboxText)) {
            // we use the language of the form and not the user
            getComponentConfiguration(uuid).hotspot.exclusiveCheckboxText = getTranslationForLanguage('react.collectforms.hotspot.validateNoAnswer.text', getFormConfiguration().lang);
          }
        } else {
          getComponentConfiguration(uuid).hotspot.displayExclusiveCheckbox = false;
        }
        break;
      case "exclusiveCheckboxText":
        getComponentConfiguration(uuid).hotspot.exclusiveCheckboxText = value;
        break;
      case "nbMinExpectedAnswers":
        if (value >= 0 && value <= getComponentConfiguration(uuid).hotspot.zones.length && value <= getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers) {
          getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers = value;
        }
        break;
      case "nbMaxExpectedAnswers":
        if (value >= 0 && value <= getComponentConfiguration(uuid).hotspot.zones.length && value >= getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers) {
          getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers = value;
        }
        break;
      case "type":
        if (getComponentConfiguration(uuid).hotspot.type === 'multiple') {
          getComponentConfiguration(uuid).hotspot.type = 'single';
          getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers = 1;
          getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers = getComponentConfiguration(uuid).hotspot.zones.length;
        } else if (getComponentConfiguration(uuid).hotspot.type === 'single') {
          getComponentConfiguration(uuid).hotspot.type = 'multiple';
          getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers = 0;
          getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers = getComponentConfiguration(uuid).hotspot.zones.length;
        }
        break;
      case "answers":
        const foundAnswer = getComponentConfiguration(uuid).hotspot.answers.find(obj => obj.answer === newAnswer);
        if (newAnswer !== undefined && !isBlank(newAnswer) && foundAnswer === undefined) {
            getComponentConfiguration(uuid).hotspot.answers.push({
                name: newAnswer,
                uuid: uuidv4(),
                color: "#68BC00"+'80',
                strokeWidth: 2,
                ref: getNextAnswerRef()
            });
            setNewAnswer('');
        }
        break;
      case "strokeWidth":
        if(value >= 0) {
          getComponentConfiguration(uuid).hotspot.zones[indexParam].strokeWidth = value;
        }
        break;
    }
    setNeedToSave(true);
    forceUpdate();
  };

  const getNextZoneRef = () => {
    return computeNextElementRef(getComponentConfiguration(uuid).hotspot.zones, 'Z');
  };

  const getNextAnswerRef = () => {
    return computeNextElementRef(getComponentConfiguration(uuid).hotspot.answers, 'A');
  };

  const getTranslationForLanguage = (key, language) => {
    return i18next.t(key, { lng: language });
  };

  const canIMoveUp = () => {
    if (answerIndex === -1 || answerIndex - 1 < 0) {
        return false;
    } else {
        return true;
    }
  };

  const canIMoveDown = () => {
    if (answerIndex === -1 || answerIndex === getComponentConfiguration(uuid).hotspot.answers.length - 1) {
        return false;
    } else {
        return true;
    }
  };

  const moveAnswerUpward = (event) => {
    event.preventDefault();
    let newIndex = answerIndex - 1;
    let thisItem = getComponentConfiguration(uuid).hotspot.answers[newIndex + 1];
    getComponentConfiguration(uuid).hotspot.answers.splice(answerIndex, 1);
    getComponentConfiguration(uuid).hotspot.answers.splice(newIndex, 0, thisItem);
    setNeedToSave(true);
    setAnswerIndex(answerIndex - 1);
  };

const moveAnswerDownward = (event) => {
    event.preventDefault();
    let newIndex = answerIndex + 1;
    let thisItem = getComponentConfiguration(uuid).hotspot.answers[newIndex - 1];
    getComponentConfiguration(uuid).hotspot.answers.splice(answerIndex, 1);
    getComponentConfiguration(uuid).hotspot.answers.splice(newIndex, 0, thisItem);
    setNeedToSave(true);
    setAnswerIndex(answerIndex + 1);
  };

  const accessParamsQuestion = (param) => {
    setOpenDialogQuestions(true);
  };

  const deleteAnswer = () => {
    getComponentConfiguration(uuid).hotspot.answers.splice(answerIndex, 1);
    setAnswerIndex(-1);
    forceUpdate();
    setNeedToSave(true);
    notifyStructuralChange();
  };

  const selectAnswer = (subindex) => {
    setAnswerIndex(subindex);
  };

  const marks = [
    {
      value: 600,
      label: '600px',
    },
    {
      value: 700,
      label: '700px',
    },
    {
      value: 800,
      label: '800px',
    },
    {
      value: 900,
      label: '900px',
    },
    {
      value: 1000,
      label: '1000px',
    },
    {
      value: 1100,
      label: '1100px',
    },
    {
      value: 1200,
      label: '1200px',
    },
  ];

  const clickOnImg = (value) => {
    if (addPolygon) {
      const boundingRect = svgRef.current.getBoundingClientRect();
      const x = value.clientX - boundingRect.left;
      const y = value.clientY - boundingRect.top;

      let lastIndex = getComponentConfiguration(uuid).hotspot.zones.length - 1;
      getComponentConfiguration(uuid).hotspot.zones[lastIndex].points.push([x, y]);

      forceUpdate();
      setNeedToSave(true);
    }
  };

  const createNewPolygon = () => {
    if (addPolygon === false) {
      getComponentConfiguration(uuid).hotspot.zones.push(
        {
          coordinates : "", 
          points: [], // array of points [[1, 2], [3, 4]]
          uuid: uuidv4(), 
          isFinished: false, 
          color: "#7B64FF80", 
          name: `zone ${getComponentConfiguration(uuid).hotspot.zones.length + 1}`,
          strokeWidth: 2,
          ref: getNextZoneRef()
        });
      setAddPolygon(true);
    } 
  };

  const deletePolygon = (value) => {
    getComponentConfiguration(uuid).hotspot.zones = getComponentConfiguration(uuid).hotspot.zones.filter(a => a.uuid !== value)
    adjustGroupIds();
    forceUpdate();
    setNeedToSave(true);
    notifyStructuralChange();
  };

  const adjustGroupIds = () => {
    const zones = getComponentConfiguration(uuid).hotspot.zones;
    const groupMap = new Map();
    let newGroupId = 1;

    zones.forEach(zone => {
        if (zone.groupId !== 0) {
            if (!groupMap.has(zone.groupId)) {
                groupMap.set(zone.groupId, newGroupId++);
            }
            zone.groupId = groupMap.get(zone.groupId);
        }
    });
  };

  const validateNewPolygon = () => {
    let newPolygonIndex = [getComponentConfiguration(uuid).hotspot.zones.length - 1];

    if(getComponentConfiguration(uuid).hotspot.zones[newPolygonIndex].points.length <= 2) {
      return;
    }

    setAddPolygon(false);

    getComponentConfiguration(uuid).hotspot.zones[newPolygonIndex].isFinished = true;
    getComponentConfiguration(uuid).hotspot.zones[newPolygonIndex].groupId = hasGroupsOfZones ? getComponentConfiguration(uuid).hotspot.zones.length : 0;
    setSelectedPolygon(newPolygonIndex);

    forceUpdate();
    setNeedToSave(true);
  };

  const cancelNewPolygon = () => {
    getComponentConfiguration(uuid).hotspot.zones.pop();
    setAddPolygon(false);
  }

  const handleClickPolygon = (value, polygon) => {  
    if (addPolygon === false) {
      setSelectedPolygon(getComponentConfiguration(uuid).hotspot.zones.findIndex(p => p.uuid === polygon.uuid));
    } 
  };

  const handleClickOpen = () => {
    setOpenPopupHotspot(true);
  };

  const handleClose = () => {
    setOpenPopupHotspot(false);
  };

  const handleValid = () => {
    getComponentConfiguration(uuid).hotspot.zones.forEach(polygon => {
      // update / generate the coordinates from the array of cleaned points
      polygon.coordinates = polygon.points
        .map(point => point[0]+','+point[1])
        .join(" ");
    });
    
    if (getComponentConfiguration(uuid).hotspot.zones.length < getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers) {
      getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers = getComponentConfiguration(uuid).hotspot.zones.length;
    }
    if (getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers === 0 || getComponentConfiguration(uuid).hotspot.zones.length < getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers) {
      getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers = getComponentConfiguration(uuid).hotspot.zones.length;
    }
    setOpenPopupHotspot(false);
  };

  const renderPolygon = (index, polygon) => {
    if(polygon.points == undefined || polygon.points.length === 0)  return null;

    const coordinates = polygon.points
      .map(point => point[0]+','+point[1])
      .join(" ");

      return (
      <g key={`g-${index}`}>
        <polygon
          key={`p-${index}`}
          points={coordinates}
          fill={polygon.color}
          onClick={(e) => handleClickPolygon(e, polygon)}
          strokeDasharray={polygon.strokeWidth > 0 ? 0 : 2}
          style={{ stroke: selectedPolygon === index ? 'orange' : 'white', strokeWidth: '2px', cursor: addPolygon ? "crosshair" : "pointer" }}
        />
        {polygon.points.map((point, pointIndex) => {
          return (<circle 
              key={`c-${index}-${pointIndex}`} 
              cx={point[0]} 
              cy={point[1]} 
              r={4} 
              onMouseDown={(e) => startDrag(e, index, pointIndex)}
              style={{fill: 'orange' , stroke: 'white', strokeWidth: '2px', cursor: addPolygon ? "crosshair" : 'move'}} />)
        })
        }
      </g>
    )
  };

  // -- color picker popover ------------------------------------------

  const [zoneIndexToChangeColor, setZoneIndexToChangeColor] = useState(undefined);
  const [answerIndexToChangeColor, setAnswerIndexToChangeColor] = useState(undefined);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverAnchorAnswerEl, setPopoverAnchorAnswerEl] = useState(null);

  const handleClickPopover = (event, index) => {
    setZoneIndexToChangeColor(index);
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClickPopoverAnswer = (event, index) => {
    setAnswerIndexToChangeColor(index);
    setPopoverAnchorAnswerEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setZoneIndexToChangeColor(undefined);
    setPopoverAnchorEl(null);
  };

  const handleClosePopoverAnswer = () => {
    setAnswerIndexToChangeColor(undefined);
    setPopoverAnchorAnswerEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverOpenAnswer = Boolean(popoverAnchorAnswerEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;
  const popoverIdAnswer = popoverOpenAnswer ? 'simple-popover' : undefined;

  const changeColor = (value) => {
    if (selectedPolygon !== -1) {
      getComponentConfiguration(uuid).hotspot.zones[zoneIndexToChangeColor].color = value.hex+'80';
    }
    forceUpdate();
    setNeedToSave(true);
    handleClosePopover();
  };

  const changeColorAnswer = (value) => {
    getComponentConfiguration(uuid).hotspot.answers[answerIndexToChangeColor].color = value.hex+'80';
    forceUpdate();
    setNeedToSave(true);
    handleClosePopover();
    setAnswerIndex(undefined);
    setPopoverAnchorAnswerEl(null);
  };

  // -- Drag&Drop of circle ------------------------------------------

  const [dragging, setDragging] = useState(undefined);

  const startDrag = (e, polygonIndex, pointIndex) => {
    if (addPolygon) return;

    setDragging([polygonIndex, pointIndex]);

    const boundingRect = svgRef.current.getBoundingClientRect();
    const offsetX = e.clientX - boundingRect.left;
    const offsetY = e.clientY - boundingRect.top;

    getComponentConfiguration(uuid).hotspot.zones[polygonIndex].points[pointIndex] = [offsetX, offsetY];
    setNeedToSave(true);
    forceUpdate();
  };

  const endDrag = (e) => {
    if (addPolygon) return;

    setDragging(undefined);
  };

  const drag = (e) => {
    if (addPolygon) return;

    if (dragging) {
      const polygonIndex = dragging[0];
      const pointIndex = dragging[1];

      const boundingRect = svgRef.current.getBoundingClientRect();
      const offsetX = e.clientX - boundingRect.left;
      const offsetY = e.clientY - boundingRect.top;

      getComponentConfiguration(uuid).hotspot.zones[polygonIndex].points[pointIndex] = [offsetX, offsetY];
      setNeedToSave(true);
      forceUpdate();
    }
  };

  const getColorHex = () => {
    if (getComponentConfiguration(uuid).hotspot.zones[zoneIndexToChangeColor] !== undefined && getComponentConfiguration(uuid).hotspot.zones[zoneIndexToChangeColor].color !== undefined) {
      return getComponentConfiguration(uuid).hotspot.zones[zoneIndexToChangeColor].color;
    }
  }

  const getColorAnswerHex = () => {
    if (getComponentConfiguration(uuid).hotspot.answers[answerIndexToChangeColor] !== undefined && getComponentConfiguration(uuid).hotspot.answers[answerIndexToChangeColor].color !== undefined) {
      return getComponentConfiguration(uuid).hotspot.answers[answerIndexToChangeColor].color;
    }
  }

  const addDefaultText = (event) => {
    event.preventDefault();
    const content = getTranslationForLanguage('react.survey.hotspot.placeholder', getFormConfiguration().lang);
    getComponentConfiguration(uuid).content = content;
    quillRef.current.clipboard.dangerouslyPasteHTML(content);
    setNeedToSave(true);
    forceUpdate();
  }

  const clickOnGroupage = () => {
    if (getComponentConfiguration(uuid).hotspot.zones.every(z => z.groupId === 0)) {
      getComponentConfiguration(uuid).hotspot.zones.forEach((z, index) => z.groupId = index+1);
    } else {
      getComponentConfiguration(uuid).hotspot.zones.forEach(z => z.groupId = 0);
    }
    setHasGroupsOfZones(!hasGroupsOfZones);
    setNeedToSave(true);
    forceUpdate();
  }

  const changeGroupId = (index, subindex) => {
    getComponentConfiguration(uuid).hotspot.zones[index].groupId = subindex;
    setNeedToSave(true);
    forceUpdate();
  }

  return (
    <>
      {/* Define some popup and dialog */}
      <ParamsQuestionsHotSpot
        {...props}
        uuid={uuid}
        answerIndex={answerIndex}
        openDialogQuestions={openDialogQuestions}
        setOpenDialogQuestions={setOpenDialogQuestions}
      />
      <Dialog open={openPopupHotspot} fullWidth={true} maxWidth={"xl"} onClose={handleClose}>
        <DialogTitleWithCloseIcon
          title={t('react.surveys.hotspot.manage.zones')}
          callbackOnclose={handleClose}
        />
        <DialogContent>
          <Popover 
            id={popoverId}
            open={popoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{ paper : classes.MuiPopoverPaper}}
          >
            <CompactPicker height={386} onChange={changeColor} color={getColorHex()} />
          </Popover>          
          <Grid container>
                <Grid item xs={6}>
                  <Typography>{t("react.collectors.hotspot.img.buttons")}</Typography>
                  <Typography className={classes.sliderHelp}>{t("react.collectors.hotspot.img.buttons.help")}</Typography>
                  <Slider
                    onChange={(e) => changeField("changeWidth", e.target.value)}
                    disabled={addPolygon}
                    value={getComponentConfiguration(uuid).hotspot.displayWidth}
                    style={{width: 400}}
                    step={100}
                    marks={marks}
                    min={600}
                    max={1200}
                  />
                </Grid>
                <Grid item xs={6}>
                  <List subheader={
                    <ListSubheader component="div" className={classes.listSubheader}>
                      <div className={classes.styleDivButton}>
                          <Typography variant="h6" style={{width: '100%', lineHeight:'inherit'}}>{t("react.collectors.hotspot.list.area")}</Typography>
                          <div style={{textWrap: 'nowrap'}}>
                          <Button 
                            variant={hasGroupsOfZones ? "contained" : "outlined"}
                            onClick={() => clickOnGroupage()}
                            style={{marginRight: 5}}
                          >
                            {t('react.collectors.hotspot.group.button')}
                          </Button>
                          <Button 
                            variant={addPolygon ? "contained" : "outlined"} 
                            color={addPolygon ? "success" : "primary"}
                            onClick={() => addPolygon ? validateNewPolygon() : createNewPolygon()} 
                            startIcon={addPolygon ? <CreditScoreIcon /> : <AddCardIcon/>}>
                            {t(addPolygon ? 'react.collectors.hotspot.finish.area' : 'react.collectors.hotspot.add.area')}
                          </Button>
                          <Button sx={{marginLeft: '5px'}} disabled={addPolygon === false} variant="outlined" onClick={() => cancelNewPolygon()}>
                            {t('react.button.cancel')}
                          </Button>
                          </div>
                      </div>
                    </ListSubheader>
                  }>
                  {getComponentConfiguration(uuid).hotspot.zones.filter(p => p.isFinished !== false).map((p, index) => (
                    <ListItemButton onClick={() => setSelectedPolygon(index)} selected={selectedPolygon === index} key={index} className={classes.spaceBetweenList}>
                          {selectedPolygon === index ? 
                            <TextField 
                              value={p.name}
                              onChange={(e) => changeField("changeNameZone", e.target.value, index )}
                              autoFocus={true}
                              /> :                             
                            <ListItemText primary={p.name} />
                          }
                        <span style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
                          <Tooltip title={t('react.surveys.hotspot.zone.button.delete')}>
                            <IconButton onClick={() => deletePolygon(p.uuid)}><DeleteIcon/></IconButton>
                          </Tooltip>
                          <Tooltip title={t(p.strokeWidth === 0 ? 'react.surveys.hotspot.zone.button.enable.border' : 'react.surveys.hotspot.zone.button.disable.border')}>
                            <IconButton onClick={() => changeField('strokeWidth', p.strokeWidth === 0 ? 2 : 0, index)}>
                              {p.strokeWidth === 0 ? <BorderClearIcon/> : <BorderOuterIcon/>} 
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('react.surveys.hotspot.zone.button.color')}>
                            <IconButton aria-describedby={popoverId} variant="contained" onClick={(e) => handleClickPopover(e, index)}>
                              <SquareIcon sx={{color: p.color}}/>
                            </IconButton>
                          </Tooltip>
                          <ListItemText primary={p.ref} className={classes.hotspotProfileIndex} />
                          {hasGroupsOfZones &&
                            <FormControl style={{width: 50, marginRight: 10}}>
                              <Select value={p.groupId}>
                                {getComponentConfiguration(uuid).hotspot.zones.map((item, subindex) => {
                                  return (
                                    <MenuItem key={subindex} onClick={(e) => changeGroupId(index, subindex+1)} value={subindex+1}>{subindex+1}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          }
                        </span>                           
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </Grid>                
        {getComponentConfiguration(uuid).imageId > 0 && 
        <Grid container style={{marginTop: 20, display: 'flex', flexDirection: "column"}}>    
          <div style={{width: "100%", position: 'relative', display: "flex", justifyContent:'center'}}>
            <img
              className={classes.imgHotspot}
              style={{width:`${getComponentConfiguration(uuid).hotspot.displayWidth}px`}}
              src={mediaFiles[getComponentConfiguration(uuid).imageId]}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={`${getComponentConfiguration(uuid).hotspot.displayWidth}px`}
              height="100%"
              style={{ position: 'absolute', top: 0, left: `calc(50% - ${getComponentConfiguration(uuid).hotspot.displayWidth/2}px)`, cursor: addPolygon ? "crosshair" : "pointer" }}
              onClick={(e) => clickOnImg(e)}
              ref={svgRef}
              onMouseMove={drag}
              onMouseUp={endDrag}
            >
              {getComponentConfiguration(uuid).hotspot.zones.map((polygon, index) => {
                  return renderPolygon(index, polygon)})
              }
            </svg>
          </div>
        </Grid>
      }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValid}>{t('react.button.valid')}</Button>
        </DialogActions>
      </Dialog>
      {/* Header of the block */}
      <BlockHeader
          {...props}
          blockRef={getComponentConfiguration(uuid).ref}
          setBlockRef={(newValue) => changeField('ref', newValue)}
          isActive={getComponentConfiguration(uuid).active}
          setIsActive={(newValue) => changeField('enabledBlock', newValue)}
          title={t("react.project.collectforms.block.hotspot.title")}
      />
    <Paper className={classes.paddingPaper}>
      <Grid item xs={3} xm={4} className={classes.gridImage}>
        <div id={`preview_img_${uuid}`} className={classes.previewRoot}
          onMouseOver={e => setUploadButtonVisibility(true)}
          onMouseOut={e => setUploadButtonVisibility(false)}
        >
          {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
            <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
              {!uploading && generateSetImageButton(uuid, setUploading)}
              {!uploading && generateDeleteImageButton(uuid)}
              {uploading && <CircularProgress />}
            </div>
          </div>}
          {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
            <img
              id={`img_${uuid}`}
              className={classes.previewImg}
              src={mediaFiles[getComponentConfiguration(uuid).imageId]}
              style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
          </div>}
        </div>
      </Grid>
      <Button endIcon={<DrawIcon />} disabled={getComponentConfiguration(uuid).imageId === 0} variant="contained" onClick={handleClickOpen} style={{marginBottom: 20, marginTop: 20}}>
       {t("react.surveys.hotspot.manage.zones")}
      </Button>
      <TextField
        label={`${t("react.project.collectforms.block.stimulusname")} (${(getComponentConfiguration(uuid).title || '').length} / 255)`}
        fullWidth
        value={getComponentConfiguration(uuid).title}
        error={isBlank(getComponentConfiguration(uuid).title)}
        onChange={e => changeField('title', e.target.value)}
        inputProps={{ maxLength: 255 }}
      ></TextField>
      <div style={{position: 'relative'}}>
        <QuillEditor
          ref={quillRef}
          bottomRightButton={{tooltip: t("react.surveys.hotspot.tooltip.text"), icon: <AddCircleIcon/>, callback: addDefaultText}}
          value={getComponentConfiguration(uuid).content}
          onChange={(value) => changeField('content', value)}
          isError={isBlankHtml(getComponentConfiguration(uuid).content)}
          style={{height: 400, marginTop: 30, paddingBottom: 42}}
          placeholder= {t("react.project.collectforms.content")}
          {...props}
        />
      </div>
      <Grid className={classes.flexBetween}>
        <Typography className={classes.labelSwitchQuestion}>{t('react.project.collectforms.switch.singleOrMultiple')}</Typography>
        <FormControlLabel
          className={classes.switchSingleOrMultiple}
          control={<Switch checked={getComponentConfiguration(uuid).hotspot.type === "multiple"} color="secondary" onChange={() => changeField('type')}/>}
          labelPlacement="start"
        />
      </Grid>
      {getComponentConfiguration(uuid).hotspot.type === "multiple" &&
        <>
          <div className={classes.flexBetweenMinAnswers}>
            <Typography className={classes.labelSwitchQuestion}>{t('react.survey.hotspot.minimum.zones')}</Typography>
            <TextField
              value={getComponentConfiguration(uuid).hotspot.nbMinExpectedAnswers}
              onChange={(e) => changeField("nbMinExpectedAnswers", e.target.value)}
              type='number'
              style={{ width: 40 }}
            />
          </div>
          <div className={classes.flexBetweenMinAnswers}>
            <Typography className={classes.labelSwitchQuestion}>{t('react.survey.hotspot.maximum.zones')}</Typography>
            <TextField
              value={getComponentConfiguration(uuid).hotspot.nbMaxExpectedAnswers}
              onChange={(e) => changeField("nbMaxExpectedAnswers", e.target.value)}
              type='number'
              style={{ width: 40 }}
            />
          </div>
        </>
      }
      <div className={classes.flexBetweenMinAnswers}>
        <Typography className={classes.labelSwitchQuestion}>{t('react.survey.hotspot.validateNoAnswer')}</Typography>
        <FormControlLabel
          className={classes.switchSingleOrMultiple}
          control={<Switch checked={getComponentConfiguration(uuid).hotspot.displayExclusiveCheckbox} color="secondary" onChange={() => changeField('displayExclusiveCheckbox')}/>}
          labelPlacement="start"
        />
      </div>
      {getComponentConfiguration(uuid).hotspot.displayExclusiveCheckbox && 
        <div>
          <TextField
            label={t("react.collectforms.hotspot.validateNoAnswer.label")}
            value={getComponentConfiguration(uuid).hotspot.exclusiveCheckboxText}
            onChange={(e) => changeField("exclusiveCheckboxText", e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          
          />
        </div>
      }
      <Divider style={{paddingTop: '10px'}}/>
      <Popover 
            id={popoverIdAnswer}
            open={popoverOpenAnswer}
            anchorEl={popoverAnchorAnswerEl}
            onClose={handleClosePopoverAnswer}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{ paper : classes.MuiPopoverPaper}}
          >
            <CompactPicker height={386} onChange={changeColorAnswer} color={getColorAnswerHex()} />
          </Popover>  
      <div>
        <List dense subheader={<ListSubheader className={classes.listSubheaderQuestions}>
            <Typography variant="subtitle2">{t("react.project.collectforms.answers")}</Typography>
            <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                <Tooltip title={t("react.project.collectforms.moveDown.tooltip")}>
                    <IconButton
                        className={classes.IconButtonMinMax}
                        component='span'
                        disabled={!canIMoveDown()}
                        onClick={(event) => moveAnswerDownward(event)}
                        size='small'>
                        <ArrowDownwardIcon fontSize='small' className={classes.downwardIcon} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("react.project.collectforms.moveUp.tooltip")}>
                    <IconButton
                        className={classes.IconButtonMinMax}
                        component='span'
                        disabled={!canIMoveUp()}
                        onClick={(event) => moveAnswerUpward(event)}
                        size='small'>
                        <ArrowUpwardIcon fontSize='small' className={classes.upwardIcon} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("react.project.collectforms.nextBlock.tooltip")}>
                    <IconButton
                        className={classes.IconButtonMinMax}
                        component='span'
                        disabled={answerIndex === -1}
                        onClick={() => accessParamsQuestion()}
                        size='small'>
                        <EditIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("react.project.collectforms.deleteAnswer.tooltip")}>
                    <IconButton
                        className={classes.IconButtonMinMax}
                        component='span'
                        disabled={answerIndex === -1}
                        onClick={() => deleteAnswer()}
                        size='small'>
                        <DeleteIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListSubheader>}>
            {getComponentConfiguration(uuid).hotspot.answers.map((answer, subindex) => {
                return (
                    <ListItem
                        key={`pq-subindex-${subindex}`}
                        onClick={() => selectAnswer(subindex)}
                        selected={answerIndex === subindex}
                        button
                        className={classes.listItem}
                    >                    
                      <ListItemText primary={answer.name} style={{width: '100%'}}/>   
                      <div className={classes.iconsAnswerPosition}>
                        {answer.screenOut && <div>
                            <Tooltip title={t("react.project.collectforms.screenOut.tooltip")}>
                                <IconButton className={classes.IconButtonMinMax} size='small' onClick={() => accessParamsQuestion()}>
                                    <RedoIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </div>}
                        <Tooltip title={t('react.surveys.hotspot.zone.button.color')}>
                          <IconButton aria-describedby={popoverIdAnswer} style={{width: 27, height: 27}} variant="contained" onClick={(e) => handleClickPopoverAnswer(e, subindex)}>
                            <SquareIcon sx={{color: answer.color, height: 18}}/>
                          </IconButton>
                        </Tooltip>
                      </div>    
                      <ListItemText primary={answer.ref} className={classes.answerRef} />                                                 
                    </ListItem>
                );
            })}
        </List>
    </div>
      <div className={classes.addNewAnswer}>
        <TextField
          fullWidth
          multiline={true}
          label={t("react.project.collectforms.addanswer")}
          value={newAnswer}
          onChange={e => setNewAnswer(e.target.value)}
        ></TextField>
        <Button className={classes.addNewAnswerButton} onClick={e => changeField('answers')} size='small'><AddIcon fontSize='small' /></Button>
      </div>
    </Paper>
    </>
  );
}
