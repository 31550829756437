import { useState, useContext, useReducer, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SurveyContext } from './context.js';
import BlockHeader from './BlockHeader.js';
import TripleStateButton from '../shared/TripleStateButton/TripleStateButton.js';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { findElementInBlocksForm } from '../../utils/surveysUtils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { isBlank } from '../../utils/utils.js';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import { AiOutlineAppstoreAdd } from "react-icons/ai";

const useStyles = makeStyles()(theme => ({
    flexBetweenTripleStateButton: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 5
    },
    paddingPaper: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 1,
        paddingBottom: 30,
    },
    labelSwitchQuestion: {
        marginTop: 10,
    },
    textFieldGroupName: {
        marginTop: 10,
        marginBottom: 20
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    boxTextField: {
        position: 'absolute', 
        top: 19,
        width: '100%'
    },
    marginSwitchShufle: {
        marginTop: 8,
        marginLeft: 0
    },
    widthCellPosition: {
        width: 60,
    },
    widthCellAddVariableAndDelete: {
        width: 30
    },
    relative: {
        position: 'relative',
    },
    relativeFlexCenter: {
        position: 'relative', 
        display: 'flex', 
        alignItems: 'center',
        width: 100
    },
    scaleDeleteIconVar: {
        transform: 'scale(0.75)',
    },
    widthTextFieldCells: {
        width: 40
    },
    flexBetweenCells: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    typographyPrintVar: {
        display:'flex',
        alignItems: 'center'
    },
    rowReverse: {
        display: 'flex', 
        flexDirection: 'row-reverse'
    },
    marginSwitchShufleIterations: {
        marginRight: -5,
        padding: 0,
    },
    textFieldRenameVar: {
        maxWidth: 180, 
        marginTop: 2
    },
    divider: {
        width: 1,
        height: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        marginTop: 10,
        marginRight: 5,
        marginLeft: 5
    },
    switchSingleOrMultiple: {
        marginRight: -5,
        padding: 0,
    },
    typoHelper: {
        color: '#bf7c00',
        fontStyle: 'italic',
        fontSize: 14,
        marginTop: 7,
    }
}));

export default function Group(props) {

  const { classes } = useStyles();

  const { t, uuid } = props;

  const { setNeedToSave, getComponentConfiguration, getAllBlocks, selectedElement, flattenBlocksForm, getFormConfiguration } = useContext(SurveyContext);

  const [parentGroup, setParentGroup] = useState(undefined);
  const [mouseOverIdentifier, setMouseOverIdentifier] = useState([undefined, undefined]);
  const [renameIdentifier, setRenameIdentifier] = useState([undefined, undefined]);
  const [renameText, setRenameText] = useState(undefined);
  const [updateNotification, forceUpdate] = useReducer(x => x + 1, 0);
  const [checkedIterations, setCheckedIterations] = useState([]);

    useEffect(() => {
        const parentBlock = findElementInBlocksForm(selectedElement, getAllBlocks()).parentBlock;

        if (parentBlock?.type === "group") {
            setParentGroup(parentBlock.configuration.group);
        } else {
            setParentGroup(undefined);
        }
    }, [getAllBlocks(), selectedElement]);

    const changeField = (fieldName, value) => {
        switch (fieldName) {
            case "ref":
                getComponentConfiguration(uuid).ref = value;
                break;
            case "enabledBlock":
                getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
                break;
            case "type":
                getComponentConfiguration(uuid).group.type = value;
                if (value === 'cellAssigner') {
                    getComponentConfiguration(uuid).group.nbCellsToCarryOut = getComponentConfiguration(uuid).group.blocks.filter(b => b.type === "group").length;
                }
                break;
            case "cellSequenceType":
                getComponentConfiguration(uuid).group.cellSequenceType = value;
                if (value === "order") {
                    const nbMax = selectedElement.configuration.group.blocks.filter(b => b.type === "group").length;
                    getComponentConfiguration(uuid).group.nbCellsToCarryOut = nbMax;
                }
                break;
            case "blockSequenceType":
                getComponentConfiguration(uuid).group.blockSequenceType = value;
                if (value === "order") {
                    const nbMax = selectedElement.configuration.group.blocks.length;
                    getComponentConfiguration(uuid).group.nbBlocksToView = nbMax;
                }
                break;
            case "iterationSequenceType":
                getComponentConfiguration(uuid).group.iterationSequenceType = value;
                if (value === "order") {
                    const nbMax = selectedElement.configuration.group.iterations.length;
                    getComponentConfiguration(uuid).group.nbIterationsToView = nbMax;
                }
                break;
            case "title":
                getComponentConfiguration(uuid).title = value;
                break;
            case "addIteration":
                let variablesToAdd = {};
                if (getComponentConfiguration(uuid).group.iterations.length > 0) {
                    Object.entries(getComponentConfiguration(uuid).group.iterations[0].variables)
                        .forEach(([key, val], idx) => {
                            variablesToAdd[key] = "Value " + (idx+1);
                        });
                } else {
                    variablesToAdd = { "VAR_1": "Value 1" };
                }
                getComponentConfiguration(uuid).group.iterations.push({position: getComponentConfiguration(uuid).group.iterations.length, variables: variablesToAdd});
                break;
            case "addVariable":
                var varNumber = 1;
                let last = distinctVariableNames.slice(-1);
                if(last.length > 0) {
                    let extractedNumber = parseInt(last[0].substring(4), 10);
                    if (!isNaN(extractedNumber)) varNumber = extractedNumber + 1;
                }

                const newVar = `VAR_${varNumber}`;
                getComponentConfiguration(uuid).group.iterations.forEach(iteration => {
                    iteration.variables[newVar] = `Value ${varNumber}`;
                });
                break;
            case "deleteVariable":
                getComponentConfiguration(uuid).group.iterations.forEach(iteration => {
                    delete iteration.variables[value];
                });
                break;
            case "deleteIteration":
                getComponentConfiguration(uuid).group.iterations = getComponentConfiguration(uuid).group.iterations.filter(iteration => !checkedIterations.includes(iteration.position));
                getComponentConfiguration(uuid).group.iterations.forEach((iteration, index) => iteration.position = index);
                setCheckedIterations([]);
                break;
            case "nbCellsToCarryOut":
                const nbMaxCells = selectedElement.configuration.group.blocks.filter(b => b.type === "group").length;
                if (value >= 1 && value <= nbMaxCells) {
                    getComponentConfiguration(uuid).group.nbCellsToCarryOut = value;
                }
                break;
            case "nbBlocksToView":
                const nbMaxBlocks = selectedElement.configuration.group.blocks.length;
                if (value >= 1 && value <= nbMaxBlocks) {
                    getComponentConfiguration(uuid).group.nbBlocksToView = value;
                }
                break;
            case "nbIterationsToView":
                const nbMaxIterations = selectedElement.configuration.group.iterations.length;
                if (value >= 1 && value <= nbMaxIterations) {
                    getComponentConfiguration(uuid).group.nbIterationsToView = value;
                }
                break;
            case "checkboxIteration":
                const updatedCheckedIterations = [...checkedIterations];
                if (checkedIterations.includes(value)) {
                    const filtered = updatedCheckedIterations.filter(v => v !== value);
                    setCheckedIterations(filtered);
                } else {
                    updatedCheckedIterations.push(value);
                    setCheckedIterations(updatedCheckedIterations);
                }
                break;
        }
        setNeedToSave(true);
        forceUpdate();
    };

    const moveUpwardIteration = () => {
        let iterations = getComponentConfiguration(uuid).group.iterations;
        const sortedCheckedIterations = [...checkedIterations].sort((a, b) => a - b);

        for (let i = 0; i < sortedCheckedIterations.length; i++) {
            let currentIndex = sortedCheckedIterations[i];
    
            if (currentIndex > 0) {
                [iterations[currentIndex-1], iterations[currentIndex]] =
                [iterations[currentIndex], iterations[currentIndex-1]];
            }
        };
        iterations.forEach((iteration, index) => iteration.position = index);
        const updatedCheckedIterations = checkedIterations.map(position => position-1);
        setCheckedIterations(updatedCheckedIterations);

        setNeedToSave(true);
        forceUpdate();
    };

    const moveDownwardIteration = () => {
        let iterations = getComponentConfiguration(uuid).group.iterations;
        const sortedCheckedIterations = [...checkedIterations].sort((a, b) => b - a);
    
        for (let i = 0; i < sortedCheckedIterations.length; i++) {
            let currentIndex = sortedCheckedIterations[i];
    
            if (currentIndex < iterations.length - 1) {
                [iterations[currentIndex], iterations[currentIndex+1]] =
                    [iterations[currentIndex+1], iterations[currentIndex]];
            }
        }
        iterations.forEach((iteration, index) => iteration.position = index);
        const updatedCheckedIterations = checkedIterations.map(position => position+1);
        setCheckedIterations(updatedCheckedIterations);
    
        setNeedToSave(true);
        forceUpdate();
    };

    const handleMouseOver = (iteration, variable) => {
        setMouseOverIdentifier([iteration, variable]);
    };

    const handleStartRename = (event, position, initalText, variable) => {
        event.preventDefault();
        setRenameIdentifier([position, variable]);
        setRenameText(initalText);
        setMouseOverIdentifier([undefined, undefined]);
    };

    const handleValidRename = (event, key, position) => {
        event.preventDefault();
        getComponentConfiguration(uuid).group.iterations[position].variables[key] = renameText;
        setRenameIdentifier([undefined, undefined]);
        setRenameText(undefined);
        setMouseOverIdentifier([undefined, undefined]);

        setNeedToSave(true);
        forceUpdate();
    };

    const printIterationVariable = (position, key, value) => {
        if(renameIdentifier[0] === position && renameIdentifier[1] === key) {
            return (
                <Box className={classes.boxTextField}>
                    <TextField
                        type="text"
                        value={renameText}
                        onChange={e => setRenameText(e.target.value)}
                        error={isBlank(renameText)}
                        className={classes.textFieldRenameVar}
                        autoFocus
                        fullWidth={true}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !isBlank(renameText)) {
                                handleValidRename(e, key, position);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={t("react.button.save")}><span>
                                        <IconButton component="span"
                                            onClick={(e) => handleValidRename(e, key, position)}
                                            disabled={isBlank(renameText)}
                                            size="large">
                                            <DoneIcon style={{ color : !isBlank(renameText) ? '#66bb6a' : '#eee'}} />
                                        </IconButton></span>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box> 
            );
        }

        return (
            <Typography className={classes.typographyPrintVar}>
                {value}
                <Tooltip title={t('react.tooltip.rename')}>
                    <IconButton component="span" onClick={(e) => handleStartRename(e, position, value, key)}>
                        <EditOutlinedIcon fontSize="small" sx={{verticalAlign: 'sub', opacity: mouseOverIdentifier[0] === position && mouseOverIdentifier[1] === key ? '1': '0'}} />
                    </IconButton>
                </Tooltip>
            </Typography>
        );
    };

    const isNbCellsFieldDisabled = useMemo(() => {
        return selectedElement.configuration.group.cellSequenceType === "order" ||
                selectedElement.configuration.group.blocks.filter(b => b.type === "group").length === 0 ||
                (selectedElement.configuration.group.cellSequenceType === "pairing" && selectedElement.configuration.group.nbMaxAnswers === 0);
    }, [selectedElement.configuration.group.cellSequenceType, selectedElement.configuration.group.blocks]);

    const isCellAssignerWithCells = useMemo(() => {
        const isAlreadyACellAssigner = selectedElement.configuration.group.type === 'cellAssigner';
        const hasAlreadySomeCells = selectedElement.configuration.group.blocks.some(b => b.configuration.group?.type === "cell");
        return isAlreadyACellAssigner && hasAlreadySomeCells;
    }, [flattenBlocksForm]);

    const cantCreateCellAssigner = useMemo(() => {
        const hasAlreadyACellAssigner = flattenBlocksForm.some(b => b.configuration.uuid !== selectedElement.configuration.uuid && b.configuration.group && b.configuration.group.type === 'cellAssigner');
        const childIsAnythingElseThanCell = selectedElement.configuration.group.blocks.some(b => b.configuration.group?.type !== "cell");
        const nestedGroup = !getAllBlocks().find(b => b.uuid === selectedElement.uuid);

        return hasAlreadyACellAssigner || childIsAnythingElseThanCell || nestedGroup;
    }, [flattenBlocksForm]);

    const distinctVariableNames = useMemo(() => {
        if(getComponentConfiguration(uuid).group.iterations.length > 0 && getComponentConfiguration(uuid).group.iterations[0].variables) {
            return Object.keys(getComponentConfiguration(uuid).group.iterations[0].variables);
        }
        return [];
    }, [updateNotification]);

    const getTranslationScenarioType = useMemo(() => {
        if (selectedElement.configuration.group.type === "cellAssigner") {
            return t('react.surveys.cellSequence.type');
        } else {
            return t('react.surveys.blockSequence.type');
        }
    }, [selectedElement.configuration.group.type]);

    const getTranslationNumberOfElements = useMemo(() => {
        if (selectedElement.configuration.group.type === "cellAssigner") {
            return t('react.surveys.cellSequence.numberOfElements');
        } else {
            return t('react.surveys.blockSequence.numberOfElements');
        }
    }, [selectedElement.configuration.group.type]);

    const getValueTextFieldNumberOfElementsToDisplay = useMemo(() => {
        if (selectedElement.configuration.group.type === "cellAssigner") {
            if (getComponentConfiguration(uuid).group.cellSequenceType === "order") {
                return getComponentConfiguration(uuid).group.blocks.filter(b => b.type === "group").length;
            } else {
                return getComponentConfiguration(uuid).group.nbCellsToCarryOut;
            }
        } else {
            if (getComponentConfiguration(uuid).group.blockSequenceType === "order") {
                return getComponentConfiguration(uuid).group.blocks.length;
            } else {
                return getComponentConfiguration(uuid).group.nbBlocksToView;
            }
        }
    }, [selectedElement, getComponentConfiguration(uuid).group.nbBlocksToView, getComponentConfiguration(uuid).group.nbCellsToCarryOut]);

    return (
        <>
        {/* Header of the bloc */}
            <BlockHeader
                {...props}
                blockRef={getComponentConfiguration(uuid).ref}
                setBlockRef={(newValue) => changeField('ref', newValue)}
                isActive={getComponentConfiguration(uuid).active}
                setIsActive={(newValue) => changeField('enabledBlock', newValue)}
                title={t("react.project.collectforms.block.group.title")}
            />
            <Paper className={classes.paddingPaper}>
                <TextField
                    label={`${t("react.surveys.group.field.title")} (${(getComponentConfiguration(uuid).title || '').length} / 255)`}
                    fullWidth
                    value={getComponentConfiguration(uuid).title}
                    onChange={e => changeField('title', e.target.value)}
                    inputProps={{ maxLength: 255 }}
                    className={classes.textFieldGroupName}
                />
                {parentGroup?.type !== "cellAssigner" && <Box className={classes.flexBetween}>
                    <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.group.type')}</Typography>
                    <FormControl>
                        <Select
                            value={getComponentConfiguration(uuid).group.type}
                            onChange={e => changeField('type', e.target.value)}
                        >
                            <MenuItem value={'group'} disabled={isCellAssignerWithCells}>{t('react.surveys.group.typeGroup')}</MenuItem>
                            <MenuItem value={'loop'} disabled={isCellAssignerWithCells}>{t('react.surveys.group.typeLoop')}</MenuItem>
                            <MenuItem style={{display: "none"}} value={'cell'}>{/* this is to avoid warning */}</MenuItem>
                            <MenuItem value={'cellAssigner'} disabled={cantCreateCellAssigner}>{t('react.surveys.group.typeCellAssigner')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>}
                <Box className={classes.flexBetweenTripleStateButton}>
                    <Typography className={classes.labelSwitchQuestion}>{getTranslationScenarioType}</Typography>
                    <TripleStateButton
                        stateValues={['order', 'random', 'pairing']}
                        stateLabels={[t('react.surveys.group.cellOrder'), t('react.surveys.group.cellRandom'), t('react.surveys.group.cellPairing')]}
                        stateValue={selectedElement.configuration.group.type === "cellAssigner" ? getComponentConfiguration(uuid).group.cellSequenceType : getComponentConfiguration(uuid).group.blockSequenceType}
                        setStateValue={(newStateValue) => changeField(selectedElement.configuration.group.type === "cellAssigner" ?"cellSequenceType" : "blockSequenceType", newStateValue)}
                        disabledThird={getFormConfiguration().nbMaxAnswers === 0}
                        style = {{height: '25px', marginTop: '10px'}}
                    /> 
                </Box>
                <Box className={classes.flexBetweenCells}>
                    <Typography className={classes.labelSwitchQuestion}>{getTranslationNumberOfElements}</Typography>
                    <TextField
                        value={getValueTextFieldNumberOfElementsToDisplay}
                        onChange={(e) => changeField(selectedElement.configuration.group.type === "cellAssigner" ? "nbCellsToCarryOut" : "nbBlocksToView", e.target.value)}
                        type='number'
                        className={classes.widthTextFieldCells}
                        disabled={selectedElement.configuration.group.type === "cellAssigner" ? isNbCellsFieldDisabled : getComponentConfiguration(uuid).group.blockSequenceType === "order"}
                    />
                </Box>
                {selectedElement.configuration.group.type === "loop" &&
                    <>
                        <Box className={classes.flexBetweenTripleStateButton}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.loop.iteration.scenario')}</Typography>
                            <TripleStateButton
                                stateValues={['order', 'random', 'pairing']}
                                stateLabels={[t('react.surveys.group.cellOrder'), t('react.surveys.group.cellRandom'), t('react.surveys.group.cellPairing')]}
                                stateValue={getComponentConfiguration(uuid).group.iterationSequenceType}
                                setStateValue={(newStateValue) => changeField("iterationSequenceType", newStateValue)}
                                disabledThird={true}
                                style = {{height: '25px', marginTop: '10px'}}
                            />
                        </Box>
                        <Box className={classes.flexBetweenCells}>
                            <Typography className={classes.labelSwitchQuestion}>{t('react.surveys.loop.iteration.numberOfElements')}</Typography>
                            <TextField
                                value={getComponentConfiguration(uuid).group.iterationSequenceType === "order" ? getComponentConfiguration(uuid).group.iterations.length : getComponentConfiguration(uuid).group.nbIterationsToView}
                                onChange={(e) => changeField("nbIterationsToView", e.target.value)}
                                type='number'
                                className={classes.widthTextFieldCells}
                                disabled={getComponentConfiguration(uuid).group.iterationSequenceType === "order"}
                            />
                        </Box>
                    </>
                }
                {getComponentConfiguration(uuid).group.cellSequenceType === "pairing" && getFormConfiguration().nbMaxAnswers === 0 && <Typography className={classes.typoHelper}>{t('react.surveys.group.cellParing.textHelper')}</Typography>}
                {selectedElement.configuration.group.type === "loop" &&
                    <>
                        <Box className={classes.rowReverse}>
                            <Tooltip title={t('react.surveys.group.add.variable')}>
                                <IconButton onClick={e => changeField('addVariable')} disabled={getComponentConfiguration(uuid).group.iterations.length === 0}>
                                    <AiOutlineAppstoreAdd />
                                </IconButton>
                            </Tooltip>
                            <Box className={classes.divider}></Box>
                            <Tooltip title={t('react.surveys.group.delete.iteration')}>
                                <span>
                                    <IconButton disabled={checkedIterations.length === 0} onClick={e => changeField('deleteIteration')}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={t('react.surveys.button.add.iteration')}>
                                <IconButton onClick={e => changeField('addIteration')} >
                                    <SwitchAccessShortcutAddIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('react.surveys.group.downward.iteration')}>
                                <span>
                                    <IconButton disabled={checkedIterations.includes(getComponentConfiguration(uuid).group.iterations.length-1) || checkedIterations.length === 0} onClick={() => moveDownwardIteration()} >
                                        <ArrowDownwardIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={t('react.surveys.group.upward.iteration')}>
                                <span>
                                    <IconButton disabled={checkedIterations.includes(0) || checkedIterations.length === 0} onClick={() => moveUpwardIteration()} >
                                        <ArrowUpwardIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                        {getComponentConfiguration(uuid).group.iterations.length > 0 &&
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.widthCellPosition}></TableCell>
                                        {distinctVariableNames.map(varName => (
                                            <TableCell 
                                                key={varName}
                                                onMouseOver={e => handleMouseOver("VAR_", varName)} 
                                                onMouseOut={e => handleMouseOver(undefined, undefined)}
                                            >
                                                <Box className={classes.relativeFlexCenter}>
                                                    {varName}
                                                    <Tooltip title={t('react.surveys.group.delete.variable')}>
                                                        <IconButton onClick={e => changeField('deleteVariable', varName)} className={classes.scaleDeleteIconVar}>
                                                            <HighlightOffIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        ))}
                                        {distinctVariableNames.length === 0 && <TableCell>{t('react.surveys.group.no.variable.defined')}</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getComponentConfiguration(uuid).group.iterations.map(iteration => (
                                        <TableRow key={iteration.position}>
                                            <TableCell className={classes.widthCellPosition} style={{display: 'flex', alignItems: 'center'}}>
                                                {iteration.position + 1}
                                                <FormGroup style={{marginLeft: 10}}>
                                                    <FormControlLabel control={<Checkbox onChange={e => changeField('checkboxIteration', iteration.position)} checked={checkedIterations.includes(iteration.position)} color="primary"/>} />
                                                </FormGroup>
                                            </TableCell>
                                            {distinctVariableNames.map(varName => (
                                                <TableCell 
                                                    className={classes.relative}
                                                    key={varName}
                                                    onMouseOver={e => handleMouseOver(iteration.position, varName)} 
                                                    onMouseOut={e => handleMouseOver(undefined, undefined)}
                                                >
                                                    {printIterationVariable(iteration.position, varName, iteration.variables[varName])}
                                                </TableCell>
                                            ))}
                                            {distinctVariableNames.length === 0 && <TableCell></TableCell>}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                    </>
                }
            </Paper>
        </>
    );
};
