import React, { useContext, useState, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SurveyContext } from './context';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep, isBlank, pushAll } from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { IMAGE_WIDTH_AUTO, getBlockIcon, processToFlatBlocksForm, findElementInBlocksForm, removeElementInBlocksForm, moveUpwardElementInBlocksForm, moveDownwardElementInBlocksForm } from '../../utils/surveysUtils';
import PopupTwoButtons from "../shared/PopupTwoButtons/PopupTwoButtons.js";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const useStyles = makeStyles()(theme => ({
    marginTopForm: {
        marginTop: 10,
    },
    marginParams: {
        marginBottom: 20,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
        display: 'flex',
        justifyContent: 'space-between',
    },
    paramButton: {
        padding: 10,
        width: '100%',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    listOverflow: {
        height: 'calc(100vh - 250px)', 
        overflowY: 'scroll'
    },
    styleGroups: {
        paddingTop: 5, 
        paddingBottom: 5, 
        paddingLeft: 20, 
        borderTop: '1px dashed #ddd'
    },
    listItemIcon: {
        minWidth: '35px',
    },
    questionProfileIndex: {
        marginLeft: "5px",
        color: "#BBB",
        fontStyle: "italic",
        fontSize: 'small'
    },
    groupChildren: {
        marginLeft: 10
    }
}));

export default function ListOfBlocks(props) {
    
  const { classes } = useStyles();

  const { t, setBlocksForm, blocksForm,createBlock, conditionners, setConditionners, getNewBlockRef,
    conditionService, hiddenGroups, setHiddenGroups } = props;

  const { needToSaveReducer, setNeedToSave, computeTitle, notifyStructuralChange,
    selectedElement, setSelectedElement, flattenBlocksForm } = useContext(SurveyContext);

  const [openDeleteBlockConfirmationPopup, setOpenDeleteBlockConfirmationPopup] = useState(false);
 
    const handleListItemClick = (event, element) => {
        event.preventDefault();
        setSelectedElement(element); 
    };

    const toggleGroup = (uuid) => {
        setHiddenGroups((prev) => ({...prev, [uuid]: !prev[uuid], }));
    };

    const upwardPosition = (event) => {
        event.preventDefault();
        let clonedBlocksForm = cloneDeep(blocksForm);        

        let indexBlockSelectedBlock = flattenBlocksForm.findIndex(b => b.uuid === selectedElement.uuid);
        let upperBlockInFlattenBlocksForm = flattenBlocksForm[indexBlockSelectedBlock-1];
        let findBlock = findElementInBlocksForm(selectedElement, clonedBlocksForm);
        let upperBlockInBlocksForm = findBlock.arrayOfAdjacentsBlocks[findBlock.index-1];

        removeElementInBlocksForm(selectedElement, clonedBlocksForm);

        if (selectedElement.configuration?.group?.type === "cellAssigner") {
            const indexSelectedElement = blocksForm.findIndex(b => b.uuid === selectedElement.uuid);
            clonedBlocksForm.splice(indexSelectedElement-1, 0, selectedElement);
        } else if (selectedElement.configuration?.group?.type === "cell") {
            findBlock.arrayOfAdjacentsBlocks.splice(findBlock.index-1, 0, selectedElement);
        } else if (upperBlockInBlocksForm && upperBlockInBlocksForm.type === "group") {
            upperBlockInBlocksForm.configuration.group.blocks.push(selectedElement);
        } else {
            moveUpwardElementInBlocksForm(selectedElement, clonedBlocksForm, upperBlockInFlattenBlocksForm);
        }

        setBlocksForm(clonedBlocksForm);
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const downwardPosition = (event) => {
        event.preventDefault();
        let clonedBlocksForm = cloneDeep(blocksForm);  
        let findBlock = findElementInBlocksForm(selectedElement, clonedBlocksForm);
        let lastBlockOfParentOfSelectedIndex = findBlock.arrayOfAdjacentsBlocks[findBlock.arrayOfAdjacentsBlocks.length-1];
        let blockUnderSelectedElement = findBlock.arrayOfAdjacentsBlocks[findBlock.index+1];
        let indexInParentGroup = findBlock.indexInParentGroup;

        removeElementInBlocksForm(selectedElement, clonedBlocksForm);

        if (selectedElement.configuration?.group?.type === "cellAssigner") {
            const indexSelectedElement = blocksForm.findIndex(b => b.uuid === selectedElement.uuid);
            clonedBlocksForm.splice(indexSelectedElement+1, 0, selectedElement);
        } else if (selectedElement.configuration?.group?.type === "cell") {
            findBlock.arrayOfAdjacentsBlocks.splice(findBlock.index+1, 0, selectedElement);
        } else if (lastBlockOfParentOfSelectedIndex.uuid === selectedElement.uuid) {
            findBlock.parentList.splice(indexInParentGroup+1, 0, selectedElement);
        } else {
            if (blockUnderSelectedElement.type === "group") {
                blockUnderSelectedElement.configuration.group.blocks.unshift(selectedElement);
            } else {
                moveDownwardElementInBlocksForm(selectedElement, clonedBlocksForm, blockUnderSelectedElement);
            }
        }

        setBlocksForm(clonedBlocksForm);
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const canIMoveUp = () => {
        if (!selectedElement) return;
        const findElement = findElementInBlocksForm(selectedElement, blocksForm);

        if (selectedElement === undefined || selectedElement.movable === false) {
            return false;
        }

        if (findElement.index === 0 && !findElement.parentList) {
            return false;
        }

        if (findElement.index === 1 && !findElement.parentList && blocksForm[0].type === 'introduction') {
            return false;
        }

        if (selectedElement.configuration.group?.type === "cell" && findElement.index === 0) {
            return false;
        }

        if (findElement.parentBlock?.configuration.group.type === "cell" && findElement.index === 0) {
            return false;
        }
        return true;
    };

    const canIMoveDown = () => {
        if (!selectedElement) return;
        const findElement = findElementInBlocksForm(selectedElement, blocksForm);

        if (selectedElement === undefined || selectedElement.movable === false) {
            return false;
        }

        if (findElement.index === findElement.arrayOfAdjacentsBlocks.length - 1 && !findElement.parentList) {
            return false;
        }

        if (findElement.index === findElement.arrayOfAdjacentsBlocks.length - 2 && blocksForm[blocksForm.length - 1].type === 'thankyou' && !findElement.parentList) {
            return false;
        }

        if (selectedElement.configuration.group?.type === "cell" && findElement.index === findElement.arrayOfAdjacentsBlocks.length - 1) {
            return false;
        }

        if (findElement.parentBlock?.configuration.group.type === "cell" && findElement.index === findElement.arrayOfAdjacentsBlocks.length - 1) {
            return false;
        }
        return true;
    };

    const handleCloseDeleteBlockConfirmationPopup = () => {
        setOpenDeleteBlockConfirmationPopup(false);
    };

    const shouldDisplayWarningOnDelete  = () => {
        // the main function "doesDeleteBlockAffectConditions" will be called once if we select a bloc and as many times as there are blocks if we select a group
        const doesDeleteBlockAffectConditions = (blockParam) => {
            let listOfUuidsToCheck = [];
            const validTypes = ["question", "battery", "hotspot"];

            if (validTypes.includes(blockParam.type)) {
                listOfUuidsToCheck = blockParam.configuration[blockParam.type].answers.map(answer => answer.uuid);
            }

            const foundOneImpactingUuid = conditionners
                .map(conditionner => conditionner.conditions)
                .flat()
                .map(c => c.groups)
                .flat()
                .some(g => g.elements.findIndex(e => listOfUuidsToCheck.includes(e.itemUuid)) >= 0);

            return foundOneImpactingUuid;
        };

        if (selectedElement.type !== "group") {
            return doesDeleteBlockAffectConditions(selectedElement);
        } else {
            const blocksInsideSelectedElement = processToFlatBlocksForm(selectedElement.configuration.group.blocks);
            for (let b of blocksInsideSelectedElement) {
                if (doesDeleteBlockAffectConditions(b)) {
                    return true;
                }
            }
            return false;
        }
    };

    const askConfirmationIfNecessaryWhenDeleteElement = (event) => {
        event.preventDefault();
        if (shouldDisplayWarningOnDelete()) {
            setOpenDeleteBlockConfirmationPopup(true);
        } else {
            deleteBlockCallback();
        }
    };

    const deleteBlockCallback = () => {
        let newblocksForm = [...blocksForm];
        removeElementInBlocksForm(selectedElement, newblocksForm);
        setSelectedElement(undefined);
        setBlocksForm(newblocksForm);
        setOpenDeleteBlockConfirmationPopup(false);
        setNeedToSave(true);
        notifyStructuralChange();
    };

    // -- methods used for duplication ----------------------------------------

    const duplicateBlock = (event) => {
        event.preventDefault();
        var clonedBlocksForm = cloneDeep(blocksForm);

        let newRef = getNewBlockRef(selectedElement.configuration.type, processToFlatBlocksForm(blocksForm));
        let [config, uuidsMapping] = cloneDeepBlockConfig(selectedElement.configuration, newRef);

        var allClonedUuidsMapping = new Map();
        allClonedUuidsMapping = uuidsMapping;

        let clone = createBlock(selectedElement.type, config, true);

        if (clone.type === "group") {
            let flattenedBlocks = processToFlatBlocksForm(clonedBlocksForm);
            flattenedBlocks.push(clone);
    
            let cloneTarget = cloneDeep(clone);
            cloneTarget.configuration.group.blocks = [];

            duplicateGroupRecursively(clone, cloneTarget, allClonedUuidsMapping, flattenedBlocks);
            clone = cloneTarget;
        }

        duplicateAssociatedConditions(allClonedUuidsMapping);

        // search where to put the new block (after the current selected)
        const selectedIndex = findElementInBlocksForm(selectedElement, clonedBlocksForm);
        selectedIndex.arrayOfAdjacentsBlocks.splice(selectedIndex.index+1, 0, clone);

        // restore the previous selection (else there is a strange behavior)
        if(selectedElement !== undefined /* previous selection */) {
            var selected = processToFlatBlocksForm(clonedBlocksForm).find(b => b.uuid === selectedElement.uuid);
            setSelectedElement(selected);
        }

        setBlocksForm(clonedBlocksForm);
        setNeedToSave(true);
        notifyStructuralChange();
    };    

    const cloneDeepBlockConfig = (blockConfig, withRef, withGroupId = undefined) => {
        let uuidsMapping = new Map();

        // get the config of the block
        let config = cloneDeep(blockConfig);

        // reset some fields
        config.id = 0;
        config.imageId = 0;
        config.imagePosition = 'left';
        config.imageWidth = IMAGE_WIDTH_AUTO;
        config.openImageFullscreen = false;

        config.ref = withRef;
        config.groupId = withGroupId ? withGroupId : config.groupId;

        let newUuid = uuidv4();
        uuidsMapping.set(config.uuid, newUuid);
        config.uuid = newUuid;

        if (config.openQuestion !== undefined && config.openQuestion !== null) {
            config.openQuestion.id = 0;
        }

        if (config.experience !== undefined && config.experience !== null) {
            config.experience.id = 0;
        }

        if (config.question !== undefined && config.question !== null) {
            config.question.id = 0;
            config.question.answers.forEach(answer => {
                answer.id = 0;
                answer.questionId = 0;
                answer.imageId = 0;

                let newUuid = uuidv4();
                uuidsMapping.set(answer.uuid, newUuid);
                answer.uuid = newUuid;
            });
        }

        if (config.battery !== undefined && config.battery !== null) {
            config.battery.id = 0;

            config.battery.answers.forEach(answer => {
                answer.id = 0;
                answer.batteryId = 0;
                answer.imageId = 0;

                let newUuid = uuidv4();
                uuidsMapping.set(answer.uuid, newUuid);
                answer.uuid = newUuid;
            });

            config.battery.items.forEach(item => {
                item.id = 0;
                item.batteryId = 0;
                item.imageId = 0;

                let newUuid = uuidv4();
                uuidsMapping.set(item.uuid, newUuid);
                item.uuid = newUuid;
            });
        }

        if (config.hotspot !== undefined && config.hotspot !== null) {
            config.hotspot.id = 0;
            config.hotspot.answers.forEach(answer => {
                answer.id = 0;
                answer.hotspotId = 0;
                
                let newUuid = uuidv4();
                uuidsMapping.set(answer.uuid, newUuid);
                answer.uuid = newUuid;
            });

            // NOTE: zones are duplicated, but it's currently not possible to duplicate the associated IMG
            config.hotspot.zones.forEach(zone => {
                zone.id = 0;
                zone.hotspotId = 0;
                
                let newUuid = uuidv4();
                uuidsMapping.set(zone.uuid, newUuid);
                zone.uuid = newUuid;
            });
        }

        if (config.group !== undefined && config.group !== null) {
            config.group.id = 0;
            config.group.blockId = 0;
        }

        // returns the new config AND the mapping between OLD and NEW uuids
        return [config, uuidsMapping];
    };

    const duplicateGroupRecursively = (sourceBlock, targetBlock, allClonedUuidsMapping, flattenedBlocksForRefs) => {
        sourceBlock.configuration.group.blocks.forEach((block) => {
            let newRef = getNewBlockRef(block.type, flattenedBlocksForRefs);
            let [config, uuidsMapping] = cloneDeepBlockConfig(block.configuration, newRef);
            let clone = createBlock(block.type, config, true);
            uuidsMapping.forEach((value, key) => allClonedUuidsMapping.set(key, value));

            // maintain the list up-to-date
            flattenedBlocksForRefs.push(clone);

            if (clone.type === "group") {
                let cloneTarget = cloneDeep(clone);
                cloneTarget.configuration.group.blocks = [];

                duplicateGroupRecursively(clone, cloneTarget, allClonedUuidsMapping, flattenedBlocksForRefs);
                clone = cloneTarget;
            }

            targetBlock.configuration.group.blocks.push(clone);
        });
    };

    const canIDuplicate = useMemo(() => {
        if(!selectedElement || !selectedElement.configuration) return false;
        else if(selectedElement.configuration.type === "introduction") return false;
        else if(selectedElement.configuration.type === "thankyou") return false;
        else if(selectedElement.configuration?.type === 'group' && selectedElement.configuration.group.type === 'cellAssigner') return false;

        return true;
    }, [selectedElement, needToSaveReducer]);

    const duplicateAssociatedConditions = (uuidsMapping) => {
        var addedConditionners = [];
        var newConditionners = [...conditionners];

        uuidsMapping.forEach((clonedUuid /* value */, originalUuid /* key */) => {
            newConditionners.filter(c => c.conditionedUuids.includes(originalUuid))
                .forEach(c => {
                    // if the condition contains a relation to one of the duplicate blocks
                    // then the condition must also be duplicated
                    const concernedParentUuids = conditionService.getConcernedParentUuids(c);
                    const hasRelationsWithDuplicatedParentUuids = concernedParentUuids.some(cpuid => uuidsMapping.has(cpuid));

                    if(hasRelationsWithDuplicatedParentUuids) {
                        let duplicatedConditionner = conditionService.cloneConditionnerAsNew(c, uuidsMapping);
                        duplicatedConditionner.name = isBlank(duplicatedConditionner.name) ? '' : `${duplicatedConditionner.name} - copy`;
                        addedConditionners.push(duplicatedConditionner);
                    } else if(isBlank(c.name)) {
                        // when a condition doesn't have a name 
                        // we MUST duplicate the condition (1 condition for 1 item)
                        let duplicatedConditionner = conditionService.cloneConditionnerAsNew(c, uuidsMapping);
                        duplicatedConditionner.conditionedUuids = [clonedUuid]; // Attach to THIS clonedUuid only
                        addedConditionners.push(duplicatedConditionner);
                    } else {
                        // when a condition has a name and NO duplicated relations, we can push this new uuid into
                        c.conditionedUuids.push(clonedUuid);
                    }
                });
        });

        pushAll(newConditionners, addedConditionners);
        setConditionners(newConditionners);
    };

    // -- end of methods used for duplication ----------------------------------------

    const renderBlocksRecursively = (blocks, depth = 0) => {
        return blocks.map((element, index) => {
            const isHidden = hiddenGroups[element.uuid] || false;

            return (
                <div key={element.uuid}>
                    <ListItem
                        button
                        selected={selectedElement?.uuid === element.uuid}
                        onClick={(event) => handleListItemClick(event, element)}
                        key={element.uuid}
                        style={{
                            paddingLeft: '6px',
                            paddingRight: '6px',
                            border:  selectedElement?.uuid === element.uuid ? "1px solid #b1b1b1" : '1px solid rgba(0,0,0,0)',
                            backgroundColor: element.incomplete ? "#ffe1e7" 
                                    : depth === 0 ? ""
                                    : depth === 1
                                    ? "#f7f7f7"
                                    : "#e9ecef",
                        }}
                    >
                        <ListItemIcon
                            children={getBlockIcon(element.configuration)}
                            className={classes.listItemIcon}
                        />
                        {element.type === "group" && (
                            <IconButton
                                onClick={() => toggleGroup(element.uuid)}
                                size="small"
                                style={{marginRight: 5}}
                            >
                                {isHidden ? (
                                    <KeyboardArrowRightIcon fontSize="small" />
                                ) : (
                                    <ExpandMoreIcon fontSize="small" />
                                )}
                            </IconButton>
                        )}
                        <ListItemText primary={computeTitle(element)} />
                        {element.type !== "introduction" && element.type !== "thankyou" && (
                            <div className={classes.questionProfileIndex}>{element.configuration.ref}</div>
                        )}
                    </ListItem>
                    {element.type === "group" && (
                        <Collapse in={!isHidden} timeout="auto" unmountOnExit>
                            <div className={classes.groupChildren}>
                                {renderBlocksRecursively(element.configuration.group.blocks, depth + 1)}
                            </div>
                        </Collapse>
                    )}
                </div>
            );
        });
    };

    return(
    <Grid item xs={3} className={classes.marginTopForm}>
        <Paper>
            <div className={classes.flexBetween}>
                <div>
                    <Tooltip title={t("react.project.collectforms.moveDownQuestion.tooltip")}><span>
                        <IconButton
                            disabled={!canIMoveDown()}
                            onClick={(e) => downwardPosition(e)}
                            component="span">
                            <ArrowDownwardIcon />
                        </IconButton></span>
                    </Tooltip>
                    <Tooltip title={t("react.project.collectforms.moveUpQuestion.tooltip")}><span>
                        <IconButton
                            disabled={!canIMoveUp()}
                            onClick={(e) => upwardPosition(e)}
                            component="span">
                            <ArrowUpwardIcon />
                        </IconButton></span>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={t("react.project.collectforms.copy.block.tooltip")}><span>
                        <IconButton
                            disabled={!canIDuplicate}
                            onClick={(e) => duplicateBlock(e)}
                            component="span">
                            <FileCopyIcon />
                        </IconButton></span>
                    </Tooltip>
                    <Tooltip title={t("react.project.collectforms.delete.block.tooltip")}><span>
                        <IconButton
                            disabled={!selectedElement}
                            onClick={(e) => askConfirmationIfNecessaryWhenDeleteElement(e)}
                            component="span">
                            <DeleteIcon />
                        </IconButton></span>
                    </Tooltip>
                </div>
            </div>
            <List component="nav" className={classes.listOverflow}>
                {renderBlocksRecursively(blocksForm)}
            </List>
        </Paper>
        <PopupTwoButtons
            variant="warning"
            openState={openDeleteBlockConfirmationPopup}
            callbackOnclose={handleCloseDeleteBlockConfirmationPopup}
            callbackOnclickLeftButton={handleCloseDeleteBlockConfirmationPopup}
            callbackOnclickRightButton={deleteBlockCallback}
            title={t("react.surveys.confirm.delete.block.title")}
            content={t("react.surveys.confirm.delete.block.description")}
            leftButton={t("react.button.cancel")}
            rightButton={t("react.button.delete")}
        />
    </Grid>
  );
}
